import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../Component/pageTitle";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import BarGraph from "../../Component/barGraph";
import {
  getCounsellorAllLeadsCount,
  getDashboardCallRecords,
  getDashboardIncomingRecords,
  getDashboardRecords,
  getDashboardRevenueRecords,
  getDashboardStatusRecords,
  getDashboardTotalRevenue,
} from "../../Redux/Actions/counsellorPanelAction";
import { useSelector } from "react-redux";
import moment from "moment";
import { getReferenceListByCounsellorId } from "../../Redux/Actions/managerPanelAction";
// import CanvasJSChart from "@canvasjs/react-charts";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
interface props {
  newIssueCount: any;
}
export default function CounsellorDashbord({ newIssueCount }: props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [totalRevenue, setTotalRevenue] = useState<any>(0);
  const [otherRevenue, setOtherRevenue] = useState<any>(0);
  const [totalLeadCount, setTotalLeadCount] = useState<any>(0);
  const [totalReferenceLeadCount, setTotalReferenceLeadCount] =
    useState<any>(0);
  const [startDate, setStartDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );

  const [newOppourtunity, setNewOppourtunity] = useState<number>(0);
  const [reEnquired, setReEnquired] = useState<number>(0);
  const [enrolled, setEnrolled] = useState<number>(0);
  const [notInterested, setNotInterested] = useState<number>(0);
  const [expired, setExpired] = useState<number>(0);
  const [cnrLead, setCNRLead] = useState<number>(0);
  const [prospectToday, setProspectToday] = useState<number>(0);
  const [interestedToday, setInterestedToday] = useState<number>(0);
  const [callBackToday, setCallBackToday] = useState<number>(0);
  const [callRejectToday, setCallRejectToday] = useState<number>(0);
  const [overdueProspect, setOverdueProspect] = useState<number>(0);
  const [overdueInterested, setOverdueInterested] = useState<number>(0);
  const [overdueCallBack, setOverdueCallBack] = useState<number>(0);
  const [overdueCNR, setOverdueCNR] = useState<number>(0);
  const [overdueCallReject, setOverdueCallReject] = useState<number>(0);
  const [leadInvalid, setLeadInvalid] = useState<number>(0);
  const [alreadyAdmissionDone, setAlreadyAdmissionDone] = useState<number>(0);
  const [welcomeCallDone, setWelcomeCallDone] = useState<number>(0);
  const [welcomeCallReference, setWelcomeCallReference] = useState<number>(0);
  const [followUp, setFollowUp] = useState<number>(0);
  const [interestedForUpselling, setInterestedForUpselling] =
    useState<number>(0);
  const [prospectForUpselling, setProspectForUpselling] = useState<number>(0);
  const [technicalIssue, setTechnicalIssue] = useState<number>(0);
  const [finalCNR, setFinalCNR] = useState<number>(0);

  const [xLabel1, setXLabel1] = useState<any>([]);
  const [xLabel2, setXLabel2] = useState<any>([]);
  const [xLabel3, setXLabel3] = useState<any>([]);
  const [Graphdata1, setGraphdata1] = useState<any>([]);
  const [Graphdata2, setGraphdata2] = useState<any>([]);
  const [Graphdata3, setGraphdata3] = useState<any>([]);

  const [xLabel4, setXLabel4] = useState<any>([]);
  const [xLabel5, setXLabel5] = useState<any>([]);
  const [xLabel6, setXLabel6] = useState<any>([]);
  const [Graphdata4, setGraphdata4] = useState<any>([]);
  const [Graphdata5, setGraphdata5] = useState<any>([]);
  const [Graphdata6, setGraphdata6] = useState<any>([]);

  const { loggedInUserId, loggedInUserManagerId, loggedInUserRole } =
    useSelector((state: any) => ({
      loggedInUserId: state.Auth.loggedInUserId,
      loggedInUserManagerId: state.Auth.loggedInUserManagerId,
      loggedInUserRole: state.Auth.loggedInUserRole,
    }));

  useEffect(() => {
    onChangeGetRevenue();
    dispatch(
      getDashboardStatusRecords(
        loggedInUserId,
        (res: any) => {
          setNewOppourtunity(res?.data?.newOpportunity);
          setReEnquired(res?.data?.transferred);
          setEnrolled(res?.data?.enrolled);
          setNotInterested(res?.data?.notInterested);
          setExpired(res?.data?.upcomingExpire);
          setCNRLead(res?.data?.cnrCount);
          setProspectToday(res?.data?.prospect_today);
          setInterestedToday(res?.data?.interested_today);
          setCallBackToday(res?.data?.callback_today);
          setCallRejectToday(res?.data?.callReject_today);
          setOverdueProspect(res?.data?.prospect_overdue);
          setOverdueInterested(res?.data?.interested_overdue);
          setOverdueCallBack(res?.data?.callback_overdue);
          setOverdueCNR(res?.data?.cnr_overdue);
          setOverdueCallReject(res?.data?.callReject_overdue);
          setLeadInvalid(res?.data?.leadInvalid);
          setAlreadyAdmissionDone(res?.data?.alreadyAdmissionDone);
          setWelcomeCallDone(res?.data?.welcomeCallDone);
          setWelcomeCallReference(res?.data?.welcomeCallReference);
          setFollowUp(res?.data?.followUp);
          setInterestedForUpselling(res?.data?.interestedForUpselling);
          setProspectForUpselling(res?.data?.prospectForUpselling);
          setTechnicalIssue(res?.data?.technicalIssue);
          setFinalCNR(res?.data?.final_cnr);
        },
        () => {}
      )
    );
    dispatch(
      getCounsellorAllLeadsCount(
        loggedInUserId,
        (res: any) => {
          setTotalLeadCount(res?.data[0]?.leadCount);
        },
        () => {}
      )
    );
  }, []);

  const onChange = (value: string) => {
    if (value === "newOppourtunity") {
      navigate("../newOpportunity");
    } else if (value === "reEnquired") {
      navigate("../reEnquired");
    } else if (value === "enrolled") {
      navigate("../enrolledLead");
    } else if (value === "notInterested") {
      navigate("../notIntrestedLead");
    } else if (value === "expired") {
      navigate("../expired");
    } else if (value === "leadInvalid") {
      navigate("../leadInvalid");
    } else if (value === "cnrLead") {
      navigate("../cnrLead");
    } else if (value === "prospectToday") {
      navigate("../prospectLead");
    } else if (value === "interestedToday") {
      navigate("../interested");
    } else if (value === "callBackToday") {
      navigate("../callBackLead");
    } else if (value === "callRejectToday") {
      navigate("../callRejectToday");
    } else if (value === "overdueProspect") {
      navigate("../overdueProspect");
    } else if (value === "overdueInterested") {
      navigate("../overdueInterested");
    } else if (value === "overdueCallBack") {
      navigate("../overdueCallback");
    } else if (value === "overdueCNR") {
      navigate("../overdueCNR");
    } else if (value === "overdueCallReject") {
      navigate("../overdueCallReject");
    } else if (value === "alreadyAdmissionDone") {
      navigate("../alreadyAdmissionDone");
    } else if (value === "welcomeCallDone") {
      navigate("../welcomeCallDone");
    } else if (value === "welcomeCallReference") {
      navigate("../welcomeCallReference");
    } else if (value === "followUp") {
      navigate("../followUp");
    } else if (value === "interestedForUpselling") {
      navigate("../interestedForUpselling");
    } else if (value === "prospectForUpselling") {
      navigate("../prospectForUpselling");
    } else if (value === "technicalIssue") {
      navigate("../technicalIssue");
    } else if (value === "finalCNR") {
      navigate("../finalCNR");
    } else if (value === "Summary") {
      navigate("../summary");
    }
  };

  const onChangeGetRevenue = () => {
    let data = {
      startDate,
      endDate,
    };
    dispatch(
      getDashboardTotalRevenue(
        loggedInUserId,
        data,
        (res: any) => {
          setTotalRevenue(res?.totalRevenue);
          setOtherRevenue(res?.otherRevenue);
        },
        () => {}
      )
    );
    const referenceData: any = {
      startDate: startDate,
      endDate: endDate,
      type: 2,
    };
    dispatch(
      getReferenceListByCounsellorId(
        loggedInUserId,
        referenceData,
        (res: any) => {
          if (res?.data[0]?.data?.length > 0) {
            setTotalReferenceLeadCount(res?.data[0]?.data?.length);
          } else {
            setTotalReferenceLeadCount(0);
          }
        }
      )
    );
    dispatch(
      getDashboardRevenueRecords(
        loggedInUserId,
        data,
        (res: any) => {
          // Revenue
          for (let i = 0; i <= xLabel1?.length; i++) {
            xLabel1?.pop();
            Graphdata1?.pop();
          }
          if (res) {
            if (res[0]?.target >= 0) {
              xLabel1?.push("Target");
              Graphdata1?.push(res[0]?.target);
            }
            if (res[0]?.achieved >= 0) {
              xLabel1?.push("Achieved");
              Graphdata1?.push(res[0]?.achieved);
            }
          }
        },
        () => {}
      )
    );

    const postData = {
      startTime: startDate,
      endTime: endDate,
    };
    if (loggedInUserRole === 5) {
      dispatch(
        getDashboardIncomingRecords(
          loggedInUserId,
          postData,
          (res: any) => {
            // Calling Time
            for (let i = 0; i <= xLabel2?.length; i++) {
              xLabel2?.pop();
              Graphdata2?.pop();
            }
            if (res?.callTime) {
              if (res?.callTime?.target >= 0) {
                xLabel2?.push("Target");
                Graphdata2?.push(res?.callTime?.target);
              }
              if (res?.callTime?.achieved >= 0) {
                xLabel2?.push("Achieved");
                Graphdata2?.push(res?.callTime?.achieved);
              }
            }
            // Reference
            for (let i = 0; i <= xLabel3?.length; i++) {
              xLabel3?.pop();
              Graphdata3?.pop();
            }
            if (res?.reference) {
              if (res?.reference?.target >= 0) {
                xLabel3?.push("Target");
                Graphdata3?.push(res?.reference?.target);
              }
              if (res?.reference?.achieved >= 0) {
                xLabel3?.push("Achieved");
                Graphdata3?.push(res?.reference?.achieved);
              }
            }
          },
          () => {}
        )
      );
    } else if (loggedInUserRole !== 5) {
      dispatch(
        getDashboardRecords(
          loggedInUserId,
          postData,
          (res: any) => {
            // Calling Time
            for (let i = 0; i <= xLabel2?.length; i++) {
              xLabel2?.pop();
              Graphdata2?.pop();
            }
            if (res?.callTime) {
              if (res?.callTime?.target >= 0) {
                xLabel2?.push("Target");
                Graphdata2?.push(res?.callTime?.target);
              }
              if (res?.callTime?.achieved >= 0) {
                xLabel2?.push("Achieved");
                Graphdata2?.push(res?.callTime?.achieved);
              }
            }
            // Reference
            for (let i = 0; i <= xLabel3?.length; i++) {
              xLabel3?.pop();
              Graphdata3?.pop();
            }
            if (res?.reference) {
              if (res?.reference?.target >= 0) {
                xLabel3?.push("Target");
                Graphdata3?.push(res?.reference?.target);
              }
              if (res?.reference?.achieved >= 0) {
                xLabel3?.push("Achieved");
                Graphdata3?.push(res?.reference?.achieved);
              }
            }
          },
          () => {}
        )
      );
    }

    dispatch(
      getDashboardCallRecords(
        loggedInUserId,
        postData,
        (res: any) => {
          // Total Connected Calls
          for (let i = 0; i <= xLabel4?.length; i++) {
            xLabel4?.pop();
            Graphdata4?.pop();
          }
          if (res?.totalConnectedCall) {
            if (res?.totalConnectedCall?.target >= 0) {
              xLabel4?.push("Target");
              Graphdata4?.push(res?.totalConnectedCall?.target);
            }
            if (res?.totalConnectedCall?.achieved >= 0) {
              xLabel4?.push("Achieved");
              Graphdata4?.push(res?.totalConnectedCall?.achieved);
            }
          }
          // Unique Connected Calls
          for (let i = 0; i <= xLabel5?.length; i++) {
            xLabel5?.pop();
            Graphdata5?.pop();
          }
          if (res?.uniqueConnectedCall) {
            if (res?.uniqueConnectedCall?.target >= 0) {
              xLabel5?.push("Target");
              Graphdata5?.push(res?.uniqueConnectedCall?.target);
            }
            if (res?.uniqueConnectedCall?.achieved >= 0) {
              xLabel5?.push("Achieved");
              Graphdata5?.push(res?.uniqueConnectedCall?.achieved);
            }
          }
          // Old Connected Calls
          for (let i = 0; i <= xLabel6?.length; i++) {
            xLabel6?.pop();
            Graphdata6?.pop();
          }
          if (res?.oldConnectedCall) {
            if (res?.oldConnectedCall?.target >= 0) {
              xLabel6?.push("Target");
              Graphdata6?.push(res?.oldConnectedCall?.target);
            }
            if (res?.oldConnectedCall?.achieved >= 0) {
              xLabel6?.push("Achieved");
              Graphdata6?.push(res?.oldConnectedCall?.achieved);
            }
          }
        },
        () => {}
      )
    );
  };

  const options = {
    animationEnabled: true,
    exportEnabled: false,
    title: {
      text: "Source Wise Analysis",
      fontFamily: "verdana",
    },
    axisY: {
      title: "Revenue",
    },
    axisX: {
      title: "Source",
      interval: 1,
    },
    toolTip: {
      shared: true,
      reversed: true,
    },
    data: [
      {
        // type: "stackedColumn",
        type: "column",
        name: "Actual Revenue",
        indexLabel: "{y}",
        showInLegend: true,
        dataPoints: [{ label: "VIpul", y: 2 }],
      },
      // transactionType?._id === 1
      //   ? {
      //       type: "stackedColumn",
      //       name: "GST Amount",
      //       indexLabel: "{y}",
      //       showInLegend: true,
      //       dataPoints: totalGSTGraph,
      //     }
      //   : {},
    ],
  };

  const renderGraphMode = () => {
    return (
      <div className="p-1 cursor">
        <div className="d-flex mx-2 flex-wrap">
          <div className="col-md-6 p-1">
            <div className="boxshadow  p-2">
              {/* <CanvasJSChart options={options} /> */}
              <BarGraph label={xLabel1} value={Graphdata1} />
              <div className=" d-flex justify-content-center">
                <label className="label me-2">Revenue</label>
              </div>
            </div>
          </div>
          <div className="col-md-6 p-1">
            <div className="boxshadow  p-2">
              <BarGraph label={xLabel2} value={Graphdata2} />
              <div className=" d-flex justify-content-center">
                <label className="label me-2">Calling Time</label>
              </div>
            </div>{" "}
          </div>
          <div className="col-md-6 p-1">
            <div className="boxshadow  p-2">
              <BarGraph label={xLabel3} value={Graphdata3} />
              <div className=" d-flex justify-content-center">
                <label className="label me-2">Reference</label>
              </div>
            </div>
          </div>
          <div className="col-md-6 p-1">
            <div className="boxshadow  p-2">
              <BarGraph label={xLabel4} value={Graphdata4} />
              <div className=" d-flex justify-content-center">
                <label className="label me-2">Total Connected Calls</label>
              </div>
            </div>
          </div>
          <div className="col-md-6 p-1">
            <div className="boxshadow  p-2">
              <BarGraph label={xLabel5} value={Graphdata5} />
              <div className=" d-flex justify-content-center">
                <label className="label me-2">Unique Connected Calls</label>
              </div>
            </div>
          </div>
          <div className="col-md-6 p-1">
            <div className="boxshadow  p-2">
              <BarGraph label={xLabel6} value={Graphdata6} />
              <div className=" d-flex justify-content-center">
                <label className="label me-2">Old Connected Calls</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle
        name={"Dashboard"}
        newIssueCount={newIssueCount}
        loggedInUserRole={loggedInUserRole}
      />
      <div className="col-md-12 row">
        <div className=" formDiv formWidth">
          <div className="d-flex">
            <div className="col-8">
              <div>
                <div className="mx-2 px-2 d-flex Justify-content-between">
                  <h4 className="col-md-3 text-primary fw-bold">Analysis</h4>
                  <div className="col-md-2 d-flex">
                    <div className="teargetbg px-4 my-3 me-3"></div>
                    <p className="mt-3 fw-bold ">Target</p>
                  </div>
                  <div className="col-md-2 d-flex">
                    <div className="unachievedbg px-4 my-3 me-3"></div>
                    <p className="mt-3 fw-bold ">Achieved</p>
                  </div>
                </div>
                {renderGraphMode()}
              </div>
            </div>
            <div className="col-4">
              <div className="mx-0">
                <div className="card">
                  <div className="d-flex p-2">
                    <div className="col-md-4 me-3">
                      <LabelField lableName="Start Date" />
                      <div className="d-flex justify-content-start rounded fw-bold align-items-center">
                        <DatePicker
                          value={startDate}
                          setInputText={(value: string) => setStartDate(value)}
                          maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <LabelField lableName="End Date" />
                      <div className="d-flex justify-content-start rounded fw-bold align-items-center">
                        <DatePicker
                          value={endDate}
                          setInputText={(value: string) => setEndDate(value)}
                          maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 d-flex justify-content-center align-items-center">
                      <button
                        className="btn btn-sm btn-primary rounded-pill"
                        onClick={onChangeGetRevenue}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                  <div>
                    <div className="d-flex justify-content-between align-items-center p-2">
                      <div className="col-md-4">
                        <h6 className="card-title">Total Revenue</h6>
                      </div>
                      <div className="col-md-6">
                        <div className="d-flex justify-content-center rounded border text-center fw-bold text-success fs-4 align-items-center">
                          {totalRevenue ? totalRevenue : 0}
                        </div>
                      </div>
                    </div>
                    {/* {loggedInUserRole === 3 ? (
                      <div className="d-flex justify-content-between align-items-center p-2">
                        <div className="col-md-4">
                          <h6 className="card-title">Other Revenue</h6>
                        </div>
                        <div className="col-md-6">
                          <div className="d-flex justify-content-center rounded border text-center fw-bold text-success fs-4 align-items-center">
                            {otherRevenue ? otherRevenue : 0}
                          </div>
                        </div>
                      </div>
                    ) : null} */}
                  </div>

                  <div className="d-flex justify-content-between card-footer">
                    <div>
                      <span className="fw-bold">Total Lead Count : </span>
                      <span className="fw-bold text-success">
                        {" "}
                        &nbsp;{totalLeadCount}
                      </span>
                    </div>
                    <div>
                      <span className="fw-bold">Total Reference Count : </span>
                      <span className="fw-bold text-success">
                        {" "}
                        &nbsp;{totalReferenceLeadCount}
                      </span>
                    </div>
                  </div>
                </div>
                <ol className="list-group list-group-numbered mt-2 border counsellorDashboardStatus">
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                    onClick={() => {
                      onChange("newOppourtunity");
                    }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">New Opportunity</div>
                      <span className="">
                        {((newOppourtunity / totalLeadCount) * 100).toFixed(2)}%
                      </span>
                    </div>
                    <span className="badge text-bg-primary rounded-pill fs-6">
                      {newOppourtunity}
                    </span>
                  </li>
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                    onClick={() => {
                      onChange("reEnquired");
                    }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Re-Enquired</div>
                      <span className="">
                        {((reEnquired / totalLeadCount) * 100).toFixed(2)}%
                      </span>
                    </div>
                    <span className="badge text-bg-primary rounded-pill fs-6">
                      {reEnquired}
                    </span>
                  </li>
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                    onClick={() => {
                      onChange("enrolled");
                    }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Enrolled</div>
                      <span className="">
                        {((enrolled / totalLeadCount) * 100).toFixed(2)}%
                      </span>
                    </div>
                    <span className="badge text-bg-primary rounded-pill fs-6">
                      {enrolled}
                    </span>
                  </li>
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                    onClick={() => {
                      onChange("notInterested");
                    }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Not Interested</div>
                      <span className="">
                        {((notInterested / totalLeadCount) * 100).toFixed(2)}%
                      </span>
                    </div>
                    <span className="badge text-bg-primary rounded-pill fs-6">
                      {notInterested}
                    </span>
                  </li>
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                    onClick={() => {
                      onChange("expired");
                    }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Expired</div>
                      <span className="">
                        {((expired / totalLeadCount) * 100).toFixed(2)}%
                      </span>
                    </div>
                    <span className="badge text-bg-primary rounded-pill fs-6">
                      {expired}
                    </span>
                  </li>
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                    onClick={() => {
                      onChange("leadInvalid");
                    }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Lead Invalid</div>
                      <span className="">
                        {((leadInvalid / totalLeadCount) * 100).toFixed(2)}%
                      </span>
                    </div>
                    <span className="badge text-bg-primary rounded-pill fs-6">
                      {leadInvalid}
                    </span>
                  </li>
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                    onClick={() => {
                      onChange("cnrLead");
                    }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">CNR Today</div>
                      <span className="">
                        {((cnrLead / totalLeadCount) * 100).toFixed(2)}%
                      </span>
                    </div>
                    <span className="badge text-bg-primary rounded-pill fs-6">
                      {cnrLead}
                    </span>
                  </li>
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                    onClick={() => {
                      onChange("prospectToday");
                    }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Prospect Today</div>
                      <span className="">
                        {((prospectToday / totalLeadCount) * 100).toFixed(2)}%
                      </span>
                    </div>
                    <span className="badge text-bg-primary rounded-pill fs-6">
                      {prospectToday}
                    </span>
                  </li>
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                    onClick={() => {
                      onChange("interestedToday");
                    }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Interested Today</div>
                      <span className="">
                        {((interestedToday / totalLeadCount) * 100).toFixed(2)}%
                      </span>
                    </div>
                    <span className="badge text-bg-primary rounded-pill fs-6">
                      {interestedToday}
                    </span>
                  </li>
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                    onClick={() => {
                      onChange("callBackToday");
                    }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Call Back Today</div>
                      <span className="">
                        {((callBackToday / totalLeadCount) * 100).toFixed(2)}%
                      </span>
                    </div>
                    <span className="badge text-bg-primary rounded-pill fs-6">
                      {callBackToday}
                    </span>
                  </li>
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                    onClick={() => {
                      onChange("callRejectToday");
                    }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Call Reject Today</div>
                      <span className="">
                        {((callRejectToday / totalLeadCount) * 100).toFixed(2)}%
                      </span>
                    </div>
                    <span className="badge text-bg-primary rounded-pill fs-6">
                      {callRejectToday}
                    </span>
                  </li>
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                    onClick={() => {
                      onChange("overdueProspect");
                    }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Overdue Prospect</div>
                      <span className="">
                        {((overdueProspect / totalLeadCount) * 100).toFixed(2)}%
                      </span>
                    </div>
                    <span className="badge text-bg-primary rounded-pill fs-6">
                      {overdueProspect}
                    </span>
                  </li>
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                    onClick={() => {
                      onChange("overdueInterested");
                    }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Overdue Interested</div>
                      <span className="">
                        {((overdueInterested / totalLeadCount) * 100).toFixed(
                          2
                        )}
                        %
                      </span>
                    </div>
                    <span className="badge text-bg-primary rounded-pill fs-6">
                      {overdueInterested}
                    </span>
                  </li>
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                    onClick={() => {
                      onChange("overdueCallBack");
                    }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Overdue Call Back</div>
                      <span className="">
                        {((overdueCallBack / totalLeadCount) * 100).toFixed(2)}%
                      </span>
                    </div>
                    <span className="badge text-bg-primary rounded-pill fs-6">
                      {overdueCallBack}
                    </span>
                  </li>
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                    onClick={() => {
                      onChange("overdueCNR");
                    }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Overdue CNR</div>
                      <span className="">
                        {((overdueCNR / totalLeadCount) * 100).toFixed(2)}%
                      </span>
                    </div>
                    <span className="badge text-bg-primary rounded-pill fs-6">
                      {overdueCNR}
                    </span>
                  </li>
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                    onClick={() => {
                      onChange("overdueCallReject");
                    }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Overdue Call Reject</div>
                      <span className="">
                        {((overdueCallReject / totalLeadCount) * 100).toFixed(
                          2
                        )}
                        %
                      </span>
                    </div>
                    <span className="badge text-bg-primary rounded-pill fs-6">
                      {overdueCallReject}
                    </span>
                  </li>
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                    onClick={() => {
                      onChange("alreadyAdmissionDone");
                    }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Already Admission Done</div>
                      <span className="">
                        {(
                          (alreadyAdmissionDone / totalLeadCount) *
                          100
                        ).toFixed(2)}
                        %
                      </span>
                    </div>
                    <span className="badge text-bg-primary rounded-pill fs-6">
                      {alreadyAdmissionDone}
                    </span>
                  </li>
                  {loggedInUserRole === 8 || loggedInUserRole === 4 ? (
                    <>
                      <li
                        className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                        onClick={() => {
                          onChange("welcomeCallDone");
                        }}
                      >
                        <div className="ms-2 me-auto">
                          <div className="fw-bold">Welcome Call Done</div>
                          <span className="">
                            {((welcomeCallDone / totalLeadCount) * 100).toFixed(
                              2
                            )}
                            %
                          </span>
                        </div>
                        <span className="badge text-bg-primary rounded-pill fs-6">
                          {welcomeCallDone}
                        </span>
                      </li>
                      <li
                        className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                        onClick={() => {
                          onChange("welcomeCallReference");
                        }}
                      >
                        <div className="ms-2 me-auto">
                          <div className="fw-bold">
                            Welcome Call & Reference Collection
                          </div>
                          <span className="">
                            {(
                              (welcomeCallReference / totalLeadCount) *
                              100
                            ).toFixed(2)}
                            %
                          </span>
                        </div>
                        <span className="badge text-bg-primary rounded-pill fs-6">
                          {welcomeCallReference}
                        </span>
                      </li>
                      <li
                        className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                        onClick={() => {
                          onChange("followUp");
                        }}
                      >
                        <div className="ms-2 me-auto">
                          <div className="fw-bold">
                            Follow - Up For Upselling
                          </div>
                          <span className="">
                            {((followUp / totalLeadCount) * 100).toFixed(2)}%
                          </span>
                        </div>
                        <span className="badge text-bg-primary rounded-pill fs-6">
                          {followUp}
                        </span>
                      </li>
                    </>
                  ) : null}
                  {loggedInUserRole === 4 ? (
                    <>
                      <li
                        className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                        onClick={() => {
                          onChange("interestedForUpselling");
                        }}
                      >
                        <div className="ms-2 me-auto">
                          <div className="fw-bold">
                            Interested For Upselling
                          </div>
                          <span className="">
                            {(
                              (interestedForUpselling / totalLeadCount) *
                              100
                            ).toFixed(2)}
                            %
                          </span>
                        </div>
                        <span className="badge text-bg-primary rounded-pill fs-6">
                          {interestedForUpselling}
                        </span>
                      </li>
                      <li
                        className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                        onClick={() => {
                          onChange("prospectForUpselling");
                        }}
                      >
                        <div className="ms-2 me-auto">
                          <div className="fw-bold">Prospect For Upselling</div>
                          <span className="">
                            {(
                              (prospectForUpselling / totalLeadCount) *
                              100
                            ).toFixed(2)}
                            %
                          </span>
                        </div>
                        <span className="badge text-bg-primary rounded-pill fs-6">
                          {prospectForUpselling}
                        </span>
                      </li>
                      <li
                        className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                        onClick={() => {
                          onChange("technicalIssue");
                        }}
                      >
                        <div className="ms-2 me-auto">
                          <div className="fw-bold">Technical Issue</div>
                          <span className="">
                            {((technicalIssue / totalLeadCount) * 100).toFixed(
                              2
                            )}
                            %
                          </span>
                        </div>
                        <span className="badge text-bg-primary rounded-pill fs-6">
                          {technicalIssue}
                        </span>
                      </li>
                    </>
                  ) : null}
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                    onClick={() => {
                      onChange("finalCNR");
                    }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Final CNR</div>
                      <span className="">
                        {((finalCNR / totalLeadCount) * 100).toFixed(2)}%
                      </span>
                    </div>
                    <span className="badge text-bg-primary rounded-pill fs-6">
                      {finalCNR}
                    </span>
                  </li>
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                    onClick={() => {
                      onChange("Summary");
                    }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Summary</div>
                      <span className="">
                        {/* {((finalCNR / totalLeadCount) * 100).toFixed(2)}% */}
                      </span>
                    </div>
                    <span className="badge text-bg-primary rounded-pill fs-6">
                      {0}
                    </span>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
