import { RESET_STORE, SET } from "../Types/authTypes";

export const setInitialState = () => async (dispatch: any) => {
    dispatch({
        type: RESET_STORE
    });
};

export const updateMoreValues = (values: any) => (dispatch: any) => {
    dispatch({
        type: SET,
        payload: values
    });
};

export const updateCommonValues = (key: string, value: any) => async (dispatch: Function) => {    
    dispatch({
        type: SET,
        payload: {
            [key]: value,
        },
    });
};
