import { Pagination } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import CustomDropdown from "../../Component/customDropdown";
import PageTitle from "../../Component/pageTitle";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import SubmitButton from "../../Component/submitButton";
import usePagination from "../../Component/usePagination";
import { getTableDataSlots } from "../../DATA/dataConstant";
import InputField from "../../Component/inputField";
import {
  containsNumber,
  length_Fifty,
  length_Ten,
  length_Three,
  length_Twenty,
  length_Two,
  startsWithSpace,
  validateFullName,
  validateValueWithoutSpace,
} from "../../DATA/validators";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import { validateIsNumberOnlyErrMsg } from "../../DATA/errorMsg";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import ToggleSwitchbtn from "../../Component/toggleSwitchBtn";
import Button from "../../Component/button";
import { restoreEmployee } from "../../Redux/Actions/leadManagementAction";
import moment from "moment";
import RestorePopup from "../../Component/restorePopup";
import {
  getHrEmployeeRoleList,
  getHrEmployeeList,
  addHrEmployee,
  getHrManagerList,
  updateHrEmployee,
  deleteHrEmployee,
} from "../../Redux/Actions/hrPanelAction";

export default function ManageEmployee() {
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const [isAddEmployee, setAddEmployee] = useState(false);
  const [isEditMode, setisEditMode] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(1);
  const [firstName, setFirstname] = useState<any>("");
  const [lastName, setLastname] = useState<any>("");
  const [date, setDate] = useState<any>("");
  const [searchRole, setSearchRole] = useState<any>("");
  const [role, setRole] = useState<any>("");
  const [manager, setManager] = useState<any>("");
  const [currentSalary, setCurrentSalary] = useState<any>("");
  const [userName, setUsername] = useState<any>("");
  const [email, setEmail] = useState<any>("");
  const [password, setPassword] = useState<any>("");
  const [mob, setMob] = useState<any>("");
  const [status, setStatus] = useState(false);
  const [uniqueID, setUniqueId] = useState<any>("");
  const [empId, setEmpId] = useState<any>("");
  const [agentId, setAgentId] = useState<any>("");
  const [agentPin, setAgentPin] = useState<any>("");
  const [agentSipId, setAgentSipId] = useState<any>("");

  const [employeeList, setEmployeeList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [managerList, setManagerList] = useState([]);

  const [resetRole, setResetRole] = useState(false);
  const [resetManager, setResetManager] = useState(false);

  useEffect(() => {
    dispatch(
      getHrEmployeeRoleList((res: any) => {
        setRoleList(res);
      })
    );
    getEmpList("active", searchRole);
  }, []);

  const onChangeSearchRole = (val: any) => {
    setSearchRole(val);
    getEmpList("active", val);
  };

  const getEmpList = (data: string, role: any) => {
    setCount(0);
    setEmployeeList([]);
    let postData: any = {
      skip: 0,
      limit: rowsPerPage,
      status: data,
    };
    if (role) {
      postData["role"] = role?._id;
    }
    dispatch(
      getHrEmployeeList(
        postData,
        (res: any) => {
          setCount(res?.count);
          setEmployeeList(res?.data);
        },
        () => {}
      )
    );
  };

  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data: any = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        status:
          selectedStatus === 1
            ? "active"
            : selectedStatus === 2
            ? "inactive"
            : selectedStatus === 3
            ? "inactive"
            : "",
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        status:
          selectedStatus === 1
            ? "active"
            : selectedStatus === 2
            ? "inactive"
            : selectedStatus === 3
            ? "inactive"
            : "",
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        status:
          selectedStatus === 1
            ? "active"
            : selectedStatus === 2
            ? "inactive"
            : selectedStatus === 3
            ? "inactive"
            : "",
      };
    }
    if (searchRole) {
      data["role"] = searchRole?._id;
    }
    dispatch(
      getHrEmployeeList(
        data,
        (res: any) => {
          setCount(res?.count);
          setEmployeeList(res?.data);
        },
        () => {}
      )
    );
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const showData = (e: any, val: any) => {
    e.preventDefault();
    setSelectedStatus(val);
    if (val === 1) {
      getEmpList("active", searchRole);
    } else if (val === 2) {
      getEmpList("inactive", searchRole);
    } else if (val === 3) {
      getEmpList("deleted", searchRole);
    }
  };

  const handleDelete = (val: any) => {
    setOpenDeletePopup(true);
    setAddEmployee(false);
    setisEditMode(false);
    setEmpId(val?._id);
    setFirstname(val?.firstName);
    setLastname(val?.lastName);
  };

  const handleCloseDelete = () => {
    setOpenDeletePopup(false);
  };

  const handleSubmitDelete = () => {
    setOpenDeletePopup(false);
    dispatch(
      deleteHrEmployee(
        empId,
        () => {
          if (selectedStatus === 1) {
            getEmpList("active", searchRole);
          } else if (selectedStatus === 2) {
            getEmpList("inactive", searchRole);
          } else if (selectedStatus === 3) {
            getEmpList("deleted", searchRole);
          }
        },
        () => {}
      )
    );
  };
  const handleEdit = (value: any) => {
    setAddEmployee(true);
    setisEditMode(true);
    setEmpId(value?._id);
    setFirstname(value?.firstName);
    setLastname(value?.lastName);
    setDate(moment(value?.doj).format("YYYY-MM-DD"));
    dispatch(
      getHrEmployeeRoleList((res: any) => {
        setRoleList(res);
        setRole(value?.role);
      })
    );
    dispatch(
      getHrManagerList(
        (res: any) => {
          setManagerList(res);
          setManager(value?.managerId);
        },
        () => {}
      )
    );
    setCurrentSalary(value?.salary);
    setUsername(value?.username);
    setEmail(value?.email);
    setPassword(value?.password);
    setMob(value?.contactNo);
    setStatus(value?.status);
    setUniqueId(value?.uniqueId?.toUpperCase());
    setAgentId(value?.agentId);
    setAgentPin(value?.agentPin);
    setAgentSipId(value?.agentSIP);
  };

  const handleRestore = (value: any) => {
    setOpenPopup(true);
    setAddEmployee(false);
    setisEditMode(false);
    setEmpId(value?._id);
    setFirstname(value?.firstName);
    setLastname(value?.lastName);
    // setStatus(value?.status);
  };

  const handleAddEmployee = () => {
    setAddEmployee(true);
    setFirstname("");
    setLastname("");
    setEmail("");
    setMob("");
    setCurrentSalary("");
    setDate("");
    setRole("");
    setUsername("");
    setPassword("");
    setRole("");
    setResetRole(true);
    setAgentId("");
    setAgentPin("");
    setAgentSipId("");
    setManager("");
    setResetManager(true);
    setUniqueId("");
    setStatus(false);
    dispatch(
      getHrEmployeeRoleList((res: any) => {
        setRoleList(res);
      })
    );
  };

  const cancelAddEmployee = () => {
    setAddEmployee(false);
  };

  const onchangeFirstName = (val: string) => {
    if (validateFullName(val) && !startsWithSpace(val)) {
      setFirstname(val);
    } else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  };

  const onchangelastName = (val: string) => {
    if (validateFullName(val) && !startsWithSpace(val)) {
      setLastname(val);
    } else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  };

  const onChangeRole = (val: any) => {
    setRole(val);
    setResetRole(false);
    dispatch(
      getHrManagerList(
        (res: any) => {
          setManagerList(res);
        },
        () => {}
      )
    );
  };
  const onChangeManager = (val: string) => {
    setManager(val);
    setResetManager(false);
  };
  const onchangeCurrentSalary = (val: string) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setCurrentSalary(val);
      }
    } else {
      setCurrentSalary(val);
    }
  };

  const onchangeUserName = (val: string) => {
    setUsername(val);
    setAgentId(val);
    setAgentPin(val);
  };
  const onchangeEmail = (val: string) => {
    setEmail(val);
  };
  const onChangePassword = (val: string) => {
    setPassword(val);
  };
  const onChangeContact = (val: string) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setMob(val);
      }
    } else {
      setMob(val);
    }
  };
  const onChangeStatus = (event: any) => {
    if (event.target.checked) {
      setStatus(true);
    } else {
      setStatus(false);
    }
  };

  const onchangeUniqueId = (val: string) => {
    if (validateFullName(val) && !startsWithSpace(val)) {
      setUniqueId(val?.toUpperCase());
    } else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  };

  const onChangeAgentId = (val: string) => {
    setAgentId(val);
  };

  const onChangeAgentPin = (val: string) => {
    setAgentPin(val);
  };

  const onChangeAgentSipId = (val: string) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setAgentSipId(val);
      }
    } else {
      setAgentSipId(val);
    }
  };

  const cancelEditEmployee = () => {
    setAddEmployee(false);
    setisEditMode(false);
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    let postData = {
      firstName: firstName?.toUpperCase(),
      lastName: lastName?.toUpperCase(),
      doj: date,
      role: role?._id,
      managerId: manager?._id,
      salary: currentSalary,
      username: userName?.toUpperCase(),
      email: email,
      password: password?.toUpperCase(),
      contactNo: mob,
      status: status ? 1 : 0,
      uniqueId: uniqueID,
      agentId: agentId?.toUpperCase(),
      agentPin: agentPin?.toUpperCase(),
      agentSIP: agentSipId,
    };
    dispatch(
      addHrEmployee(
        postData,
        () => {
          setFirstname("");
          setLastname("");
          setDate("");
          setRole("");
          setResetRole(true);
          setManager("");
          setResetManager(true);
          setCurrentSalary("");
          setUsername("");
          setEmail("");
          setPassword("");
          setMob("");
          setStatus(false);
          setUniqueId("");
          setAgentId("");
          setAgentPin("");
          setAgentSipId("");
          setAddEmployee(false);
          setisEditMode(false);
          getEmpList("active", searchRole);
        },
        () => {}
      )
    );
  };

  const handleUpdate = (e: any) => {
    e.preventDefault();

    let postData: any = {
      firstName: firstName?.toUpperCase(),
      lastName: lastName?.toUpperCase(),
      doj: date,
      role: role?._id,
      managerId: manager?._id,
      salary: currentSalary,
      email: email,
      contactNo: mob,
      status: status ? 1 : 0,
      agentId: agentId?.toUpperCase(),
      agentPin: agentPin?.toUpperCase(),
      agentSIP: agentSipId,
    };
    dispatch(
      updateHrEmployee(
        empId,
        postData,
        () => {
          setFirstname("");
          setLastname("");
          setDate("");
          setRole("");
          setResetRole(true);
          setManager("");
          setResetManager(true);
          setCurrentSalary("");
          setUsername("");
          setEmail("");
          setPassword("");
          setMob("");
          setStatus(false);
          setUniqueId("");
          setAgentId("");
          setAgentPin("");
          setAgentSipId("");
          setAddEmployee(false);
          setisEditMode(false);
          getEmpList("active", searchRole);
        },
        () => {}
      )
    );
  };

  const handleClose = () => {
    setOpenPopup(false);
  };

  const handleSubmitRestore = () => {
    setOpenPopup(false);
    let postData = {
      status: 0,
    };
    dispatch(
      restoreEmployee(
        empId,
        postData,
        () => {
          setOpenPopup(false);
          setAddEmployee(false);
          setisEditMode(false);
          getEmpList("deleted", searchRole);
        },
        () => {
          setOpenPopup(false);
        }
      )
    );
  };

  const renderListMode = () => {
    return (
      <>
        {employeeList?.length > 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}

        {/* {count > 0 ? ( */}
        <PaginationView
          pagename={"HrEmployee"}
          count={count}
          tableData={employeeList ? employeeList : []}
          tabs={["Active", "De-Active", "Deleted"]}
          onTabChange={(e: any, index: any) => showData(e, index)}
          selectedIndex={selectedStatus}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={getTableDataSlots(count)}
          handleClick={handleAddEmployee}
          isExport={true}
          exportRef={tableRef}
          roleList={roleList}
          role={searchRole}
          onChangeRole={onChangeSearchRole}
        />
        {/* ) : null} */}

        {selectedStatus === 1 ? (
          <PaginationTable
            tableData={employeeList ? employeeList : []}
            pagename={"HrEmployee"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={[
              "S.N",
              "Unique ID",
              "First Name",
              "Last Name",
              "Mobile No.",
              "Employee Type",
              "Manager / Team Lead",
              "Experience (Months)",
              "Status",
              "Edit",
              "Delete",
            ]}
            edit={true}
            handleEdit={(value: any) => handleEdit(value)}
            handleDelete={(value: any) => handleDelete(value)}
          />
        ) : selectedStatus === 2 ? (
          <PaginationTable
            tableData={employeeList ? employeeList : []}
            pagename={"HrEmployee"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={[
              "S.N",
              "Unique ID",
              "First Name",
              "Last Name",
              "Mobile No.",
              "Employee Type",
              "Manager / Team Lead",
              "Experience (Months)",
              "Status",
              "Edit",
              "Delete",
            ]}
            edit={true}
            handleEdit={(value: any) => handleEdit(value)}
            handleDelete={(value: any) => handleDelete(value)}
          />
        ) : (
          <PaginationTable
            tableData={employeeList ? employeeList : []}
            pagename={"HrEmployee"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={[
              "S.N",
              "Unique ID",
              "First Name",
              "Last Name",
              "Mobile No.",
              "Employee Type",
              "Manager / Team Lead",
              "Experience (Months)",
              "Status",
              "Restore",
              "",
            ]}
            edit={true}
            handleEdit={(value: any) => handleRestore(value)}
          />
        )}
      </>
    );
  };

  const renderAddMode = () => {
    return (
      <>
        {!isEditMode ? (
          <h2 className="text-primary">Add Employee</h2>
        ) : (
          <h2 className="text-primary">Edit Employee</h2>
        )}
        <form
          className="col-md-12  shadow-lg py-4 px-3"
          onSubmit={!isEditMode ? handleSubmit : handleUpdate}
        >
          <div className="row">
            <div className="col-md-4 my-1">
              <InputField
                placeholder="Enter First Name"
                value={firstName}
                onChangeInput={(value: any) => onchangeFirstName(value)}
                maxlength={length_Fifty - 1}
                minlength={length_Three}
                isRequired={true}
                disabled={isEditMode}
              />
            </div>
            <div className="col-md-4 my-1">
              <InputField
                placeholder="Enter Last Name"
                value={lastName}
                onChangeInput={(value: any) => onchangelastName(value)}
                maxlength={length_Fifty - 1}
                minlength={length_Three}
                isRequired={true}
                disabled={isEditMode}
              />
            </div>
            <div className="col-md-4 my-1">
              <InputField
                placeholder="Enter Email"
                value={email}
                onChangeInput={(value: any) => onchangeEmail(value)}
                isRequired={true}
                // disabled={isEditMode}
              />
            </div>
            <div className="col-md-4 my-1">
              <InputField
                placeholder="Enter Mobile Number"
                value={mob}
                onChangeInput={(value: any) => onChangeContact(value)}
                maxlength={length_Ten}
                minlength={length_Ten}
                // disabled={isEditMode}
                isRequired={true}
              />
            </div>
            <div className="col-md-4 my-1">
              <InputField
                placeholder="Enter Current Salary"
                value={currentSalary}
                onChangeInput={(value: any) => onchangeCurrentSalary(value)}
                maxlength={length_Ten}
                minlength={length_Two}
                isRequired={true}
              />
            </div>
            <div className="col-md-4 d-flex my-1">
              <div className="col-5 mt-2 ms-2">
                <LabelField lableName="Date Of Joining" />
              </div>
              <DatePicker
                labelName={"Date of Joining"}
                value={date}
                setInputText={(value: string) => setDate(value)}
                Isrequired={true}
                defaultValue={date}
                maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                // disabled={isEditMode}
              />
            </div>
            <div className="col-md-4 my-1">
              <InputField
                placeholder="Enter User Name"
                value={userName}
                onChangeInput={(value: any) => onchangeUserName(value)}
                maxlength={length_Twenty}
                minlength={length_Two}
                isRequired={true}
                disabled={isEditMode}
              />
            </div>
            <div className="col-md-4 my-1">
              <InputField
                placeholder="Enter Password"
                value={password}
                onChangeInput={(value: any) => onChangePassword(value)}
                disabled={isEditMode}
                isRequired={true}
              />
            </div>
            <div className="col-md-4 my-1">
              <CustomDropdown
                lableName="Select Role"
                setInputText={(value: any) => onChangeRole(value)}
                value={role}
                options={roleList}
                Isrequired={true}
                reset={resetRole}
                defaultValue={role?._id}
                // disabled={isEditMode}
              />
            </div>

            <div className="col-md-4 my-1">
              <InputField
                placeholder="Enter Ozonetel Agent ID"
                value={agentId}
                onChangeInput={(value: any) => onChangeAgentId(value)}
                disabled={true}
                isRequired={true}
              />
            </div>
            <div className="col-md-4 my-1">
              <InputField
                placeholder="Enter Ozonetel Agent PIN"
                value={agentPin}
                onChangeInput={(value: any) => onChangeAgentPin(value)}
                disabled={true}
                isRequired={true}
              />
            </div>

            <div className="col-md-4 my-1">
              <CustomDropdown
                lableName="Select Manager"
                setInputText={(value: any) => onChangeManager(value)}
                value={manager}
                options={managerList}
                Isrequired={true}
                reset={resetManager}
                defaultValue={manager?._id}
                disabled={role?._id === 2 ? true : false}
              />
            </div>
            <div className="col-md-3 my-1">
              <InputField
                placeholder="Enter Ozonetel Agent SIP ID"
                value={agentSipId}
                onChangeInput={(value: any) => onChangeAgentSipId(value)}
                // disabled={isEditMode}
                isRequired={true}
              />
            </div>
            <div className="col-md-4 my-1">
              <InputField
                placeholder="Enter Unique Id"
                value={uniqueID}
                onChangeInput={(value: any) => onchangeUniqueId(value)}
                isRequired={role ? true : false}
                // disabled={isEditMode || role ? true : false}
              />
            </div>
            <div className="col-md-4 my-1 ms-2">
              <ToggleSwitchbtn
                lableName="Status"
                getToggleval={(value: any) => {
                  onChangeStatus(value);
                }}
                isActive={status}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end me-3 mt-2">
            <div className="me-2">
              {!isEditMode ? (
                <Button name={"Cancel"} handleClick={cancelAddEmployee} />
              ) : (
                <Button name={"Cancel"} handleClick={cancelEditEmployee} />
              )}
            </div>
            {!isEditMode ? (
              <div>
                <SubmitButton name={"Save"} validation={validation()} />
              </div>
            ) : (
              <div>
                <SubmitButton name={"Save & Update"} />
              </div>
            )}
          </div>
        </form>
      </>
    );
  };

  const validation = () => {
    return (
      firstName === "" ||
      firstName?.length < 3 ||
      lastName === "" ||
      lastName?.length < 3 ||
      date === "" ||
      role === "" ||
      userName === "" ||
      email === "" ||
      password === "" ||
      mob === "" ||
      mob?.length < 10 ||
      currentSalary === ""
    );
  };

  return (
    <div className="contentBox">
      <PageTitle
        name={"Manage HR Employee"}
        isEditMode={isEditMode}
        setisEditMode={setisEditMode}
      />
      <div className="formDiv">
        <div className="col-md-12  px-4 mx-0 mt-3">
          {!isAddEmployee && !isEditMode ? renderListMode() : renderAddMode()}
        </div>
      </div>
      {openPopup ? (
        <RestorePopup
          open={openPopup}
          handleClose={handleClose}
          handleSubmitRestore={() => {
            handleSubmitRestore();
          }}
          title={"Restore Employee"}
          data={"Do you want to restore this employee ?"}
          buttonTitle={"Restore"}
        />
      ) : (
        ""
      )}
      {openDeletePopup ? (
        <RestorePopup
          open={openDeletePopup}
          handleClose={handleCloseDelete}
          handleSubmitRestore={() => {
            handleSubmitDelete();
          }}
          title={"Delete Employee"}
          data={"Do you want to delete this ?"}
          buttonTitle={"Delete"}
        />
      ) : (
        ""
      )}
      <div ref={tableRef} className="d-none">
        <table className="w-100">
          <thead className="font-12">
            <tr>
              <th>
                <label>Sr. No.</label>
              </th>
              <th>
                <label>Unique ID</label>
              </th>
              <th>
                <label>First Name</label>
              </th>
              <th>
                <label>Last Name</label>
              </th>
              <th>
                <label>Mobile No.</label>
              </th>
              <th>
                <label>Employee Type</label>
              </th>
              <th>
                <label>Manager / Team Lead</label>
              </th>
            </tr>
          </thead>
          <tbody style={{ backgroundColor: "#E8EAF5" }}>
            {employeeList?.map((item: any, index: number) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item?.uniqueId}</td>
                  <td>{item?.firstName}</td>
                  <td>{item?.lastName}</td>
                  <td>{item?.contactNo}</td>
                  <td>{item?.role?.name}</td>
                  <td>{item?.managerId?.name}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
