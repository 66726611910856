import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import moment from "moment";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import {
  getManagerwiseAllocatedRevenue,
  getTeamwiseAllocatedRevenueTargetByManagerId,
  getAllocatedRevenueTargetByExams,
  getManagerwiseOtherRevenue,
  getTeamwiseOtherRevenueTargetByManagerId,
  getOtherRevenueTargetByExams,
  getManagerwisePublicationRevenue,
  getTeamwisePublicationRevenueTargetByManagerId,
  getPublicationRevenueTargetByExams,
  getAllocatedRevenueTargetExamsByCounsellor,
  getPublicationRevenueTargetExamsByCounsellor,
  getOtherRevenueTargetExamsByCounsellor,
} from "../../Redux/Actions/superManagerAction";
import ReplyIcon from "@mui/icons-material/Reply";
import SyncIcon from "@mui/icons-material/Sync";

const initialVal: any = {
  phdData: [],
  mscData: [],
  ugcData: [],
};

export default function SalesDirectorDashboard() {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [selected, setSelected] = useState("allocated");
  const [inboundList, setInboundList] = useState<any>([]);
  const [outboundList, setOutboundList] = useState<any>([]);
  const [counsellorList, setCounsellorList] = useState<any>([]);
  const [overallTarget, setOverallTarget] = useState<any>([]);
  const [teamLeadName, setTeamLeadName] = useState<any>("");
  const [counsellorName, setCounsellorName] = useState<any>("");
  const [isViewMode, setIsViewMode] = useState<any>(false);

  const [totalTransactionAmount, setTotalTransactionAmount] = useState<any>(0);

  const [sectionArr, setSectionArr] = useState(initialVal);

  const { loggedInUserRole, loggedInUserId, loggedInUserName } = useSelector(
    (state: any) => ({
      loggedInUserRole: state.Auth.loggedInUserRole,
      loggedInUserId: state.Auth.loggedInUserId,
      loggedInUserName: state.Auth.loggedInUserName,
    })
  );

  useEffect(() => {
    displayManagerwiseAllocatedRevenue();
  }, []);

  const onChangeSelected = (value: string) => {
    setSelected(value);
    setInboundList([]);
    setOutboundList([]);
    setOverallTarget([]);
    if (value === "allocated") {
      displayManagerwiseAllocatedRevenue();
    } else if (value === "other") {
      displayManagerwiseOtherRevenue();
    } else if (value === "publication") {
      displayManagerwisePublicationRevenue();
    }
  };

  const displayManagerwiseAllocatedRevenue = () => {
    dispatch(
      getManagerwiseAllocatedRevenue(
        {
          startDate,
          endDate,
        },
        (res: any) => {
          let inbound = res?.filter((item: any) => item?.role[0] === 5);

          let tempData = res?.sort((a: any, b: any) => {
            return b.role - a.role;
          });

          let outbound = tempData?.filter((item: any) => item?.role[0] !== 5);

          const inboundTotals = calculateTotals(inbound);
          const outboundTotals = calculateTotals(outbound);
          const overallTotals = calculateOverallTotals(res);

          let inboundData: any = {
            totalInbound: inboundTotals,
            Inbound: inbound,
          };

          let outboundData: any = {
            totalOutbound: outboundTotals,
            Outbound: outbound,
          };

          setInboundList(inboundData);
          setOutboundList(outboundData);
          setOverallTarget(overallTotals);
        },
        () => {}
      )
    );
  };

  const displayManagerwiseOtherRevenue = () => {
    dispatch(
      getManagerwiseOtherRevenue(
        {
          startDate,
          endDate,
        },
        (res: any) => {
          let tempData = res?.sort((a: any, b: any) => {
            return b.role - a.role;
          });

          const outboundTotals = calculateTotals(tempData);
          const overallTotals = calculateOverallTotals(res);

          let outboundData: any = {
            totalOutbound: outboundTotals,
            Outbound: tempData,
          };

          setOutboundList(outboundData);
          setOverallTarget(overallTotals);
        },
        () => {}
      )
    );
  };

  const displayManagerwisePublicationRevenue = () => {
    dispatch(
      getManagerwisePublicationRevenue(
        {
          startDate,
          endDate,
        },
        (res: any) => {
          let tempData = res?.sort((a: any, b: any) => {
            return b.role - a.role;
          });

          const outboundTotals = calculateTotals(tempData);
          const overallTotals = calculateOverallTotals(res);

          let outboundData: any = {
            totalOutbound: outboundTotals,
            Outbound: tempData,
          };

          setOutboundList(outboundData);
          setOverallTarget(overallTotals);
        },
        () => {}
      )
    );
  };

  const calculateTotals = (items: any) => {
    return items.reduce(
      (acc: any, item: any) => {
        acc.totalTarget += item.targetRevenue || 0;
        acc.totalAchievedRevenue += item.achievedRevenue || 0;
        acc.totalAchievedReference += item.achievedLeadCount || 0;
        acc.totalTargetReference += item.referenceleadTargetCount || 0;
        return acc;
      },
      {
        totalTarget: 0,
        totalAchievedRevenue: 0,
        totalAchievedReference: 0,
        totalTargetReference: 0,
      }
    );
  };

  const calculateOverallTotals = (items: any) => {
    return items.reduce(
      (acc: any, item: any) => {
        if (item?._id === 3) {
          item?.teamLeadData?.map((subItem: any) => {
            acc.totalTarget += subItem?.targetRevenue;
          });
        } else if (item?._id !== 3) {
          acc.totalTarget += item?.targetRevenue;
        }

        // acc.totalTarget += item.targetRevenue || 0;
        acc.totalAchievedRevenue += item.achievedRevenue || 0;
        acc.totalAchievedReference += item.achievedLeadCount || 0;
        acc.totalTargetReference += item.referenceleadTargetCount || 0;
        return acc;
      },
      {
        totalTarget: 0,
        totalAchievedRevenue: 0,
        totalAchievedReference: 0,
        totalTargetReference: 0,
      }
    );
  };

  const onClickLead = (data: any) => {
    setIsViewMode(true);
    setTeamLeadName(data);
    setSectionArr(initialVal);
    setCounsellorList([]);
    if (selected === "allocated") {
      displayTeamwiseAllocatedRevenueTargetByManagerId(data?._id);
    } else if (selected === "other") {
      displayTeamwiseOtherRevenueTargetByManagerId(data?._id);
    } else if (selected === "publication") {
      displayTeamwisePublicationRevenueTargetByManagerId(data?._id);
    }
  };

  const displayTeamwiseAllocatedRevenueTargetByManagerId = (id: any) => {
    dispatch(
      getTeamwiseAllocatedRevenueTargetByManagerId(
        {
          startDate,
          endDate,
          managerId: id,
        },
        (res: any) => {
          if (res?.length > 0) {
            const tempTotals = calculateTotals(res);
            let tempData: any = {
              total: tempTotals,
              data: res,
            };
            setCounsellorList(tempData);
          } else {
            setCounsellorList([]);
          }
        },
        () => {}
      )
    );
    refreshExamRevenue(id);
  };

  const displayTeamwiseOtherRevenueTargetByManagerId = (id: any) => {
    dispatch(
      getTeamwiseOtherRevenueTargetByManagerId(
        {
          startDate,
          endDate,
          managerId: id,
        },
        (res: any) => {
          if (res?.length > 0) {
            const tempTotals = calculateTotals(res);
            let tempData: any = {
              total: tempTotals,
              data: res,
            };
            setCounsellorList(tempData);
          } else {
            setCounsellorList([]);
          }
        },
        () => {}
      )
    );
    refreshExamRevenue(id);
  };

  const displayTeamwisePublicationRevenueTargetByManagerId = (id: any) => {
    dispatch(
      getTeamwisePublicationRevenueTargetByManagerId(
        {
          startDate,
          endDate,
          managerId: id,
        },
        (res: any) => {
          if (res?.length > 0) {
            const tempTotals = calculateTotals(res);
            let tempData: any = {
              total: tempTotals,
              data: res,
            };
            setCounsellorList(tempData);
          } else {
            setCounsellorList([]);
          }
        },
        () => {}
      )
    );
    refreshExamRevenue(id);
  };

  const refreshExamRevenue = (id: any) => {
    setCounsellorName("");
    setTotalTransactionAmount(0);
    setSectionArr(initialVal);
    if (selected === "allocated") {
      dispatch(
        getAllocatedRevenueTargetByExams(
          {
            startDate,
            endDate,
            managerId: id,
          },
          (res: any) => {
            setTotalTransactionAmount(res?.totalRevenue);
            const phdData: any = res?.sectionList?.filter((itm: any) => {
              return itm?.sectionName === "PHD";
            });
            const mscData: any = res?.sectionList?.filter((itm: any) => {
              return itm?.sectionName === "MSC";
            });
            const ugcData: any = res?.sectionList?.filter((itm: any) => {
              return itm?.sectionName === "UGCNET";
            });
            setSectionArr({
              phdData,
              mscData,
              ugcData,
            });
          },
          () => {}
        )
      );
    } else if (selected === "other") {
      dispatch(
        getOtherRevenueTargetByExams(
          {
            startDate,
            endDate,
            managerId: id,
          },
          (res: any) => {
            setTotalTransactionAmount(res?.totalRevenue);
            const phdData: any = res?.sectionList?.filter((itm: any) => {
              return itm?.sectionName === "PHD";
            });
            const mscData: any = res?.sectionList?.filter((itm: any) => {
              return itm?.sectionName === "MSC";
            });
            const ugcData: any = res?.sectionList?.filter((itm: any) => {
              return itm?.sectionName === "UGCNET";
            });
            setSectionArr({
              phdData,
              mscData,
              ugcData,
            });
          },
          () => {}
        )
      );
    } else if (selected === "publication") {
      dispatch(
        getPublicationRevenueTargetByExams(
          {
            startDate,
            endDate,
            managerId: id,
          },
          (res: any) => {
            setTotalTransactionAmount(res?.totalRevenue);
            const phdData: any = res?.sectionList?.filter((itm: any) => {
              return itm?.sectionName === "PHD";
            });
            const mscData: any = res?.sectionList?.filter((itm: any) => {
              return itm?.sectionName === "MSC";
            });
            const ugcData: any = res?.sectionList?.filter((itm: any) => {
              return itm?.sectionName === "UGCNET";
            });
            setSectionArr({
              phdData,
              mscData,
              ugcData,
            });
          },
          () => {}
        )
      );
    }
  };

  const onClickCounsellor = (data: any) => {
    setIsViewMode(true);
    setCounsellorName(
      selected === "allocated" ? data?.counsellorName : data?.name
    );
    setSectionArr(initialVal);
    if (selected === "allocated") {
      dispatch(
        getAllocatedRevenueTargetExamsByCounsellor(
          {
            startDate,
            endDate,
            counsellorId: data?._id,
          },
          (res: any) => {
            setTotalTransactionAmount(res?.totalRevenue);
            const phdData: any = res?.sectionList?.filter((itm: any) => {
              return itm?.sectionName === "PHD";
            });
            const mscData: any = res?.sectionList?.filter((itm: any) => {
              return itm?.sectionName === "MSC";
            });
            const ugcData: any = res?.sectionList?.filter((itm: any) => {
              return itm?.sectionName === "UGCNET";
            });
            setSectionArr({
              phdData,
              mscData,
              ugcData,
            });
          },
          () => {}
        )
      );
    } else if (selected === "other") {
      dispatch(
        getOtherRevenueTargetExamsByCounsellor(
          {
            startDate,
            endDate,
            counsellorId: data?._id,
          },
          (res: any) => {
            setTotalTransactionAmount(res?.totalRevenue);
            const phdData: any = res?.sectionList?.filter((itm: any) => {
              return itm?.sectionName === "PHD";
            });
            const mscData: any = res?.sectionList?.filter((itm: any) => {
              return itm?.sectionName === "MSC";
            });
            const ugcData: any = res?.sectionList?.filter((itm: any) => {
              return itm?.sectionName === "UGCNET";
            });
            setSectionArr({
              phdData,
              mscData,
              ugcData,
            });
          },
          () => {}
        )
      );
    } else if (selected === "publication") {
      dispatch(
        getPublicationRevenueTargetExamsByCounsellor(
          {
            startDate,
            endDate,
            counsellorId: data?._id,
          },
          (res: any) => {
            setTotalTransactionAmount(res?.totalRevenue);
            const phdData: any = res?.sectionList?.filter((itm: any) => {
              return itm?.sectionName === "PHD";
            });
            const mscData: any = res?.sectionList?.filter((itm: any) => {
              return itm?.sectionName === "MSC";
            });
            const ugcData: any = res?.sectionList?.filter((itm: any) => {
              return itm?.sectionName === "UGCNET";
            });
            setSectionArr({
              phdData,
              mscData,
              ugcData,
            });
          },
          () => {}
        )
      );
    }
  };

  const calculateExamTotals = (items: any) => {
    return items.reduce(
      (acc: any, item: any) => {
        acc.totalAchievedRevenue += item.achievedRevenue || 0;
        return acc;
      },
      {
        totalAchievedRevenue: 0,
      }
    );
  };

  const handleBack = () => {
    setIsViewMode(false);
  };

  const renderListMode = () => {
    return (
      <>
        <hr className="hr"></hr>
        <div className="d-flex justify-content-center">
          <div
            className="btn-group"
            role="group"
            aria-label="Basic radio toggle button group"
          >
            <input
              type="radio"
              className="btn-check"
              name="btnradio"
              id="allocated"
              autoComplete="off"
              checked={selected === "allocated"}
              onChange={() => onChangeSelected("allocated")}
            />
            <label className="btn btn-outline-primary" htmlFor="allocated">
              Allocated Exams
            </label>

            <input
              type="radio"
              className="btn-check"
              name="btnradio"
              id="other"
              autoComplete="off"
              checked={selected === "other"}
              onChange={() => onChangeSelected("other")}
            />
            <label className="btn btn-outline-primary" htmlFor="other">
              Other Exams
            </label>

            <input
              type="radio"
              className="btn-check"
              name="btnradio"
              id="publication"
              autoComplete="off"
              checked={selected === "publication"}
              onChange={() => onChangeSelected("publication")}
            />
            <label className="btn btn-outline-primary" htmlFor="publication">
              Publication
            </label>
          </div>
        </div>
        {selected === "allocated" ? (
          <div className="boxshadow rounded pb-5">
            <div className="mt-2">
              <div className="row d-flex flex-wrap">
                <div style={{ maxWidth: "20%" }}>
                  <div className="d-flex justify-content-center p-2 rounded m-2 border boxshadow">
                    <div className="text-center px-5">
                      <p className="fw-bold font24">Target</p>
                      <p className="fw-bold text-success font24">
                        {overallTarget?.totalTarget?.toFixed(2)}
                      </p>
                    </div>
                  </div>
                </div>
                <div style={{ maxWidth: "20%" }}>
                  <div className="d-flex justify-content-center p-2 rounded m-2 border boxshadow">
                    <div className="text-center px-5">
                      <p className="fw-bold font24">ACHIEVED</p>
                      <p className="fw-bold text-success font24">
                        {overallTarget?.totalAchievedRevenue?.toFixed(2)}
                      </p>
                    </div>
                  </div>
                </div>
                <div style={{ maxWidth: "20%" }}>
                  <div className="d-flex justify-content-center p-2 rounded m-2 border boxshadow">
                    <div className="text-center px-5">
                      <p className="fw-bold font24">PERCENTAGE</p>
                      <p className="fw-bold text-success font24">
                        {overallTarget?.totalAchievedRevenue
                          ? (
                              (overallTarget?.totalAchievedRevenue /
                                overallTarget?.totalTarget) *
                              100
                            )?.toFixed(2)
                          : 0}
                        %
                      </p>
                    </div>
                  </div>
                </div>
                <div style={{ maxWidth: "20%" }}>
                  <div className="d-flex justify-content-center p-2 rounded m-2 border boxshadow">
                    <div className="text-center px-5">
                      <p className="fw-bold font24">DEFICIT</p>
                      <p className="fw-bold text-danger font24">
                        {(
                          overallTarget?.totalTarget -
                          overallTarget?.totalAchievedRevenue
                        )?.toFixed(2)}
                      </p>
                    </div>
                  </div>
                </div>
                <div style={{ maxWidth: "20%" }}>
                  <div className="d-flex justify-content-center p-2 rounded m-2 border boxshadow">
                    <div className="text-center px-5">
                      <p className="fw-bold font24">REFERENCE</p>
                      <p className="fw-bold text-success font24">
                        {`${overallTarget?.totalAchievedReference} / ${overallTarget?.totalTargetReference}`}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mx-0">
              <div className="mt-2">
                <h6 className="ms-2 fw-bold text-primary">INBOUND</h6>
                <div className="mx-2 px-0">
                  <table className="w-100">
                    <thead>
                      <tr className="border-dark border text-dark">
                        <th
                          className="border-dark border"
                          rowSpan={2}
                          style={{ width: "3rem" }}
                        >
                          S.R.
                        </th>
                        <th
                          className="border-dark border"
                          rowSpan={2}
                          style={{ width: "10rem" }}
                        >
                          Manager
                        </th>
                        <th
                          className="border-dark border"
                          style={{ width: "5rem" }}
                        >
                          TARGET REVENUE
                        </th>
                        <th
                          className="border-dark border"
                          style={{ width: "5rem" }}
                        >
                          ACHIEVED
                        </th>
                        <th
                          className="border-dark border"
                          style={{ width: "10rem" }}
                        >
                          PERCENTAGE ACHIEVED
                        </th>
                        <th
                          className="border-dark border"
                          style={{ width: "5rem" }}
                        >
                          DEFICIT
                        </th>
                        <th
                          className="border-dark border"
                          style={{ width: "10rem" }}
                        >
                          REFERENCE GENERATED
                        </th>
                      </tr>
                      <tr className="text-dark">
                        <th className="border-dark border bg-white text-dark">
                          {inboundList?.totalInbound?.totalTarget?.toFixed(2)}
                        </th>
                        <th className="border-dark border bg-white text-dark">
                          {inboundList?.totalInbound?.totalAchievedRevenue?.toFixed(
                            2
                          )}
                        </th>
                        <th className="border-dark border bg-white text-dark">
                          {inboundList?.totalInbound?.totalAchievedRevenue
                            ? (
                                (inboundList?.totalInbound
                                  ?.totalAchievedRevenue /
                                  inboundList?.totalInbound?.totalTarget) *
                                100
                              )?.toFixed(2)
                            : 0}
                          %
                        </th>
                        <th className="border-dark border bg-white text-danger">
                          {(
                            inboundList?.totalInbound?.totalTarget -
                            inboundList?.totalInbound?.totalAchievedRevenue
                          )?.toFixed(2)}
                        </th>
                        <th className="border-dark border bg-white text-dark">
                          {`${inboundList?.totalInbound?.totalAchievedReference} / ${inboundList?.totalInbound?.totalTargetReference}`}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="border-dark border text-dark">
                      {inboundList?.Inbound?.map((item: any, index: number) => {
                        return (
                          <tr
                            onClick={() => {
                              onClickLead(item);
                            }}
                          >
                            <td className="border-dark border bg-white text-dark">
                              {index + 1}
                            </td>
                            <td className="border-dark border bg-white text-dark">
                              {item?.name}
                            </td>
                            <td className="border-dark border bg-white text-dark">
                              {item?.targetRevenue?.toFixed(2)}
                            </td>
                            <td className="border-dark border bg-white text-dark">
                              {item?.achievedRevenue?.toFixed(2)}
                            </td>
                            <td className="border-dark border bg-white text-dark">
                              {item?.percent ? item?.percent : 0}%
                            </td>
                            <td className="border-dark border bg-white text-danger">
                              {item?.deficit?.toFixed(2)}
                            </td>
                            <td className="border-dark border bg-white text-dark">
                              {`${item?.achievedLeadCount} / ${item?.referenceleadTargetCount}`}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="mt-2 ">
                <h6 className="ms-2 fw-bold text-primary">OUTBOUND</h6>
                <div className="ms-2 px-0">
                  <table className="w-100">
                    <thead>
                      <tr className="border-dark border text-dark">
                        <th
                          className="border-dark border"
                          rowSpan={2}
                          style={{ width: "3rem" }}
                        >
                          S.R.
                        </th>
                        <th
                          className="border-dark border"
                          rowSpan={2}
                          style={{ width: "10rem" }}
                        >
                          Manager
                        </th>
                        <th
                          className="border-dark border"
                          style={{ width: "5rem" }}
                        >
                          TARGET REVENUE
                        </th>
                        <th
                          className="border-dark border"
                          style={{ width: "5rem" }}
                        >
                          ACHIEVED
                        </th>
                        <th
                          className="border-dark border"
                          style={{ width: "10rem" }}
                        >
                          PERCENTAGE ACHIEVED
                        </th>
                        <th
                          className="border-dark border"
                          style={{ width: "5rem" }}
                        >
                          DEFICIT
                        </th>
                        <th
                          className="border-dark border"
                          style={{ width: "10rem" }}
                        >
                          REFERENCE GENERATED
                        </th>
                      </tr>
                      <tr className="text-dark">
                        <th className="border-dark border bg-white text-dark">
                          {outboundList?.totalOutbound?.totalTarget?.toFixed(2)}
                        </th>
                        <th className="border-dark border bg-white text-dark">
                          {outboundList?.totalOutbound?.totalAchievedRevenue?.toFixed(
                            2
                          )}
                        </th>
                        <th className="border-dark border bg-white text-dark">
                          {outboundList?.totalOutbound?.totalAchievedRevenue
                            ? (
                                (outboundList?.totalOutbound
                                  ?.totalAchievedRevenue /
                                  outboundList?.totalOutbound?.totalTarget) *
                                100
                              )?.toFixed(2)
                            : 0}
                          %
                        </th>
                        <th className="border-dark border bg-white text-danger">
                          {(
                            outboundList?.totalOutbound?.totalTarget -
                            outboundList?.totalOutbound?.totalAchievedRevenue
                          )?.toFixed(2)}
                        </th>
                        <th className="border-dark border bg-white text-dark">
                          {`${outboundList?.totalOutbound?.totalAchievedReference} / ${outboundList?.totalOutbound?.totalTargetReference}`}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="border-dark border text-dark">
                      {outboundList?.Outbound?.map(
                        (item: any, index: number) => {
                          return (
                            <>
                              <tr
                                onClick={() => {
                                  onClickLead(item);
                                }}
                              >
                                <td className="border-dark border bg-white text-dark">
                                  {index + 1}
                                </td>
                                <td className="border-dark border bg-white text-dark">
                                  {item?.name}
                                </td>
                                <td className="border-dark border bg-white text-dark">
                                  {item?.targetRevenue?.toFixed(2)}
                                </td>
                                <td className="border-dark border bg-white text-dark">
                                  {item?.achievedRevenue?.toFixed(2)}
                                </td>
                                <td className="border-dark border bg-white text-dark">
                                  {item?.percent ? item?.percent : 0}%
                                </td>
                                <td className="border-dark border bg-white text-danger">
                                  {item?.deficit?.toFixed(2)}
                                </td>
                                <td className="border-dark border bg-white text-dark">
                                  {`${item?.achievedLeadCount} / ${item?.referenceleadTargetCount}`}
                                </td>
                              </tr>
                            </>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {selected === "other" ? (
          <div className="boxshadow rounded pb-5">
            <div className="mt-2">
              <div className="row d-flex flex-wrap justify-content-center">
                <div style={{ maxWidth: "20%" }}>
                  <div className="d-flex justify-content-center p-2 rounded m-2 border boxshadow">
                    <div className="text-center px-5">
                      <p className="fw-bold font24">ACHIEVED</p>
                      <p className="fw-bold text-success font24">
                        {overallTarget?.totalAchievedRevenue?.toFixed(2)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mx-0">
              <div className="mt-2 ">
                <h6 className="ms-2 fw-bold text-primary">OUTBOUND</h6>
                <div className="ms-2 px-0">
                  <table className="w-100">
                    <thead>
                      <tr className="border-dark border text-dark">
                        <th
                          className="border-dark border"
                          rowSpan={2}
                          style={{ width: "3rem" }}
                        >
                          S.R.
                        </th>
                        <th
                          className="border-dark border"
                          rowSpan={2}
                          style={{ width: "10rem" }}
                        >
                          Manager
                        </th>

                        <th
                          className="border-dark border"
                          style={{ width: "5rem" }}
                        >
                          ACHIEVED
                        </th>
                      </tr>
                      <tr className="text-dark">
                        <th className="border-dark border bg-white text-dark">
                          {outboundList?.totalOutbound?.totalAchievedRevenue?.toFixed(
                            2
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="border-dark border text-dark">
                      {outboundList?.Outbound?.map(
                        (item: any, index: number) => {
                          return (
                            <>
                              <tr
                                onClick={() => {
                                  onClickLead(item);
                                }}
                              >
                                <td className="border-dark border bg-white text-dark">
                                  {index + 1}
                                </td>
                                <td className="border-dark border bg-white text-dark">
                                  {item?.name}
                                </td>
                                <td className="border-dark border bg-white text-dark">
                                  {item?.achievedRevenue?.toFixed(2)}
                                </td>
                              </tr>
                            </>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {selected === "publication" ? (
          <div className="boxshadow rounded pb-5">
            <div className="mt-2">
              <div className="row d-flex flex-wrap justify-content-center">
                <div style={{ maxWidth: "20%" }}>
                  <div className="d-flex justify-content-center p-2 rounded m-2 border boxshadow">
                    <div className="text-center px-5">
                      <p className="fw-bold font24">ACHIEVED</p>
                      <p className="fw-bold text-success font24">
                        {overallTarget?.totalAchievedRevenue?.toFixed(2)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mx-0">
              <div className="mt-2 ">
                <h6 className="ms-2 fw-bold text-primary">OUTBOUND</h6>
                <div className="ms-2 px-0">
                  <table className="w-100">
                    <thead>
                      <tr className="border-dark border text-dark">
                        <th
                          className="border-dark border"
                          rowSpan={2}
                          style={{ width: "3rem" }}
                        >
                          S.R.
                        </th>
                        <th
                          className="border-dark border"
                          rowSpan={2}
                          style={{ width: "10rem" }}
                        >
                          Manager
                        </th>

                        <th
                          className="border-dark border"
                          style={{ width: "5rem" }}
                        >
                          ACHIEVED
                        </th>
                      </tr>
                      <tr className="text-dark">
                        <th className="border-dark border bg-white text-dark">
                          {outboundList?.totalOutbound?.totalAchievedRevenue?.toFixed(
                            2
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="border-dark border text-dark">
                      {outboundList?.Outbound?.map(
                        (item: any, index: number) => {
                          return (
                            <>
                              <tr
                                onClick={() => {
                                  onClickLead(item);
                                }}
                              >
                                <td className="border-dark border bg-white text-dark">
                                  {index + 1}
                                </td>
                                <td className="border-dark border bg-white text-dark">
                                  {item?.name}
                                </td>
                                <td className="border-dark border bg-white text-dark">
                                  {item?.achievedRevenue?.toFixed(2)}
                                </td>
                              </tr>
                            </>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  };

  const renderManagerwise = () => {
    return (
      <div className="mt-2">
        {selected === "allocated" ? (
          <>
            <table className="w-100">
              <thead>
                <tr className="border-dark border text-dark">
                  <th
                    className="border-dark border"
                    rowSpan={2}
                    style={{ width: "3rem" }}
                  >
                    S.R.
                  </th>
                  <th
                    className="border-dark border"
                    rowSpan={2}
                    style={{ width: "10rem" }}
                  >
                    COUNSELLOR
                  </th>
                  <th className="border-dark border" style={{ width: "5rem" }}>
                    TARGET
                  </th>
                  <th className="border-dark border" style={{ width: "5rem" }}>
                    ACHIEVED
                  </th>
                  <th className="border-dark border" style={{ width: "10rem" }}>
                    PERCENTAGE ACHIEVED
                  </th>
                  <th className="border-dark border" style={{ width: "5rem" }}>
                    DEFICIT
                  </th>
                  <th className="border-dark border" style={{ width: "10rem" }}>
                    REFERENCE GENERATED
                  </th>
                </tr>
                <tr className="text-dark">
                  <th className="border-dark border bg-white text-dark">
                    {counsellorList?.total?.totalTarget}
                  </th>
                  <th className="border-dark border bg-white text-dark">
                    {counsellorList?.total?.totalAchievedRevenue?.toFixed(2)}
                  </th>
                  <th className="border-dark border bg-white text-dark">
                    {counsellorList?.total?.totalAchievedRevenue
                      ? (
                          (counsellorList?.total?.totalAchievedRevenue /
                            counsellorList?.total?.totalTarget) *
                          100
                        )?.toFixed(2)
                      : 0}
                    %
                  </th>
                  <th className="border-dark border bg-white text-danger">
                    {counsellorList?.total?.totalTarget
                      ? (
                          counsellorList?.total?.totalTarget -
                          counsellorList?.total?.totalAchievedRevenue
                        )?.toFixed(2)
                      : 0}
                  </th>
                  <th className="border-dark border bg-white text-dark">
                    {`${
                      counsellorList?.total?.totalAchievedReference
                        ? counsellorList?.total?.totalAchievedReference
                        : 0
                    } / ${
                      counsellorList?.total?.totalTargetReference
                        ? counsellorList?.total?.totalTargetReference
                        : 0
                    }`}
                  </th>
                </tr>
              </thead>
              <tbody className="border-dark border text-dark">
                {counsellorList?.data?.map((item: any, index: number) => {
                  return (
                    <>
                      <tr
                        onClick={() => {
                          onClickCounsellor(item);
                        }}
                        className="cursor"
                      >
                        <td className="border-dark border bg-white text-dark">
                          {index + 1}
                        </td>
                        <td className="border-dark border bg-white text-dark">
                          {item?.counsellorName}
                        </td>
                        <td className="border-dark border bg-white text-dark">
                          {item?.targetRevenue?.toFixed(2)}
                        </td>
                        <td className="border-dark border bg-white text-dark">
                          {item?.achievedRevenue?.toFixed(2)}
                        </td>
                        <td className="border-dark border bg-white text-dark">
                          {item?.percent ? item?.percent : 0}%
                        </td>
                        <td className="border-dark border bg-white text-danger">
                          {item?.deficit?.toFixed(2)}
                        </td>
                        <td className="border-dark border bg-white text-dark">
                          {`${item?.achievedLeadCount} / ${item?.referenceleadTargetCount}`}
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </>
        ) : null}
        {selected === "other" ? (
          <>
            <table className="w-100">
              <thead>
                <tr className="border-dark border text-dark">
                  <th
                    className="border-dark border"
                    rowSpan={2}
                    style={{ width: "3rem" }}
                  >
                    S.R.
                  </th>
                  <th
                    className="border-dark border"
                    rowSpan={2}
                    style={{ width: "10rem" }}
                  >
                    COUNSELLOR
                  </th>

                  <th className="border-dark border" style={{ width: "5rem" }}>
                    ACHIEVED
                  </th>
                </tr>
                <tr className="text-dark">
                  <th className="border-dark border bg-white text-dark">
                    {counsellorList?.total?.totalAchievedRevenue?.toFixed(2)}
                  </th>
                </tr>
              </thead>
              <tbody className="border-dark border text-dark">
                {counsellorList?.data?.map((item: any, index: number) => {
                  return (
                    <>
                      <tr
                        onClick={() => {
                          onClickCounsellor(item);
                        }}
                        className="cursor"
                      >
                        <td className="border-dark border bg-white text-dark">
                          {index + 1}
                        </td>
                        <td className="border-dark border bg-white text-dark">
                          {item?.name}
                        </td>
                        <td className="border-dark border bg-white text-dark">
                          {item?.achievedRevenue?.toFixed(2)}
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </>
        ) : null}
        {selected === "publication" ? (
          <>
            <table className="w-100">
              <thead>
                <tr className="border-dark border text-dark">
                  <th
                    className="border-dark border"
                    rowSpan={2}
                    style={{ width: "3rem" }}
                  >
                    S.R.
                  </th>
                  <th
                    className="border-dark border"
                    rowSpan={2}
                    style={{ width: "10rem" }}
                  >
                    COUNSELLOR
                  </th>

                  <th className="border-dark border" style={{ width: "5rem" }}>
                    ACHIEVED
                  </th>
                </tr>
                <tr className="text-dark">
                  <th className="border-dark border bg-white text-dark">
                    {counsellorList?.total?.totalAchievedRevenue?.toFixed(2)}
                  </th>
                </tr>
              </thead>
              <tbody className="border-dark border text-dark">
                {counsellorList?.data?.map((item: any, index: number) => {
                  return (
                    <>
                      <tr
                        onClick={() => {
                          onClickCounsellor(item);
                        }}
                        className="cursor"
                      >
                        <td className="border-dark border bg-white text-dark">
                          {index + 1}
                        </td>
                        <td className="border-dark border bg-white text-dark">
                          {item?.name}
                        </td>
                        <td className="border-dark border bg-white text-dark">
                          {item?.achievedRevenue?.toFixed(2)}
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </>
        ) : null}
        <div className="m-lg-2">
          <div className="align-items-center">
            {counsellorName ? (
              <h5 className="fw-bold">Counsellor: {counsellorName}</h5>
            ) : null}
            <h5 className="fw-bold">
              Grand Total: {"\u20B9"}
              {totalTransactionAmount?.toFixed(2)}
            </h5>
          </div>
        </div>
        {sectionArr?.phdData[0]?.examTypes?.length > 0 ? renderPhdList() : null}
        {sectionArr?.mscData[0]?.examTypes?.length > 0 ? renderMscList() : null}
        {sectionArr?.ugcData[0]?.examTypes?.length > 0 ? renderUgcList() : null}
      </div>
    );
  };
  const renderPhdList = () => {
    return (
      <div className="performanceTable table-responsive">
        <h6 className="fw-bold m-lg-2">PHD Entrance</h6>
        <div>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th className="text-center align-middle">EXAM NAME</th>
                <th className="text-center align-middle">SUBJECT</th>
                <th className="text-center align-middle">ACHIEVED REVENUE</th>
                <th className="text-center align-middle">
                  EXAM WISE CONTRIBUTION
                </th>
                <th className="text-center align-middle ">
                  OVERALL CONTRIBUTION
                </th>
              </tr>
            </thead>
            <tbody>
              {sectionArr?.phdData?.map((item: any, index: number) => {
                return (
                  <React.Fragment key={index}>
                    {item?.examTypes?.map((itm: any, idx: number) => {
                      return (
                        <>
                          <tr>
                            <td
                              className="border fw-bold align-middle text-center"
                              rowSpan={itm?.baseCourses?.length + 1}
                            >
                              {itm?.examTypeName}
                            </td>
                          </tr>
                          {itm?.baseCourses?.map((i: any, j: any) => {
                            return (
                              <tr key={j} className="text-center">
                                <td className="ps-5">{i?.baseCourseName}</td>
                                <td className="">
                                  {i?.achievedRevenue?.toFixed(2) ??
                                    i?.achievedRevenue?.toFixed(2)}
                                </td>
                                <td className="ps-5">
                                  {i?.baseCourseContribution ??
                                    i?.baseCourseContribution}
                                </td>
                                <td className="ps-5">
                                  {i?.overallContribution ??
                                    i?.overallContribution}
                                </td>
                              </tr>
                            );
                          })}
                          <tr className="ps-5 fw-bold text-center">
                            <td colSpan={2}>Total</td>
                            <td>{itm?.examTypeTotalRevenue?.toFixed(2)}</td>
                            <td>Section Wise:- {itm?.examContribution}</td>
                            <td>Overall Wise:- {itm?.overallContribution}</td>
                          </tr>
                        </>
                      );
                    })}
                    <tr className="ps-5 fw-bold text-center">
                      <td colSpan={2}>Grand Total</td>
                      <td>{item?.sectionTotal?.toFixed(2)}</td>
                      <td colSpan={2}>{item?.sectionContribution}</td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const renderMscList = () => {
    return (
      <div className="performanceTable table-responsive">
        <h6 className="fw-bold m-lg-2">MSC</h6>
        <div>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th className="text-center align-middle ">EXAM NAME</th>
                <th className="text-center align-middle ">SUBJECT</th>
                <th className="text-center align-middle ">ACHIEVED REVENUE</th>
                <th className="text-center align-middle ">
                  EXAM WISE CONTRIBUTION
                </th>
                <th className="text-center align-middle ">
                  OVERALL CONTRIBUTION
                </th>
              </tr>
            </thead>
            <tbody>
              {sectionArr?.mscData?.map((item: any, index: number) => {
                return (
                  <React.Fragment key={index}>
                    {item?.examTypes?.map((itm: any, idx: number) => {
                      return (
                        <>
                          <tr>
                            <td
                              className="border fw-bold align-middle text-center"
                              rowSpan={itm?.baseCourses?.length + 1}
                            >
                              {itm?.examTypeName}
                            </td>
                          </tr>
                          {itm?.baseCourses?.map((i: any, j: any) => {
                            return (
                              <tr key={j} className="text-center">
                                <td className="ps-5">{i?.baseCourseName}</td>
                                <td className="">
                                  {i?.achievedRevenue?.toFixed(2) ??
                                    i?.achievedRevenue?.toFixed(2)}
                                </td>
                                <td className="ps-5">
                                  {i?.baseCourseContribution ??
                                    i?.baseCourseContribution}
                                </td>
                                <td className="ps-5">
                                  {i?.overallContribution ??
                                    i?.overallContribution}
                                </td>
                              </tr>
                            );
                          })}
                          <tr className="ps-5 fw-bold text-center">
                            <td colSpan={2}>Total</td>
                            <td>{itm?.examTypeTotalRevenue?.toFixed(2)}</td>
                            <td>Section Wise:- {itm?.examContribution}</td>
                            <td>Overall Wise:- {itm?.overallContribution}</td>
                          </tr>
                        </>
                      );
                    })}
                    <tr className="ps-5 fw-bold text-center">
                      <td colSpan={2}>Grand Total</td>
                      <td>{item?.sectionTotal?.toFixed(2)}</td>
                      <td colSpan={2}>{item?.sectionContribution}</td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const renderUgcList = () => {
    return (
      <div className="performanceTable table-responsive">
        <h6 className="fw-bold m-lg-2">UGC NET</h6>
        <div>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th className="text-center align-middle ">EXAM NAME</th>
                <th className="text-center align-middle ">SUBJECT</th>
                <th className="text-center align-middle ">ACHIEVED REVENUE</th>
                <th className="text-center align-middle ">
                  EXAM WISE CONTRIBUTION
                </th>
                <th className="text-center align-middle ">
                  OVERALL CONTRIBUTION
                </th>
              </tr>
            </thead>
            <tbody>
              {sectionArr?.ugcData?.map((item: any, index: number) => {
                return (
                  <React.Fragment key={index}>
                    {item?.examTypes?.map((itm: any, idx: number) => {
                      return (
                        <>
                          <tr>
                            <td
                              className="border fw-bold align-middle text-center"
                              rowSpan={itm?.baseCourses?.length + 1}
                            >
                              {itm?.examTypeName}
                            </td>
                          </tr>
                          {itm?.baseCourses?.map((i: any, j: any) => {
                            return (
                              <tr key={j} className="text-center">
                                <td className="ps-5">{i?.baseCourseName}</td>
                                <td className="">
                                  {i?.achievedRevenue?.toFixed(2) ??
                                    i?.achievedRevenue?.toFixed(2)}
                                </td>
                                <td className="ps-5">
                                  {i?.baseCourseContribution ??
                                    i?.baseCourseContribution}
                                </td>
                                <td className="ps-5">
                                  {i?.overallContribution ??
                                    i?.overallContribution}
                                </td>
                              </tr>
                            );
                          })}
                          <tr className="ps-5 fw-bold text-center">
                            <td colSpan={2}>Total</td>
                            <td>{itm?.examTypeTotalRevenue?.toFixed(2)}</td>
                            <td>Section Wise:- {itm?.examContribution}</td>
                            <td>Overall Wise:- {itm?.overallContribution}</td>
                          </tr>
                        </>
                      );
                    })}
                    <tr className="ps-5 fw-bold text-center">
                      <td colSpan={2}>Grand Total</td>
                      <td>{item?.sectionTotal?.toFixed(2)}</td>
                      <td colSpan={2}>{item?.sectionContribution}</td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div className="contentBox">
      <div className="row">
        <div className="formWidth">
          <div className="p-3">
            <div className="d-flex justify-content-between">
              <div>
                <div className="d-flex">
                  {isViewMode ? (
                    <div>
                      <button
                        type="button"
                        className="btn btn-light me-2"
                        onClick={() => handleBack()}
                      >
                        <ReplyIcon /> Back
                      </button>
                      <button
                        type="button"
                        className="btn btn-light me-2"
                        onClick={() => refreshExamRevenue(teamLeadName?._id)}
                      >
                        <SyncIcon />
                      </button>
                    </div>
                  ) : null}
                  <h3 className="fw-bold text-primary">
                    {isViewMode ? (
                      teamLeadName?.teamLeadName
                    ) : (
                      <>
                        Hi,{loggedInUserName}
                        <h5 className="fw-bold text-primary">
                          Today's Dashboard
                        </h5>
                      </>
                    )}
                  </h3>
                </div>
              </div>
              <div>
                <div className="card">
                  <div className="d-flex justify-content-between p-2">
                    <div className="col-md-4 me-4">
                      <LabelField lableName="Start Date" />
                      <div className="d-flex justify-content-start rounded fw-bold align-items-center">
                        <DatePicker
                          value={startDate}
                          setInputText={(value: string) => setStartDate(value)}
                          maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                          disabled={isViewMode}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 ms-1">
                      <LabelField lableName="End Date" />
                      <div className="d-flex justify-content-start rounded fw-bold align-items-center">
                        <DatePicker
                          value={endDate}
                          setInputText={(value: string) => setEndDate(value)}
                          maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                          disabled={isViewMode}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 d-flex justify-content-center align-items-center">
                      <button
                        className="btn btn-sm btn-primary rounded-pill"
                        onClick={() => {
                          onChangeSelected(selected);
                        }}
                        disabled={isViewMode}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!isViewMode ? renderListMode() : renderManagerwise()}
          </div>
        </div>
      </div>
    </div>
  );
}
