import services from "../../API/service";
import { showErrorSnackbar, showSuccessSnackbar } from "./snackbarAction";
import { updateCommonValues } from "./CommonAction";
import endpoint from "../../API/endpoint";

export const CrmLogin =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.auth.login,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          localStorage.setItem("token", res.data.token.refresh.token);

          dispatch(
            updateCommonValues("authToken", res.data.token.access.token)
          );
          dispatch(
            updateCommonValues("counsellorId", res?.data?.employee?._id)
          );
          dispatch(
            updateCommonValues("managerId", res?.data?.employee?.managerId)
          );
          dispatch(
            updateCommonValues("loggedInUserId", res?.data?.employee?._id)
          );
          dispatch(
            updateCommonValues(
              "loggedInUserManagerId",
              res?.data?.employee?.managerId
            )
          );
          dispatch(
            updateCommonValues("loggedInUserName", res?.data?.user?.name)
          );
          dispatch(
            updateCommonValues("loggedInUserRole", res?.data?.user?.role)
          );
          dispatch(
            updateCommonValues(
              "ozonetelApiKey",
              "KKf22f8f998e6b7eb3d050d5bc1068bfb6"
            )
          );
          dispatch(
            updateCommonValues("ozonetelAccountId", res?.data?.accountId)
          );
          dispatch(
            updateCommonValues("ozonetelAgentId", res?.data?.user?.agentId)
          );
          dispatch(updateCommonValues("ozonetelPin", res?.data?.user?.pin));
          dispatch(
            updateCommonValues("ozonetelAgentSIP", res?.data?.user?.agentSIP)
          );
          dispatch(
            updateCommonValues("ozonetelContactNo", res?.data?.user?.contactNo)
          );

          // const token = localStorage.getItem('token');
          services.setAuthorizationToken(res.data.token.access.token);

          dispatch(showSuccessSnackbar("Success!"));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const CrmLogout =
  (callback: Function, errorCallback: Function): any =>
  async (dispatch: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.auth.logout,
      {},
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res.statusCode === services.status_code.success) {
          localStorage.setItem("token", "");
          dispatch(updateCommonValues("authToken", ""));
          dispatch(updateCommonValues("counsellorId", ""));
          dispatch(updateCommonValues("loggedInUserName", ""));
          dispatch(updateCommonValues("loggedInUserRole", ""));
          dispatch(updateCommonValues("selectedLeadId", ""));
          dispatch(updateCommonValues("startDate", ""));
          dispatch(updateCommonValues("endDate", ""));
          dispatch(updateCommonValues("selectedManagerId", ""));
          dispatch(updateCommonValues("selectedCounsellorId", ""));
          dispatch(updateCommonValues("selectedExamTypeId", ""));
          dispatch(updateCommonValues("selectedBaseCourseId", ""));
          dispatch(updateCommonValues("selectedMainCategoryId", ""));
          dispatch(updateCommonValues("selectedSubCategoryId", ""));
          dispatch(updateCommonValues("selectedStatus", ""));
          dispatch(updateCommonValues("ozonetelURL", ""));
          dispatch(updateCommonValues("ozonetelApiKey", ""));
          dispatch(updateCommonValues("ozonetelAccountId", ""));
          dispatch(updateCommonValues("ozonetelAgentId", ""));
          dispatch(updateCommonValues("ozonetelPin", ""));
          dispatch(updateCommonValues("ozonetelAgentSIP", ""));
          dispatch(updateCommonValues("ozonetelContactNo", ""));
          services.setAuthorizationToken("");
          callback(res);
        } else if (res.statusCode !== services.status_code.success) {
          errorCallback(res);

          dispatch(showErrorSnackbar(res.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const ForgotPassword =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.auth.ForgotPassword,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const UpdatePassword =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.auth.UpdatePassword,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const BDECrmLogin =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.auth.BDElogin,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          localStorage.setItem("token", res.data.token.refresh.token);

          dispatch(
            updateCommonValues("authToken", res?.data?.token?.access?.token)
          );
          dispatch(updateCommonValues("executiveID", res?.data?.user?._id));
          dispatch(
            updateCommonValues("loggedInUserName", res?.data?.user?.name)
          );
          dispatch(
            updateCommonValues("loggedInUserRole", res?.data?.user?.role)
          );

          // const token = localStorage.getItem('token');
          services.setAuthorizationToken(res?.data?.token?.access?.token);

          dispatch(showSuccessSnackbar("Success!"));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const HrLogin =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.auth.HrLogin,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          localStorage.setItem("token", res.data.token.refresh.token);

          dispatch(
            updateCommonValues("authToken", res.data.token.access.token)
          );
          dispatch(
            updateCommonValues("counsellorId", res?.data?.employee?._id)
          );
          dispatch(
            updateCommonValues("managerId", res?.data?.employee?.managerId)
          );
          dispatch(
            updateCommonValues("loggedInUserId", res?.data?.employee?._id)
          );
          dispatch(
            updateCommonValues(
              "loggedInUserManagerId",
              res?.data?.employee?.managerId
            )
          );
          dispatch(
            updateCommonValues("loggedInUserName", res?.data?.user?.name)
          );
          dispatch(
            updateCommonValues("loggedInUserRole", res?.data?.user?.role)
          );
          dispatch(
            updateCommonValues(
              "ozonetelApiKey",
              "KKf22f8f998e6b7eb3d050d5bc1068bfb6"
            )
          );
          dispatch(
            updateCommonValues("ozonetelAccountId", res?.data?.accountId)
          );
          dispatch(
            updateCommonValues("ozonetelAgentId", res?.data?.user?.agentId)
          );
          dispatch(updateCommonValues("ozonetelPin", res?.data?.user?.pin));
          dispatch(
            updateCommonValues("ozonetelAgentSIP", res?.data?.user?.agentSIP)
          );
          dispatch(
            updateCommonValues("ozonetelContactNo", res?.data?.user?.contactNo)
          );

          // const token = localStorage.getItem('token');
          services.setAuthorizationToken(res.data.token.access.token);

          dispatch(showSuccessSnackbar("Success!"));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };
