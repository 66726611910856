/* eslint-disable react/prop-types */
import React from "react";

const SquareCheckBox = (props: any) => {
  const { id, value, name, checked, onChange, labelName, disabled } = props;
  return (
    <>
      <input
        id={id}
        type="checkbox"
        value={value}
        name={name}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <span className="font-14 ms-2">{labelName}</span>
    </>
  );
};
export default SquareCheckBox;
