import "../../App.css";
import { Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Sidebar from "../../Module/sidebar";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import Loading from "../../Component/loading";
import {
  CrmCounsellorPanelSidebarData,
  CrmServiceCounsellorPanelSidebarData,
  CrmUpsellingCounsellorPanelSidebarData,
} from "../../DATA/crmCounsellorPanelSidebarData";
import {
  getUpcomingCall,
  makeCalltoLead,
  getChatBotLeadDetails,
} from "../../Redux/Actions/counsellorPanelAction";
import CallPopUP from "../../Component/callPopUP";

import CounsellorDashbord from "./counsellorDashbord";
import CounsellorLeadOverview from "./counsellorLeadOverview";
import LeadAssignedDateAnalysis from "./leadAssignedDateAnalysis";
import LeadAssignedStatusOverview from "./leadAssignedStatusOverview";
import NewOpportunity from "./newOpportunity";
import ReEnquired from "./reEnquired";
import EnrolledLead from "./enrolledLead";
import NotIntrestedLead from "./notIntrestedLead";
import Expired from "./expired";
import CNRLead from "./cnrLead";
import ProspectLead from "./prospectLead";
import Interested from "./interested";
import CallBackLead from "./callBackLead";
import CallRejectToday from "./callRejectToday";
import OverdueProspect from "./overdueProspect";
import OverdueInterested from "./overdueInterested";
import OverdueCallback from "./overdueCallback";
import OverdueCNR from "./overdueCNR";
import OverdueCallReject from "./overdueCallReject";
import LeadInvalid from "./leadInvalid";
import AlreadyAdmissionDone from "./alreadyAdmissionDone";
import WelcomeCallDone from "./welcomeCallDone";
import WelcomeCallReference from "./welcomeCallReference";
import FollowUp from "./followUp";
import FinalCNR from "./finalCNR";
import Summary from "./summary";
import TargetStatus from "./targetStatus";
import IncentiveTargetStatus from "./incentiveTargetStatus";
import LeadAssignedDate from "./leadAssignedDate";
import AdmissionDate from "./admissionDate";
import ExamRevenue from "./examRevenue";
import CourseSubscriptionMonths from "./courseSubscriptionMonths";
import CallList from "./callList";
import CouponCode from "./couponCode";
import LeadSearchByMobileNumber from "./leadSearchByMobileNumber";
import ReferNewLead from "./referNewLead";
import ReferenceLeadsList from "./referenceLeadsList";
import IncomingCall from "./incomingCall";
import CounsellorRemark from "./counsellorRemark";
import LeadCompleteDetails from "./leadCompleteDetails";
import CompleteLeadDetail from "../CRMTeamLeadPanel/completeLeadDetail";
import { updateCommonValues } from "../../Redux/Actions/CommonAction";
import { useAppDispatch } from "../../hooks";
import ResultCompilation from "./resultCompilation";
import StudentResult from "./studentResult";
import UserActivityTracker from "./userActivityTracker";
import UserActivityDetails from "./userActivityDetails";
import RemarkAnalysis from "./remarkAnalysis";
import InterestedForUpselling from "./interestedForUpselling";
import ProspectForUpselling from "./prospectForUpselling";
import TechnicalIssue from "./technicalIssue";
import StudentAnalysis from "./StudentAnalysis/studentAnalysis";
import CourseSupport from "./courseSupport";

function CounsellorPanelDashbord(Props: any) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentURLarr = useLocation().pathname.split("/");
  let pageName = currentURLarr[currentURLarr.length - 1];

  const { loggedInUserId, loggedInUserManagerId, loggedInUserRole } =
    useSelector((state: any) => ({
      loggedInUserId: state.Auth.loggedInUserId,
      loggedInUserManagerId: state.Auth.loggedInUserManagerId,
      loggedInUserRole: state.Auth.loggedInUserRole,
    }));

  const [upcomingCallPopUp, setUpcomingCallPopUp] = useState<boolean>(false);
  const [upcomingCall, setUpcomingCall] = useState<any>([]);
  const [newIssueCount, setNewIssueCount] = useState<number>(0);

  useEffect(() => {
    let interval = setInterval(async () => {
      getUpcomingCallList();
    }, 120000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  const handleIssueCount = (count: number) => {
    setNewIssueCount(count);
  };
  const getUpcomingCallList = () => {
    dispatch(
      getUpcomingCall(
        loggedInUserId,
        (res: any) => {
          if (res?.data?.length > 0) {
            setUpcomingCall(res?.data);
            setUpcomingCallPopUp(true);
          }
        },
        () => {}
      )
    );
  };

  const onHandleClose = () => {
    setUpcomingCallPopUp(false);
  };

  const onHandleCall = (data: any) => {
    setUpcomingCallPopUp(false);
    dispatch(
      makeCalltoLead(
        {
          crmEmployeeId: loggedInUserId,
          leadMobileNo: data?.target?.value,
        },
        (res: any) => {
          dispatch(updateCommonValues("selectedLeadId", data?.target?.id));
          navigate("/leadDetail", {
            state: {
              pageName,
              success: res?.success,
            },
          });
        },
        () => {}
      )
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(
        getChatBotLeadDetails(
          loggedInUserId,
          (res: any) => {
            if (res) {
              const newIssues: any = [];
              res?.data?.forEach((statusWise: any) => {
                if (statusWise?.status === 0) {
                  newIssues.push(statusWise);
                }
              });
              setNewIssueCount(newIssues);
            }
          },
          () => {}
        )
      );
    }, 3000);
    return () => clearInterval(interval);
  }, [loggedInUserId]);

  return (
    <div className="AppBox">
      <Sidebar
        data={
          loggedInUserRole === 8
            ? CrmServiceCounsellorPanelSidebarData
            : loggedInUserRole === 4
            ? CrmUpsellingCounsellorPanelSidebarData
            : CrmCounsellorPanelSidebarData
        }
        pagename
        newCount={newIssueCount}
      />
      <div className="dashboard">
        {
          <Suspense
            fallback={
              <div className="border p-1">
                <Loading />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<CounsellorDashbord newIssueCount={newIssueCount}/>}  />
              <Route
                path="counsellorDashbord"
                element={<CounsellorDashbord newIssueCount={newIssueCount}/>}
              />
              <Route
                path="counsellorLeadOverview"
                element={<CounsellorLeadOverview />}
              />
              <Route
                path="leadAssignedDateAnalysis"
                element={<LeadAssignedDateAnalysis />}
              />
              <Route
                path="leadAssignedStatusOverview"
                element={<LeadAssignedStatusOverview />}
              />
              <Route path="newOpportunity" element={<NewOpportunity />} />
              <Route path="reEnquired" element={<ReEnquired />} />
              <Route path="enrolledLead" element={<EnrolledLead />} />
              <Route path="notIntrestedLead" element={<NotIntrestedLead />} />
              <Route path="expired" element={<Expired />} />
              <Route path="cnrLead" element={<CNRLead />} />
              <Route path="prospectLead" element={<ProspectLead />} />
              <Route path="interested" element={<Interested />} />
              <Route path="callBackLead" element={<CallBackLead />} />
              <Route path="callRejectToday" element={<CallRejectToday />} />
              <Route path="overdueProspect" element={<OverdueProspect />} />
              <Route path="overdueInterested" element={<OverdueInterested />} />
              <Route path="overdueCallback" element={<OverdueCallback />} />
              <Route path="overdueCNR" element={<OverdueCNR />} />
              <Route path="overdueCallReject" element={<OverdueCallReject />} />
              <Route path="leadInvalid" element={<LeadInvalid />} />
              <Route
                path="alreadyAdmissionDone"
                element={<AlreadyAdmissionDone />}
              />
              <Route path="welcomeCallDone" element={<WelcomeCallDone />} />
              <Route
                path="welcomeCallReference"
                element={<WelcomeCallReference />}
              />
              <Route path="FollowUp" element={<FollowUp />} />
              <Route path="finalCNR" element={<FinalCNR />} />
              <Route path="summary" element={<Summary />} />
              <Route path="targetStatus" element={<TargetStatus />} />
              <Route
                path="incentiveTargetStatus"
                element={<IncentiveTargetStatus />}
              />
              <Route path="leadAssignedDate" element={<LeadAssignedDate />} />
              <Route path="admissionDate" element={<AdmissionDate />} />
              <Route path="examRevenue" element={<ExamRevenue />} />
              <Route
                path="CourseSubscriptionMonths"
                element={<CourseSubscriptionMonths />}
              />
              <Route path="callList" element={<CallList />} />
              <Route path="couponCode" element={<CouponCode />} />
              <Route
                path="leadSearchByMobileNumber"
                element={<LeadSearchByMobileNumber />}
              />
              <Route path="referNewLead" element={<ReferNewLead />} />
              <Route
                path="referenceLeadsList"
                element={<ReferenceLeadsList />}
              />
              <Route path="incomingCall" element={<IncomingCall />} />
              <Route path="counsellorRemark" element={<CounsellorRemark />} />
              <Route
                path="leadCompleteDetails"
                element={<LeadCompleteDetails />}
              />
              <Route
                path="completeLeadDetail"
                element={<CompleteLeadDetail />}
              />
              <Route path="resultCompilation" element={<ResultCompilation />} />
              <Route path="studentResult" element={<StudentResult />} />
              <Route
                path="userActivityTracker"
                element={<UserActivityTracker />}
              />
              <Route
                path="userActivityDetails"
                element={<UserActivityDetails />}
              />
              <Route path="RemarkAnalysis" element={<RemarkAnalysis />} />
              <Route
                path="interestedForUpselling"
                element={<InterestedForUpselling />}
              />
              <Route
                path="prospectForUpselling"
                element={<ProspectForUpselling />}
              />
              <Route path="technicalIssue" element={<TechnicalIssue />} />
              <Route path="studentAnalysis" element={<StudentAnalysis />} />
              <Route
                path="coursesupport"
                element={<CourseSupport onSendIssueCount={handleIssueCount} />}
              />
            </Routes>
          </Suspense>
        }
      </div>
      {upcomingCallPopUp ? (
        <CallPopUP
          handleClose={() => {
            onHandleClose();
          }}
          handleCall={(value: any) => {
            onHandleCall(value);
          }}
          title={"Upcoming Call"}
          data={upcomingCall}
        />
      ) : null}
    </div>
  );
}

export default CounsellorPanelDashbord;
