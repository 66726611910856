import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import InputField from "../../Component/inputField";
import LabelField from "../../Component/labelField";
import CallIcon from "@mui/icons-material/Call";
import CustomDropdown from "../../Component/customDropdown";
import LeadDetailHeader from "../../Component/LeadDetailheader";
import MessagePopUp from "../../Component/messagePopUp";
import EmailPopUp from "../../Component/emailPopUp";
import {
  getCallHistory,
  getCountryList,
  getLeadPaymentHistory,
  getStatesList,
  getStudentDetailsById,
  getStudentLeadScore,
  makeCalltoLead,
  updateLeadDetails,
} from "../../Redux/Actions/counsellorPanelAction";
import {
  getHrEmployeeCompanyList,
  getHrEmployeeDepartmentList,
  getHrEmployeeDesignationList,
  getHrStatusList,
  getHrSubDepartment,
  getHrSubStatusList,
  uploadPDF,
} from "../../Redux/Actions/hrPanelAction";
import DateTime from "../../Component/dateTime";
import SubmitButton from "../../Component/submitButton";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../Redux/Actions/snackbarAction";
import {
  containsNumber,
  length_Ten,
  pdf_size_1MB,
  validateIsFilePDFType,
  validateValueWithoutSpace,
} from "../../DATA/validators";
import {
  validateIsFilePDFTypeErrMsg,
  validateIsNumberOnlyErrMsg,
} from "../../DATA/errorMsg";
import { getLeadByMobileNumber } from "../../Redux/Actions/managerPanelAction";
import {
  qualificationList,
  qualificationYearList,
} from "../../DATA/dataConstant";
import { updateCommonValues } from "../../Redux/Actions/CommonAction";
import { useAppDispatch } from "../../hooks";
import ImagePickerAndViewer from "../../Component/imagePickerAndViewer";

const initialVal: {
  baseCourseId: string;
  examTypeId: string;
  subExamTypeId: string;
  maincategoryId: string;
  subcategoryId: string;
}[] = [
  {
    baseCourseId: "",
    examTypeId: "",
    subExamTypeId: "",
    maincategoryId: "",
    subcategoryId: "",
  },
];

export default function LeadDetail() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { loggedInUserId, loggedInUserRole, selectedLeadId, ozonetelAgentId } =
    useSelector((state: any) => ({
      loggedInUserId: state.Auth.loggedInUserId,
      loggedInUserRole: state.Auth.loggedInUserRole,
      selectedLeadId: state.Auth.selectedLeadId,
      ozonetelAgentId: state.Auth.ozonetelAgentId,
    }));

  const pageName = location.state?.pageName;
  const success = location.state?.success;
  const leadStatus = location.state?.status;
  const leadState = location.state?.state;
  const leadStartDate = location.state?.startDate;
  const leadEndDate = location.state?.endDate;
  const leadType = location.state?.type;
  const leadMobileNo = location.state?.leadMobileNo;

  const [leadScore, setLeadScore] = useState<any>("");
  const [rank, setRank] = useState<any>("");
  const [isSave, setIsSave] = useState(false);
  const [firstName, setFirstName] = useState<any>("");
  const [lastName, setLastName] = useState<any>("");
  const [contact1, setContact1] = useState<any>("");
  const [contact2, setContact2] = useState<any>("");
  const [email, setEmail] = useState<any>("");
  const [state, setState] = useState<any>("");
  const [city, setCity] = useState<any>("");
  const [address, setAddress] = useState<any>("");
  const [educationYear, setEducationYear] = useState<any>("");
  const [qualification, setQualification] = useState<any>("");
  const [currentCompany, setCurrentCompany] = useState<any>("");
  const [currentCTC, setCurrentCTC] = useState<any>("");
  const [experience, setExperience] = useState<any>("");
  const [selectedCV, setSelectedCV] = useState<any>("");
  const [pdfCV, setPdfCV] = useState<any>("");
  const [selectedAadharCard, setSelectedAadharCard] = useState<any>("");
  const [pdfAadharCard, setPdfAadharCard] = useState<any>("");
  const [selectedPanCard, setSelectedPanCard] = useState<any>("");
  const [pdfPanCard, setPdfPanCard] = useState<any>("");
  const [company, setCompany] = useState<any>("");
  const [department, setDepartment] = useState<any>("");
  const [subDepartment, setSubDepartment] = useState<any>("");
  const [designation, setDesignation] = useState<any>("");
  const [status, setStatus] = useState<any>("");
  const [subStatus, setSubStatus] = useState<any>("");
  const [remark, setRemark] = useState<any>("");
  const [callBackDate, setCallBackDate] = useState<any>("");
  const [firstInterviewDate, setFirstInterviewDate] = useState<any>("");
  const [secondInterviewDate, setSecondInterviewDate] = useState<any>("");
  const [hrRoundDate, setHrRoundDate] = useState<any>("");
  const [joiningDate, setJoiningDate] = useState<any>("");

  const [referredStudentContactNo, setReferredStudentContactNo] =
    useState<any>("");
  const [referredStudentFirstName, setreferredStudentFirstName] =
    useState<any>("");
  const [referredStudentLastName, setreferredStudentLastName] =
    useState<any>("");
  const [referredStudentLeadId, setreferredStudentLeadId] = useState<any>("");

  const [openPopup, setOpenPopup] = useState(false);
  const [openEmailPopup, setOpenEmailPopup] = useState(false);

  const [stateList, setStateList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [educationYearList, setEducationYearList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [subDepartmentList, setSubDepartmentList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [examList, setExamList] = useState([]);
  const [subExamList, setSubExamList] = useState([]);
  const [examStreamList, setExamStreamList] = useState([]);
  const [courseTypeList, setCourseTypeList] = useState([]);
  const [studyMaterialList, setStudyMaterialList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [statusDetailsList, setStatusDetailsList] = useState([]);
  const [courseExamTypeInfoArr, setCourseExamTypeInfoArr] =
    useState<any>(initialVal);
  const [qualifiedExam, setQualifiedExam] = useState<any>([]);

  const [history, setHistory] = useState<any>(1);
  const [resetStatus, setResetStatus] = useState(false);
  const [resetStatusDetails, setResetStatusDetails] = useState(false);
  const [callHistoryData, setCallHistoryData] = useState([]);
  const [paymentHistoryData, setPaymentHistoryData] = useState([]);
  const [enrolledData, setEnrolledData] = useState([]);
  const [makeCallsList, setMakeCallsList] = useState<any>([]);
  const [leadByMobileNumberList, setLeadByMobileNumberList] = useState<any>([]);

  useEffect(() => {
    if (success) {
      setMakeCallsList([...makeCallsList, success?.call_id]);
      dispatch(showSuccessSnackbar(success?.message) as any);
    }
    dispatch(
      getStudentDetailsById(
        selectedLeadId,
        (res: any) => {
          setFirstName(res?.data[0]?.firstName?.toUpperCase());
          setLastName(res?.data[0]?.lastName?.toUpperCase());
          setContact1(res?.data[0]?.mobileNo);
          setContact2(res?.data[0]?.altMobileNo);
          if (res?.data[0]?.altMobileNo?.length === 10) {
            setIsSave(true);
          }
          setEmail(res?.data[0]?.email);
          setState(res?.data[0]?.stateId);
          dispatch(
            getStatesList((res: any) => {
              if (res?.data?.length > 0) {
                setStateList(res?.data);
              } else {
                setStateList([]);
              }
            })
          );
          setCity(res?.data[0]?.cityId);
          setQualification(res?.data[0]?.qualification);
          let tempEducationYear: any = qualificationYearList?.filter?.(
            (item: any) => item?._id === parseInt(res?.data[0]?.educationYear)
          );
          setEducationYear(tempEducationYear[0]);
          dispatch(
            getHrEmployeeCompanyList(
              (res: any) => {
                setCompanyList(res);
                let tempCompanyId: any = res?.find?.(
                  (item: any) => item?._id === parseInt(res?.data[0]?.companyId)
                );
                setEducationYear(tempCompanyId);
              },
              () => {}
            )
          );
          dispatch(
            getHrEmployeeDepartmentList(
              (res: any) => {
                setDepartmentList(res);
                let tempCompanyId: any = res?.find?.(
                  (item: any) => item?._id === parseInt(res?.data[0]?.companyId)
                );
                setEducationYear(tempCompanyId);
              },
              () => {}
            )
          );
          dispatch(
            getHrEmployeeDesignationList(
              (res: any) => {
                setDesignationList(res);
                let tempCompanyId: any = res?.find?.(
                  (item: any) => item?._id === parseInt(res?.data[0]?.companyId)
                );
                setEducationYear(tempCompanyId);
              },
              () => {}
            )
          );
          setStatus(res?.data[0]?.leadStatusId?._id);
          setSubStatus(res?.data[0]?.leadSubstatusId?._id);
          dispatch(
            getHrStatusList(
              "",
              (res: any) => {
                setStatusList(res);
              },
              () => {}
            )
          );
          if (res?.data[0]?.leadStatusId?._id > 3) {
            dispatch(
              getHrSubStatusList(
                res?.data[0]?.leadStatusId?._id,
                (res: any) => {
                  setStatusDetailsList(res);
                },
                () => {}
              )
            );
          }
          setRemark(res?.data[0]?.comment);
          setFirstInterviewDate(res?.data[0]?.callBackDate);
          dispatch(
            getCallHistory(
              { studentLeadsId: selectedLeadId },
              (res: any) => {
                setCallHistoryData(res);
                let enrolledList = res?.filter(
                  (item: any) => item?.leadStatus?._id === 5
                );
                setEnrolledData(enrolledList);
              },
              () => {}
            )
          );
          setRank(res?.data[0]?.webinarRank);
          setReferredStudentContactNo(res?.data[0]?.referredStudentContactNo);
          setreferredStudentFirstName(res?.data[0]?.referredStudentFirstName);
          setreferredStudentLastName(res?.data[0]?.referredStudentLastName);
          setreferredStudentLeadId(res?.data[0]?.referredStudentLeadId);
        },
        () => {}
      )
    );
    dispatch(
      getStudentLeadScore(
        selectedLeadId,
        (res: any) => {
          setLeadScore(res?.data);
        },
        () => {}
      )
    );
  }, []);

  const onChangeState = (val: any) => {
    setState(val);
    dispatch(
      getCountryList(
        val._id,
        (res: any) => {
          setCountryList(res?.data);
        },
        () => {}
      )
    );
  };

  const onPressAdd = () => {
    setCourseExamTypeInfoArr([...courseExamTypeInfoArr, initialVal[0]]);
  };

  const handleArrClose = (index: number) => {
    const newArrr = [...courseExamTypeInfoArr];
    if (index > -1) {
      newArrr.splice(index, 1);
    }
    setCourseExamTypeInfoArr(newArrr);
  };

  const onChange = (index: number, value: any, key: string) => {
    let newArrr = JSON.parse(JSON.stringify(courseExamTypeInfoArr));
    if (key === "exam") {
      newArrr[index].examTypeId = value?._id;
      setCourseExamTypeInfoArr(newArrr);
      if (newArrr[index].subExamTypeId) {
      } else {
      }
    } else if (key === "subExam") {
      newArrr[index].subExamTypeId = value?._id;
      setCourseExamTypeInfoArr(newArrr);
    } else if (key === "examStream") {
      newArrr[index].baseCourseId = value?._id;
      setCourseExamTypeInfoArr(newArrr);
    } else if (key === "courseType") {
      newArrr[index].maincategoryId = value?._id;
    } else if (key === "studyMaterial") {
      newArrr[index].subcategoryId = value?._id;
      setCourseExamTypeInfoArr(newArrr);
    }
    if (key === "exam") {
      newArrr[index].examTypeId = value?._id;
      setCourseExamTypeInfoArr(newArrr);
    }
  };

  const onChangeQualification = (val: any) => {
    setEducationYear("");
    setQualification(val);
    let tempQualification: any = qualificationYearList?.filter?.(
      (item: any) => item?.qualificationId === val?._id
    );
    setEducationYearList(tempQualification);
  };

  const onChangePDFCV = (e: any) => {
    e.preventDefault();
    if (!validateIsFilePDFType(e)) {
      dispatch(showErrorSnackbar(validateIsFilePDFTypeErrMsg));
      return;
    }
    if (e.target.files[0].size > pdf_size_1MB) {
      dispatch(
        showErrorSnackbar("File size should be less than 10 MB!!!") as any
      );
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setPdfCV(reader.result);
        setSelectedCV(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const onChangePDFAadharCard = (e: any) => {
    e.preventDefault();
    if (!validateIsFilePDFType(e)) {
      dispatch(showErrorSnackbar(validateIsFilePDFTypeErrMsg));
      return;
    }
    if (e.target.files[0].size > pdf_size_1MB) {
      dispatch(
        showErrorSnackbar("File size should be less than 10 MB!!!") as any
      );
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setPdfAadharCard(reader.result);
        setSelectedAadharCard(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const onChangePDFPanCard = (e: any) => {
    e.preventDefault();
    if (!validateIsFilePDFType(e)) {
      dispatch(showErrorSnackbar(validateIsFilePDFTypeErrMsg));
      return;
    }
    if (e.target.files[0].size > pdf_size_1MB) {
      dispatch(
        showErrorSnackbar("File size should be less than 10 MB!!!") as any
      );
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setPdfPanCard(reader.result);
        setSelectedPanCard(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const onChangeDepartment = (val: any) => {
    setSubDepartment(val);
    dispatch(
      getHrSubDepartment(
        val?._id,
        (res: any) => {
          setSubDepartmentList(res);
        },
        () => {}
      )
    );
  };

  const onChangeStatus = (val: any) => {
    setSubStatus("");
    setStatus(val);
    setResetStatus(false);
    setStatusDetailsList([]);
    dispatch(
      getHrSubStatusList(
        val?._id,
        (res: any) => {
          setStatusDetailsList(res);
        },
        () => {}
      )
    );
  };

  const onChangeSubStatus = (val: any) => {
    setSubStatus(val);
    setResetStatusDetails(false);
  };
  const changeRemark = (val: any) => {
    setRemark(val);
  };
  const handleClose = (value: any) => {
    setOpenEmailPopup(false);
    setOpenPopup(false);
  };
  const handleOpenMessagePopup = () => {
    setOpenPopup(true);
  };
  const handleOpenEmailPopup = () => {
    setOpenEmailPopup(true);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (
      (status?._id === 4 ||
        status?._id === 7 ||
        status?._id === 9 ||
        status?._id === 10 ||
        status?._id === 11) &&
      (state === "" || state === undefined || state === null)
    ) {
      dispatch(showErrorSnackbar("Please Select State") as any);
    } else if (
      (status?._id === 4 ||
        status?._id === 7 ||
        status?._id === 9 ||
        status?._id === 10 ||
        status?._id === 11) &&
      (qualification === "" ||
        qualification === undefined ||
        qualification === null)
    ) {
      dispatch(showErrorSnackbar("Please Select Qualification") as any);
    } else {
      let postData: any = {
        firstName: firstName?.toUpperCase(),
        lastName: lastName?.toUpperCase(),
        mobileNo: contact1,
        altMobileNo: contact2,
        email: email,
        stateId: state?._id,
        stateName: state?.name,
        cityId: city?._id,
        cityName: city?.name,
        address: address,
        educationYear: educationYear?._id,
        qualification: qualification?._id,
        currentCTC: currentCTC,
        currentCompany: currentCompany,
        department: department,
        designation: designation,
        leadStatusId: status?._id,
        leadSubstatusId: subStatus?._id,
        comment: remark,
        callBackDate: callBackDate,
        firstInterviewDate: firstInterviewDate,
        secondInterviewDate: secondInterviewDate,
        hrRoundCallBackDate: hrRoundDate,
        joiningDate: joiningDate,
      };
      if (selectedCV) {
        dispatch(
          uploadPDF(
            selectedCV,
            1,
            (resPdfCV: any) => {
              if (resPdfCV) {
                postData["cv"] = resPdfCV?.file;
              }
            },
            () => {}
          )
        );
      } else if (selectedAadharCard) {
        dispatch(
          uploadPDF(
            selectedAadharCard,
            2,
            (resPddAadhar: any) => {
              if (resPddAadhar) {
                postData["aadharCard"] = resPddAadhar?.file;
              }
            },
            () => {}
          )
        );
      } else if (selectedPanCard) {
        dispatch(
          uploadPDF(
            selectedPanCard,
            3,
            (resPdfPan: any) => {
              if (resPdfPan) {
                postData["panCard"] = resPdfPan?.file;
              }
            },
            () => {}
          )
        );
      }

      // if (selectedCV && !selectedAadharCard && !selectedPanCard) {
      //   dispatch(
      //     updateLeadDetails(postData, (res: any) => {
      //       resetData();
      //     })
      //   );
      // } else if (selectedCV && selectedAadharCard && !selectedPanCard) {
      //   dispatch(
      //     updateLeadDetails(postData, (res: any) => {
      //       resetData();
      //     })
      //   );
      // } else if (selectedCV && selectedAadharCard && selectedPanCard) {
      //   dispatch(
      //     updateLeadDetails(postData, (res: any) => {
      //       resetData();
      //     })
      //   );
      // } else {
      //   dispatch(
      //     updateLeadDetails(postData, (res: any) => {
      //       resetData();
      //     })
      //   );
      // }

      dispatch(
        updateLeadDetails(
          postData,
          (res: any) => {
            setMakeCallsList([]);
            if (pageName === "newOpportunity") {
              navigate("../CrmCounsellorPanel/newOpportunity", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "reEnquired") {
              navigate("../CrmCounsellorPanel/reEnquired", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "enrolledLead") {
              navigate("../CrmCounsellorPanel/enrolledLead", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "notIntrestedLead") {
              navigate("../CrmCounsellorPanel/notIntrestedLead", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "expired") {
              navigate("../CrmCounsellorPanel/expired", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "cnrLead") {
              navigate("../CrmCounsellorPanel/cnrLead", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "prospectLead") {
              navigate("../CrmCounsellorPanel/prospectLead", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "interested") {
              navigate("../CrmCounsellorPanel/interested", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "callBackLead") {
              navigate("../CrmCounsellorPanel/callBackLead", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "overdueProspect") {
              navigate("../CrmCounsellorPanel/overdueProspect", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "overdueInterested") {
              navigate("../CrmCounsellorPanel/overdueInterested", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "overdueCallback") {
              navigate("../CrmCounsellorPanel/overdueCallback", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "overdueCNR") {
              navigate("../CrmCounsellorPanel/overdueCNR", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "callList") {
              navigate("../CrmCounsellorPanel/callList", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "IncomingCallList") {
              navigate("../CrmCounsellorPanel/incomingCall", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "counsellorDashbord") {
              navigate("../CrmCounsellorPanel/counsellorDashbord", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "leadInvalid") {
              navigate("../CrmCounsellorPanel/leadInvalid", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "alreadyAdmissionDone") {
              navigate("../CrmCounsellorPanel/alreadyAdmissionDone", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "summary") {
              navigate("../CrmCounsellorPanel/summary", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "leadSearch") {
              navigate("../CrmCounsellorPanel/leadSearchByMobileNumber", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "leadAssignedStatusOverview") {
              navigate("../CrmCounsellorPanel/leadAssignedStatusOverview", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "finalCNR") {
              navigate("../CrmCounsellorPanel/finalCNR", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "welcomeCallDone") {
              navigate("../CrmCounsellorPanel/welcomeCallDone", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "welcomeCallReference") {
              navigate("../CrmCounsellorPanel/welcomeCallReference", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "followUp") {
              navigate("../CrmCounsellorPanel/followUp", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "interestedForUpselling") {
              navigate("../CrmCounsellorPanel/interestedForUpselling", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "prospectForUpselling") {
              navigate("../CrmCounsellorPanel/prospectForUpselling", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "technicalIssue") {
              navigate("../CrmCounsellorPanel/technicalIssue", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "userActivityDetailsList") {
              navigate("../CrmCounsellorPanel/userActivityDetails", {
                state: {
                  pageName: "leadDetail",
                  leadMobileNo,
                },
              });
            } else if (pageName === "referenceGeneratedList") {
              navigate("../CrmCounsellorPanel/referenceLeadsList", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "callRejectToday") {
              navigate("../CrmCounsellorPanel/callRejectToday", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "overdueCallReject") {
              navigate("../CrmCounsellorPanel/overdueCallReject", {
                state: {
                  pageName: "leadDetail",
                },
              });
            }
          },
          () => {}
        )
      );
    }
  };

  const resetData = () => {
    setFirstName("");
    setLastName("");
    setContact1("");
    setContact2("");
    setEmail("");
    setState("");
    setCity("");
    setAddress("");
    setEducationYear("");
    setQualification("");
    setCurrentCTC("");
    setCurrentCompany("");
    setExperience("");
    setSelectedCV("");
    setPdfCV("");
    setSelectedAadharCard("");
    setPdfAadharCard("");
    setSelectedPanCard("");
    setPdfPanCard("");
    setDepartment("");
    setDesignation("");
    setStatus("");
    setSubStatus("");
    setRemark("");
    setCallBackDate("");
    setFirstInterviewDate("");
    setSecondInterviewDate("");
    setHrRoundDate("");
    setJoiningDate("");
    navigate("../hrDashbord");
  };

  const onClickCall = () => {
    dispatch(
      makeCalltoLead(
        {
          crmEmployeeId: loggedInUserId,
          customerNumber: contact1,
          agentId: ozonetelAgentId,
        },
        (res: any) => {
          dispatch(updateCommonValues("ozonetelIframe", true));
          // setMakeCallsList([...makeCallsList, res?.success?.call_id]);
          dispatch(showSuccessSnackbar(res?.data) as any);
        },
        () => {}
      )
    );
  };

  const onChangeContact2 = (val: string) => {
    setIsSave(false);
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setContact2(val);
        if (val?.length === 10) {
          const postData = {
            mobileNo: val,
          };
          dispatch(
            getLeadByMobileNumber(
              postData,
              (res: any) => {
                setLeadByMobileNumberList(res);
                if (res?.length > 0) {
                  setContact2("");
                  dispatch(showErrorSnackbar("Lead is Already Present") as any);
                }
              },
              () => {}
            )
          );
        }
      }
    } else {
      setContact2(val);
    }
  };

  const onHandleContact2 = () => {
    setIsSave(true);

    let postData = {
      studentLeadId: selectedLeadId,
      altMobileNo: contact2,
    };
    if (
      postData?.altMobileNo === "" ||
      postData?.altMobileNo === undefined ||
      postData?.altMobileNo === null
    ) {
      delete postData?.altMobileNo;
    }

    dispatch(
      updateLeadDetails(
        postData,
        (res: any) => {},
        () => {}
      )
    );
  };

  const onClickCall2 = () => {
    dispatch(
      makeCalltoLead(
        {
          crmEmployeeId: loggedInUserId,
          customerNumber: contact2,
          agentId: ozonetelAgentId,
        },
        (res: any) => {
          dispatch(updateCommonValues("ozonetelIframe", true));
          // setMakeCallsList([...makeCallsList, res?.success?.call_id]);
          dispatch(showSuccessSnackbar(res?.data) as any);
        },
        () => {}
      )
    );
  };

  const onClickHistory = (val: any) => {
    setHistory(val);
    setPaymentHistoryData([]);
    if (val === 1) {
      dispatch(
        getCallHistory(
          { studentLeadsId: selectedLeadId },
          (res: any) => {
            setCallHistoryData(res);
            let enrolledList = res?.filter(
              (item: any) => item?.leadStatus?._id === 5
            );
            setEnrolledData(enrolledList);
          },
          () => {}
        )
      );
    } else if (val === 2) {
      dispatch(
        getLeadPaymentHistory(
          {
            _id: selectedLeadId,
          },
          (res: any) => {
            setPaymentHistoryData(res?.data);
          },
          () => {}
        )
      );
    } else if (val === 3 && referredStudentLeadId) {
      dispatch(
        getLeadPaymentHistory(
          {
            _id: referredStudentLeadId,
          },
          (res: any) => {
            setPaymentHistoryData(res?.data);
          },
          () => {}
        )
      );
    }
  };

  const viewPDF = (val: any) => {
    sessionStorage.setItem("pdfLink", val);
    window.open(
      "/PdfViewer",
      "_blank",
      "toolbar=no,scrollbars=yes,resizable=yes"
    );
  };

  return (
    <div className="col-md-12">
      <LeadDetailHeader
        handleCall={onClickCall}
        handleWhatsapp={handleOpenMessagePopup}
        page="hrLeadDetail"
        leadScore={leadScore}
        leadRank={rank}
        enrolledData={enrolledData}
        qualifiedExamArr={qualifiedExam}
        leadStatus={leadStatus}
        leadState={leadState}
        leadType={leadType}
        leadStartDate={leadStartDate}
        leadEndDate={leadEndDate}
        pageName={pageName}
        leadMobileNo={leadMobileNo}
      />

      <div className="row m-0">
        <div className="col-md-12 leaddetails">
          <div className="boxshadow mx-5 my-1">
            <div className="p-1 d-flex justify-content-center  ">
              <form
                onSubmit={handleSubmit}
                className="detailsdiv col-md-9 row d-flex justify-content-center px-2 "
              >
                <div className="col-md-6 col-sm-12 p-2">
                  <div className="tabheader p-2">Personal & Contact </div>
                  <div className="row d-flex justify-content-between pt-2 ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="First Name" />
                    </div>
                    <div className="col-md-7">
                      <InputField
                        placeholder="Enter First Name"
                        value={firstName}
                        onChangeInput={(value: any) =>
                          setFirstName(value?.toUpperCase())
                        }
                        // disabled={true}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Last Name" />
                    </div>
                    <div className="col-md-7">
                      <InputField
                        placeholder="Enter Last Name"
                        value={lastName}
                        onChangeInput={(value: any) =>
                          setLastName(value?.toUpperCase())
                        }
                        // disabled={true}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between  ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Contact 1" />
                    </div>
                    <div className="col-md-7">
                      <InputField
                        placeholder="Enter Contact 1"
                        value={contact1}
                        onChangeInput={(value: any) => setContact1(value)}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between  ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Contact 2" />
                    </div>
                    <div className="d-flex justify-content-between col-7 me-0">
                      <InputField
                        placeholder="Enter Contact 2"
                        value={contact2}
                        onChangeInput={(value: any) => onChangeContact2(value)}
                        disabled={isSave ? true : false}
                        minlength={length_Ten}
                        maxlength={length_Ten}
                      />
                      {!isSave ? (
                        <button
                          type="button"
                          className={
                            contact2?.length === 10
                              ? "btn btn-success ms-2 me-2 font12 mb-2 "
                              : "btn btn-secondary me-2 font12 mb-2 "
                          }
                          onClick={onHandleContact2}
                          disabled={
                            contact2?.length > 10 ||
                            leadByMobileNumberList?.length > 0
                              ? true
                              : false
                          }
                        >
                          {" "}
                          <span>Save</span>
                        </button>
                      ) : null}

                      {contact2?.length === 10 && isSave ? (
                        <button
                          type="button"
                          className={
                            contact2?.length === 10
                              ? "btn btn-success me-2 font12 mb-2 "
                              : "btn btn-secondary me-2 font12 mb-2 "
                          }
                          onClick={onClickCall2}
                          // disabled={contact2?.length <= 10 ? false : true}
                          disabled={
                            contact2?.length > 10 ||
                            leadByMobileNumberList?.length > 0
                              ? true
                              : false
                          }
                        >
                          {" "}
                          <span>
                            <CallIcon className="font12" />
                          </span>
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between  ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Email" />
                    </div>
                    <div className="col-md-7">
                      <InputField
                        placeholder="Enter Email"
                        value={email}
                        onChangeInput={(value: any) => setEmail(value)}
                        // disabled={true}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between mt-1">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="State" isRequired={true} />
                    </div>
                    <div className="col-md-7 ps-3">
                      <CustomDropdown
                        lableName="Select State"
                        setInputText={(value: any) => onChangeState(value)}
                        value={state}
                        options={stateList}
                        defaultValue={stateList?.length > 0 ? state : ""}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between mt-1">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="City" />
                    </div>
                    <div className="col-md-7  ps-3">
                      <CustomDropdown
                        lableName="Select City"
                        setInputText={(value: any) => setCity(value)}
                        value={city}
                        options={countryList}
                        defaultValue={countryList?.length > 0 ? city : ""}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between mt-1">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Address" />
                    </div>
                    <div className="col-md-7  ps-3">
                      <textarea
                        className="form-control"
                        placeholder={"Address"}
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 p-2">
                  <div className="tabheader p-2">Employment Details</div>
                  <div className="row d-flex justify-content-between  pt-3">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Company" />
                    </div>
                    <div className="col-md-7">
                      <CustomDropdown
                        lableName="Select Company"
                        setInputText={(value: any) => setCompany(value)}
                        value={company}
                        options={companyList}
                        defaultValue={
                          companyList?.length > 0 ? company?._id : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between  pt-3">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Department" />
                    </div>
                    <div className="col-md-7">
                      <CustomDropdown
                        lableName="Select Department"
                        setInputText={(value: any) => onChangeDepartment(value)}
                        value={department}
                        options={departmentList}
                        defaultValue={
                          examList?.length > 0 ? department?._id : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between  pt-3">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Sub Department" />
                    </div>
                    <div className="col-md-7">
                      <CustomDropdown
                        lableName="Select Sub Department"
                        setInputText={(value: any) => setSubDepartment(value)}
                        value={subDepartment}
                        options={subDepartmentList}
                        defaultValue={
                          subDepartmentList?.length > 0
                            ? subDepartment?._id
                            : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between  pt-3">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Designation" />
                    </div>
                    <div className="col-md-7">
                      <CustomDropdown
                        lableName="Select Designation"
                        setInputText={(value: any) => setDesignation(value)}
                        value={designation}
                        options={examList}
                        defaultValue={
                          examList?.length > 0 ? designation?._id : ""
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6 p-2">
                  <div className="tabheader p-2">Education & Work</div>
                  <div className="row d-flex justify-content-between pt-2 ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Qualification" isRequired={true} />
                    </div>
                    <div className="col-md-7">
                      <CustomDropdown
                        lableName="Select Qualification"
                        setInputText={(value: any) =>
                          onChangeQualification(value)
                        }
                        value={qualification}
                        options={qualificationList}
                        defaultValue={
                          qualificationList?.length > 0 ? qualification : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between  ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Education Year" />
                    </div>
                    <div className="col-md-7">
                      <CustomDropdown
                        lableName="Select Education Year"
                        setInputText={(value: any) => setEducationYear(value)}
                        value={educationYear}
                        options={educationYearList}
                        defaultValue={
                          educationYearList?.length > 0 ? educationYear : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Current Company" />
                    </div>
                    <div className="col-md-7">
                      <InputField
                        placeholder="Enter Current Company"
                        value={currentCompany}
                        onChangeInput={(value: any) => setCurrentCompany(value)}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Current CTC" />
                    </div>
                    <div className="col-md-7">
                      <InputField
                        placeholder="Enter Current CTC"
                        value={currentCTC}
                        onChangeInput={(value: any) => setCurrentCTC(value)}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Experience" />
                    </div>
                    <div className="col-md-7">
                      <InputField
                        placeholder="Enter Experience"
                        value={experience}
                        onChangeInput={(value: any) => setExperience(value)}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="row d-flex justify-content-between ">
                      <div className="mt-2">
                        <LabelField lableName="Upload CV" />
                        <ImagePickerAndViewer
                          lableName={"Upload PDF"}
                          value={selectedCV}
                          doctype={"pdf"}
                          removelabel={true}
                          htmlFor={`pdf-upload`}
                          handleChange={(e: any) => onChangePDFCV(e)}
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between ">
                      <div className="mt-2">
                        <LabelField lableName="Upload Aadhar Card" />
                        <ImagePickerAndViewer
                          lableName={"Upload PDF"}
                          value={selectedAadharCard}
                          doctype={"pdf"}
                          removelabel={true}
                          htmlFor={`pdf-upload-aadhar`}
                          handleChange={(e: any) => onChangePDFAadharCard(e)}
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between ">
                      <div className="mt-2">
                        <LabelField lableName="Upload Pan Card" />
                        <ImagePickerAndViewer
                          lableName={"Upload PDF"}
                          value={selectedPanCard}
                          doctype={"pdf"}
                          removelabel={true}
                          htmlFor={`pdf-upload-pan`}
                          handleChange={(e: any) => onChangePDFPanCard(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 p-2">
                  <div className="tabheader p-2">Action & Disposition</div>
                  <div className="row d-flex justify-content-between pt-2 ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Status" isRequired={true} />
                    </div>

                    <div className="col-md-7">
                      <CustomDropdown
                        lableName="Select Status"
                        setInputText={(value: any) => onChangeStatus(value)}
                        value={status}
                        options={statusList}
                        defaultValue={statusList?.length > 0 ? status : ""}
                        reset={resetStatus}
                        disabled={status?._id === 5 ? true : false}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between mt-1">
                    <div className="col-md-4 mt-2">
                      <LabelField
                        lableName="Status Details"
                        isRequired={true}
                      />
                    </div>
                    <div className="col-md-7">
                      <CustomDropdown
                        lableName="Select Status Details"
                        setInputText={(value: any) => onChangeSubStatus(value)}
                        value={subStatus}
                        options={statusDetailsList}
                        defaultValue={
                          statusDetailsList?.length > 0 ? subStatus : ""
                        }
                        disabled={status?._id === 5 ? true : false}
                      />
                    </div>
                  </div>

                  <div className="row d-flex justify-content-between p-2">
                    <textarea
                      className="form-control"
                      placeholder={"Remark"}
                      value={remark}
                      onChange={(e) => changeRemark(e.target.value)}
                    ></textarea>
                  </div>

                  {status?._id === 1 ? (
                    <div className="row d-flex justify-content-between  ">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Call Back Date" />
                      </div>
                      <div className="col-md-7">
                        <DateTime
                          value={callBackDate}
                          setInputText={(value: any) => setCallBackDate(value)}
                          defaultValue={callBackDate}
                          disabled={status?._id === 12 ? true : false}
                        />
                      </div>
                    </div>
                  ) : null}

                  {status?._id === 2 ? (
                    <div className="row d-flex justify-content-between mt-2">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="1st Interview Date" />
                      </div>
                      <div className="col-md-7">
                        <DateTime
                          value={firstInterviewDate}
                          setInputText={(value: any) =>
                            setFirstInterviewDate(value)
                          }
                          defaultValue={firstInterviewDate}
                        />
                      </div>
                    </div>
                  ) : null}

                  {status?._id === 3 ? (
                    <div className="row d-flex justify-content-between mt-2">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="2nd Interview Date" />
                      </div>
                      <div className="col-md-7">
                        <DateTime
                          value={secondInterviewDate}
                          setInputText={(value: any) =>
                            setSecondInterviewDate(value)
                          }
                          defaultValue={secondInterviewDate}
                        />
                      </div>
                    </div>
                  ) : null}

                  {status?._id === 4 ? (
                    <div className="row d-flex justify-content-between mt-2">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Hr Interview Date" />
                      </div>
                      <div className="col-md-7">
                        <DateTime
                          value={hrRoundDate}
                          setInputText={(value: any) => setHrRoundDate(value)}
                          defaultValue={hrRoundDate}
                        />
                      </div>
                    </div>
                  ) : null}

                  {status?._id === 9 ? (
                    <div className="row d-flex justify-content-between mt-2">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Joining Date" />
                      </div>
                      <div className="col-md-7">
                        <DateTime
                          value={joiningDate}
                          setInputText={(value: any) => setJoiningDate(value)}
                          defaultValue={joiningDate}
                        />
                      </div>
                    </div>
                  ) : null}

                  <div className=" d-flex justify-content-end  mt-2">
                    <SubmitButton name={"Save"} />
                  </div>
                </div>
              </form>
              <div className="col-md-3 p-3 pt-2">
                <div className="d-flex justify-content-between tabheader p-1">
                  <button
                    className={`btn btn-sm me-1 w-100 ${
                      history === 1 ? "btn-success" : "btn-secondary"
                    }`}
                    onClick={() => onClickHistory(1)}
                  >
                    Call History
                  </button>
                  {/* <button
                    className={`btn btn-sm me-1 ${
                      history === 2 ? "btn-success" : "btn-secondary"
                    }`}
                    onClick={() => onClickHistory(2)}
                  >
                    Payment History
                  </button>
                  <button
                    className={`btn btn-sm me-1 ${
                      history === 3 ? " btn-success" : " btn-secondary"
                    }`}
                    onClick={() => onClickHistory(3)}
                  >
                    Reference History
                  </button> */}
                </div>
                <div className="historydetails">
                  {history === 1 ? (
                    callHistoryData?.map((value: any) => {
                      return (
                        <div className="my-2">
                          {value?.callBackDate ? (
                            <p className=" ps-4 fw-bold    mb-0 text-primary">
                              Call Back Date :{" "}
                              {moment(value?.callBackDate)
                                .utc()
                                .format("DD-MM-YYYY hh:mm:ss a")}
                            </p>
                          ) : null}
                          {value?.call_date ? (
                            <div className="d-flex justify-content-between">
                              <span className="ps-4 fw-bold mb-0 text-secondary">
                                Called Date & Time :{" "}
                                {moment(value?.call_date).format("DD-MM-YYYY")}{" "}
                                {value?.call_time}
                              </span>
                            </div>
                          ) : null}
                          {value?.call_date ? (
                            <div className="d-flex justify-content-between">
                              <span className="ps-4 fw-bold mb-0 text-secondary">
                                Call Type : {value?.calltype}
                              </span>
                            </div>
                          ) : null}
                          {value?.call_duration ? (
                            <div className="d-flex justify-content-between">
                              <span className="ps-4 fw-bold mb-0 text-secondary">
                                Call Duration : {value?.call_duration}
                              </span>
                            </div>
                          ) : null}
                          {value?.call_transfer_status ? (
                            <div className="d-flex justify-content-between">
                              <span className="ps-4 fw-bold mb-0 text-secondary">
                                Call Status : {value?.call_transfer_status}
                              </span>
                            </div>
                          ) : null}

                          <p className=" ps-4  mb-0 text-success">
                            {value?.courses[0]?.baseCourseName}
                          </p>
                          <p className=" ps-4 mb-0 text-danger">
                            {value?.courses[0]?.examTypeName}
                          </p>
                          <p className=" ps-4  mb-0 text-danger">-----</p>
                          <p className=" ps-4 mb-0 text-primary ">
                            Status : {value?.leadStatus?.status}
                          </p>
                          {value?.courses[0]?.mainCategoryName ? (
                            <p className=" ps-4 mb-0  ">
                              Course Type :{" "}
                              {value?.courses[0]?.mainCategoryName}
                            </p>
                          ) : null}
                          <p className=" ps-4 mb-0 text-success">
                            Status Details : {value?.leadSubStatus?.status}
                          </p>
                          {value?.couponName ? (
                            <p className=" ps-4 mb-0  ">
                              Coupon Code :{" "}
                              <span className=" mb-0  text-secondary">
                                {value?.couponName}
                              </span>
                            </p>
                          ) : null}
                          <div className="d-flex justify-content-center">
                            {value?.callRecords ? (
                              value?.callRecords?.map((item: any) => {
                                return (
                                  <audio
                                    className="ps-4 mb-0"
                                    src={item?.rURL}
                                    controls
                                  ></audio>
                                );
                              })
                            ) : value?.call_recording &&
                              value?.call_recording !== "None" ? (
                              <audio
                                className="ps-4 mb-0"
                                src={value?.call_recording}
                                controls
                              ></audio>
                            ) : null}
                          </div>
                          {value?.comment ? (
                            <p className=" ps-4 mb-0  ">
                              Comment :{" "}
                              <span className=" mb-0  text-secondary">
                                {value?.comment}
                              </span>
                            </p>
                          ) : null}
                          <hr />
                        </div>
                      );
                    })
                  ) : history === 2 ? (
                    paymentHistoryData?.map((value: any) => {
                      return (
                        <div className="my-2">
                          {value?.course ? (
                            <p className=" ps-4 fw-bold    mb-0 text-primary">
                              Course : {value?.course?.name}
                            </p>
                          ) : null}
                          {value?.orderDate ? (
                            <p className=" ps-4 fw-bold    mb-0 text-secondary">
                              Order Date :{" "}
                              {moment(value?.orderDate).format(
                                "DD-MM-YYYY hh:mm:ss a"
                              )}
                            </p>
                          ) : null}

                          {value?.withoutGstTotalAmount ? (
                            <p className=" ps-4 mb-0  ">
                              Actual Fees :{" "}
                              <span className=" mb-0  text-secondary">
                                {value?.withoutGstTotalAmount}
                              </span>
                            </p>
                          ) : null}
                          {value?.totalGstAmount ? (
                            <p className=" ps-4 mb-0  ">
                              GST :{" "}
                              <span className=" mb-0  text-secondary">
                                {value?.totalGstAmount}
                              </span>
                            </p>
                          ) : null}
                          {value?.netPayableAmount ? (
                            <p className=" ps-4 mb-0  ">
                              Total Paid :{" "}
                              <span className=" mb-0  text-secondary">
                                {value?.netPayableAmount}
                              </span>
                            </p>
                          ) : null}
                          {value?.couponName ? (
                            <p className=" ps-4 mb-0  ">
                              Coupon Code :{" "}
                              <span className=" mb-0  text-secondary">
                                {value?.couponName}
                              </span>
                            </p>
                          ) : null}
                          <p className=" ps-4  mb-0 text-danger">-----</p>
                          {value?.subscriptionPlanMonths ? (
                            <p className=" ps-4 mb-0  ">
                              Subscription Months :{" "}
                              {value?.subscriptionPlanMonths}
                            </p>
                          ) : null}
                          {/* <p className=" ps-4 mb-0 text-success">
                              Status Details : {value?.leadSubStatus?.status}
                            </p> */}

                          {value?.userSubscriptionEndDate ? (
                            <p className=" ps-4 fw-bold    mb-0 text-secondary">
                              Course End Date :{" "}
                              {moment(value?.userSubscriptionEndDate).format(
                                "DD-MM-YYYY hh:mm:ss a"
                              )}
                            </p>
                          ) : null}
                          <hr />
                        </div>
                      );
                    })
                  ) : referredStudentContactNo ? (
                    <div>
                      {referredStudentFirstName ? (
                        <span className="fw-bold mt-2 text-dark">
                          Student Name :{" "}
                          {`${referredStudentFirstName} ${referredStudentLastName}`}
                        </span>
                      ) : null}
                      {referredStudentContactNo ? (
                        <p className="fw-bold mt-0 text-dark">
                          Mobile No : {referredStudentContactNo}
                        </p>
                      ) : null}
                      <hr />
                      {paymentHistoryData?.map((value: any) => {
                        return (
                          <div className="my-2">
                            {value?.course ? (
                              <p className=" ps-4 fw-bold    mb-0 text-primary">
                                Course : {value?.course?.name}
                              </p>
                            ) : null}
                            <p className=" ps-4  mb-0 text-danger">-----</p>
                            {value?.subscriptionPlanMonths ? (
                              <p className=" ps-4 mb-0  ">
                                Subscription Months :{" "}
                                {value?.subscriptionPlanMonths}
                              </p>
                            ) : null}

                            {value?.userSubscriptionEndDate ? (
                              <p className=" ps-4 fw-bold    mb-0 text-secondary">
                                Course End Date :{" "}
                                {moment(value?.userSubscriptionEndDate).format(
                                  "DD-MM-YYYY hh:mm:ss a"
                                )}
                              </p>
                            ) : null}
                            <hr />
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {openPopup ? (
        <MessagePopUp open={openPopup} handleClose={handleClose} />
      ) : (
        ""
      )}
      {openEmailPopup ? (
        <EmailPopUp open={openEmailPopup} handleClose={handleClose} />
      ) : (
        ""
      )}
    </div>
  );
}
