import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Login from "./Panel/Auth/login";
import ForgotPassword from "./Panel/Auth/forgotPassword";
import ErrorSnackbar from "./Component/errorSnackbar";
import Backdrop from "@mui/material/Backdrop";
import SuccessSnackbar from "./Component/successSnackbar";
import { useAppDispatch, useAppSelector } from "./hooks";
import { AuthReducerModal } from "./Redux/Modals";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import CallIcon from "@mui/icons-material/Call";
import CallEndIcon from "@mui/icons-material/CallEnd";

import CRMAdminPanelDashboard from "./Panel/CRMAdminPanel/crmAdminDashboard";
import CRMDataOperatorDashboard from "./Panel/CRMDataOperatorPanel/crmDataOperatorDashboard";
import CRMDataEntryDashboard from "./Panel/CRMDataEntryPanel/crmDataEntryDashboard";
import CrmCounsellorDashboard from "./Panel/CRMCounsellorPanel/crmCounsellorDashboard";
import CrmTeamLeadDashboard from "./Panel/CRMTeamLeadPanel/crmTeamLeadDashboard";
import CrmManagerDashboard from "./Panel/CRMManagerPanel/crmManagerDashboard";
import HrDataOperatorDashboard from "./Panel/HrDataOperatorPanel/hrDataOperatorDashboard";
import HrPanelDashboard from "./Panel/HrPanel/hrPanelDashboard";

import LeadDetail from "./Panel/CRMCounsellorPanel/leadDetail";
import HrLeadDetail from "./Panel/HrPanel/hrLeadDetail";
import BdeAdminPanelDashboard from "./Panel/BDEAdmin/bdeAdminPanelDashboard";
import BDEPanelDashbord from "./Panel/BDEdashboardPanel/bdepanelDashbord";
import Departmentdetails from "./Panel/BDEdashboardPanel/departmentdetails";
import WebinarStatusDetails from "./Panel/BDEdashboardPanel/webinarStatusDetails";
import CompletedWebinarStatusDetails from "./Panel/BDEdashboardPanel/completedWebinarStatusDetails";
import CancelledWebinarStatusDetails from "./Panel/BDEdashboardPanel/cancelledWebinarStatusDetails";
import PendingWebinarStatusDetails from "./Panel/BDEdashboardPanel/pendingWebinarStatusDetails ";
import BDManagerDashboard from "./Panel/BDTeamLead/bdTeamLeadDashboard";
import BDTeamLead from "./Panel/BDTeamLead/bdTeamLead";
import Details from "./Panel/BDTeamLead/details";
import WebinarDetails from "./Panel/BDTeamLead/webinarDetails";
import TLCompletewebinarDetails from "./Panel/BDTeamLead/tlCompletewebinarDetails";
import TLCancelledwebinarDetails from "./Panel/BDTeamLead/tlCancelledWebinarDetails";
import TLPendingwebinarDetails from "./Panel/BDTeamLead/tlPendingWebinarDetails ";
import SuperAdminPanelDashboard from "./Panel/CRMSuperManagerPanel/superAdminPaneldashboard";
import CompleteWebinarDetailsList from "./Panel/BDTeamLead/completeWebiarDeatilsList";
import ImageDisplay from "./Panel/BDEdashboardPanel/image";
import { updateCommonValues } from "./Redux/Actions/CommonAction";

function App() {
  const dispatch = useAppDispatch();
  const {
    loader,
    authToken,
    loggedInUserRole,
    ozonetelURL,
    ozonetelReduxframe,
  } = useAppSelector((state: { Auth: AuthReducerModal }) => ({
    loader: state?.Auth?.loader,
    authToken: state.Auth.authToken,
    loggedInUserRole: state.Auth.loggedInUserRole,
    ozonetelURL: state.Auth.ozonetelURL,
    ozonetelReduxframe: state.Auth.ozonetelIframe,
  }));

  const [ozonetelIFrame, setOzonetelIframe] = useState<boolean>(false);
  const [isRinging, setIsRinging] = useState(false);
  const [iframe, setIframe] = useState<any>(null);
  const messageReceived = useRef(false);

  useEffect(() => {
    const handleMessage = (event: any) => {
      console.log("eventttt", event);
      if (event.origin === "https://agent.cloudagent.ozonetel.com") {
        if (event.data.action === "dropCall") {
          messageReceived.current = true; // Set to true if message is received
          setOzonetelIframe(true); // Show iframe when call is detected
        }
      }
    };

    // Add the event listener
    window.addEventListener("message", handleMessage);

    // Check periodically if the message has been received
    const interval = setInterval(() => {
      if (messageReceived.current) {
        console.log("Message received!");
        messageReceived.current = false; // Reset the status after checking
      } else {
        console.log("No message received yet.");
      }
    }, 1000); // Check every 5 seconds

    // Cleanup on unmount and clear interval
    return () => {
      window.removeEventListener("message", handleMessage);
      clearInterval(interval); // Clear the interval when the component unmounts
    };
  }, []);

  // // This will be called when the call comes in
  // function onIncomingCall() {
  //   // Notify the parent window that there is an incoming call
  //   window.parent.postMessage(
  //     {
  //       action: "dropCall", // Message action
  //       callDetails: {
  //         caller: "John Doe",
  //         number: "+1234567890",
  //       },
  //     },
  //     "https://agent.cloudagent.ozonetel.com"
  //   ); // Parent URL for security
  // }

  // // Example of detecting the incoming call (this is just a mockup)
  // function detectIncomingCall() {
  //   // Logic to detect an incoming call in Ozonetel (e.g., API event or socket)
  //   setTimeout(() => {
  //     onIncomingCall(); // Trigger the parent message
  //   }, 1000); // Simulate an incoming call after 5 seconds
  // }

  // detectIncomingCall();

  useEffect(() => {
    if (ozonetelReduxframe) {
      const interval = setInterval(() => {
        if (authToken) {
          setOzonetelIframe(true);
        }
      }, 1000);
      return () => clearInterval(interval);
    } else {
      setOzonetelIframe(false);
      dispatch(updateCommonValues("ozonetelIframe", false));
    }
  }, [ozonetelReduxframe]);

  const OnChangeIframe = () => {
    if (ozonetelIFrame) {
      dispatch(updateCommonValues("ozonetelIframe", false));
      setOzonetelIframe(false);
    } else {
      dispatch(updateCommonValues("ozonetelIframe", true));
      setOzonetelIframe(true);
    }
  };

  return (
    <div className="App">
      {loader ? (
        <Backdrop sx={{ color: "#fff", zIndex: "10001" }} open>
          <span className="loader"></span>
        </Backdrop>
      ) : (
        ""
      )}
      <SuccessSnackbar />
      <ErrorSnackbar />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="CRMAdminPanel/*" element={<CRMAdminPanelDashboard />} />
        <Route
          path="CRMDataOperatorPanel/*"
          element={<CRMDataOperatorDashboard />}
        />
        <Route path="CRMDataEntryPanel/*" element={<CRMDataEntryDashboard />} />
        <Route
          path="CrmCounsellorPanel/*"
          element={<CrmCounsellorDashboard />}
        />
        <Route path="CrmTeamLeadPanel/*" element={<CrmTeamLeadDashboard />} />
        <Route path="CrmManagerPanel/*" element={<CrmManagerDashboard />} />
        <Route path="SuperManager/*" element={<SuperAdminPanelDashboard />} />

        <Route path="BDEAdminPanel/*" element={<BdeAdminPanelDashboard />} />
        <Route path="BDEPanelDashbord/*" element={<BDEPanelDashbord />} />
        <Route path="BDTeamLead/*" element={<BDTeamLead />} />

        <Route path="leadDetail" element={<LeadDetail />} />
        <Route path="hrLeadDetail" element={<HrLeadDetail />} />
        <Route path="departmentdetails" element={<Departmentdetails />} />
        <Route path="webinarStatusDetails" element={<WebinarStatusDetails />} />
        <Route
          path="completedWebinarStatusDetails"
          element={<CompletedWebinarStatusDetails />}
        />
        <Route
          path="cancelledWebinarStatusDetails"
          element={<CancelledWebinarStatusDetails />}
        />
        <Route
          path="pendingWebinarStatusDetails"
          element={<PendingWebinarStatusDetails />}
        />
        <Route path="details" element={<Details />} />
        <Route path="webinarDetails" element={<WebinarDetails />} />
        <Route
          path="tlCompletewebinarDetails"
          element={<TLCompletewebinarDetails />}
        />
        <Route
          path="tlCancelledwebinarDetails"
          element={<TLCancelledwebinarDetails />}
        />
        <Route
          path="tlPendingwebinarDetails"
          element={<TLPendingwebinarDetails />}
        />
        <Route
          path="completeWebinarDetailsList"
          element={<CompleteWebinarDetailsList />}
        />
        <Route path="image" element={<ImageDisplay />} />
        <Route
          path="HrDataOperatorPanel/*"
          element={<HrDataOperatorDashboard />}
        />
        <Route path="HrPanel/*" element={<HrPanelDashboard />} />
      </Routes>
      {authToken &&
      (loggedInUserRole === 3 ||
        loggedInUserRole === 4 ||
        loggedInUserRole === 5 ||
        loggedInUserRole === 6 ||
        loggedInUserRole === 7 ||
        loggedInUserRole === 8 ||
        loggedInUserRole === 9 ||
        loggedInUserRole === 10 ||
        loggedInUserRole === 11 ||
        loggedInUserRole === 12) ? (
        <div>
          {/* {ozonetelIFrame && ( */}
          <iframe
            id="ca_frame"
            sandbox="allow-scripts allow-forms allow-same-origin allow-popups allow-modals allow-downloads"
            allow="geolocation; microphone; display-capture; notifications"
            src={ozonetelURL}
            width="380px"
            height="600px"
            className={`ozonetelCallFrame ${
              ozonetelIFrame ? "d-block" : "d-none"
            }`}
            title="Ozonetel Incoming Call"
          />
          {/* )} */}
          <div className="ozonetelCallbutton">
            {ozonetelIFrame ? (
              <button
                className="btn btn-sm btn-danger rounded-pill"
                onClick={OnChangeIframe}
                style={{
                  boxShadow: "0 5px 10px rgba(0, 0, 0, 0.2)", // Shadow for depth
                  transition: "all 0.3s ease", // Smooth transition
                }}
              >
                {" "}
                <CallEndIcon />
              </button>
            ) : (
              <button
                className="btn btn-success rounded-pill callIconOzone"
                style={{
                  width: "60px",
                  height: "60px",
                  backgroundColor: "#28a745",
                  border: "none",
                  outline: "none",
                  boxShadow: "0 5px 10px rgba(0, 0, 0, 0.2)",
                  transition: "all 0.3s ease",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={OnChangeIframe}
              >
                <CallIcon />
              </button>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
}
export default App;
