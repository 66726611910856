import "../../App.css";
import React, { Suspense } from "react";
import Sidebar from "../../Module/sidebar";
import { Route, Routes } from "react-router-dom";
import { SuperAdminSideBar } from "../../DATA/superAdminSideBar";
import Loading from "../../Component/loading";
import Header from "../../Component/header";

import SalesDirectorDashboard from "./salesDirectorDashboard";
import SalesDirectorManager from "./salesDirectorManager";
import SalesDirectorTeamLead from "./salesDirectorTeamLead";
import SalesDirectorCounsellor from "./salesDirectorCounsellor";
import SalesDirectorCounsellorTarget from "./salesDirectorCounsellorTarget";
import LeadAssignedDateAnalysis from "./leadAssignedDateAnalysis";
import LeadAssignedStatusOverview from "./leadAssignedStatusOverview";
import SuperAdminDashbord from "./superAdminDashboard";
import TeamLeadOverViewDetails from "./teamLeadOverViewDetails";
import AssignedLeadDateAnalysis from "./assignedLeadDateAnalysis";
import LeadStatusOverview from "./leadStatusOverview";
import AssignedLeadDetails from "./assignedLeadDetails";
import SALeadAnalysis from "./sALeadAnalysis";
import SAEnrolledAnalysis from "./sAEnrollAnalysis";
import SACourseAnalysis from "./saCourseAnalysis";
import SAReferenceAnalysisManager from "./saReferenceanalysisManager";
import SAReferenceAnalysis from "./saReferenceAnalysis";
import LeadAllotedBydate from "./leadAllotedBydate";
import SALeadByAdmissionDate from "./saLeadByAdmissionDate";
import SuperManagerExamRevenue from "./smExamRevenue";
import SMTargetStatus from "./smTargetStatus";
import SACouponCode from "./sACouponCode";
import ManageEmployee from "../CRMDataOperatorPanel/manageEmployee";
import LeadTransfer from "../CRMDataOperatorPanel/leadTransfer";
import SAActiveEmployee from "./saActiveEmployee";
import SAAttendance from "./saAttendance";
import SAMonthlyReport from "./saMonthlyReport";
import DailyMeeting from "./dailyMeeting";
import SMLeadAssignedStatusDetails from "./sMLeadAssignedStatusDetails";
import SMCompleteLeadDetail from "./sMCompleteLeadDetails";
import SMSkipDetails from "./sMSkipDetails";
import IncentiveTarget from "../CRMDataOperatorPanel/incentiveTarget";
import LeadAllottedAnalysisbyDate from "./leadAllottedAnalysisbyDate";
import TimeAnalysisSuperManager from "./timeAnalysisSuperManager";
import RevenueAnalysisSuperManager from "./revenueAnalysisSuperManager";
import SMSummeryOverview from "./sMSummeryOverview";
import SMCompleteDetails from "./sMCompleteDetails";
import CounsellorCallDetails from "../CRMTeamLeadPanel/counsellorCallDetails";
import LeadDetail from "../CRMTeamLeadPanel/completeLeadDetail";

const TransactionEdutech = React.lazy(() => import("./transactionEdutech"));
const TransactionEdutechStudyMaterial = React.lazy(
  () => import("./transactionEdutechStudyMaterial")
);
const SourceAnalysis = React.lazy(() => import("./sourceWiseAnalysis"));
const StateAnalysis = React.lazy(() => import("./stateWiseAnalysis"));
const AllExamWiseRevenue = React.lazy(() => import("./allExamWiseRevenue"));
const CxoBatchwiseCount = React.lazy(() => import("./cxoBatchWiseCount"));
const DatewiseCount = React.lazy(() => import("./cxoDateWiseCount"));
const AssuredRevenue = React.lazy(() => import("./assuredRevenue"));
const SecondInstallmentDue = React.lazy(() => import("./secondInstallmentDue"));
const SecondInstallmentPaid = React.lazy(
  () => import("./secondInstallmentPaid")
);
const RegisteredUser = React.lazy(() => import("./registeredUser"));
const StudentCount = React.lazy(() => import("./studentCount"));
const WebsiteLeadCount = React.lazy(() => import("./websiteLeadCount"));
const ClassroomStudents = React.lazy(() => import("./classroomStudents"));
const ExpertSupport = React.lazy(() => import("./expertSupport"));
const LeadAnalysis = React.lazy(() => import("./leadAnalysis"));
const ReferenceGenerated = React.lazy(() => import("./referenceGenerated"));

const ReferenceManagerCount = React.lazy(
  () => import("./referenceManagerCount")
);
const ReferenceTeamLeadCount = React.lazy(
  () => import("./referenceTeamLeadCount")
);
const ReferenceLeadsCount = React.lazy(() => import("./referenceLeadsCount"));

const LeadGenerationOverview = React.lazy(
  () => import("../CRMAdminPanel/leadGenerationOverview")
);
const LeadOverview = React.lazy(() => import("../CRMAdminPanel/leadOverview"));

const TeamLeadOverview = React.lazy(() => import("./teamLeadOverview"));

const SalesPerformance = React.lazy(() => import("./salesPerformance"));

function SuperAdminPanelDashboard(Props: any) {
  return (
    <div className="AppBox">
      {/* <Header/>    */}
      <Sidebar data={SuperAdminSideBar} pagename />
      <div className="dashboard">
        {
          <Suspense
            fallback={
              <div className="border p-1">
                <Loading />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<SalesDirectorDashboard />} />
              <Route
                path="salesDirectorDashboard"
                element={<SalesDirectorDashboard />}
              />
              <Route
                path="salesDirectorManager"
                element={<SalesDirectorManager />}
              />
              <Route
                path="salesDirectorTeamLead"
                element={<SalesDirectorTeamLead />}
              />
              <Route
                path="salesDirectorCounsellor"
                element={<SalesDirectorCounsellor />}
              />
              <Route
                path="salesDirectorCounsellorTarget"
                element={<SalesDirectorCounsellorTarget />}
              />
              <Route
                path="leadAssignedDateAnalysis"
                element={<LeadAssignedDateAnalysis />}
              />
              <Route
                path="leadAssignedStatusOverview"
                element={<LeadAssignedStatusOverview />}
              />
              <Route
                path="superAdminDashbord"
                element={<SuperAdminDashbord />}
              />
              <Route
                path="leadGenerationOverview"
                element={<LeadGenerationOverview />}
              />
              <Route path="leadOverview" element={<LeadOverview />} />
              <Route path="teamLeadOverview" element={<TeamLeadOverview />} />
              <Route
                path="teamLeadOverViewDetails"
                element={<TeamLeadOverViewDetails />}
              />
              <Route
                path="assignedLeadDateAnalysis"
                element={<AssignedLeadDateAnalysis />}
              />
              <Route
                path="leadStatusOverview"
                element={<LeadStatusOverview />}
              />
              <Route
                path="assignedLeadDetails"
                element={<AssignedLeadDetails />}
              />
              <Route path="sALeadAnalysis" element={<SALeadAnalysis />} />
              <Route path="sAEnrollAnalysis" element={<SAEnrolledAnalysis />} />
              <Route path="saCourseAnalysis" element={<SACourseAnalysis />} />
              <Route
                path="saReferenceanalysisManager"
                element={<SAReferenceAnalysisManager />}
              />
              <Route
                path="saReferenceAnalysis"
                element={<SAReferenceAnalysis />}
              />
              <Route path="leadAllotedBydate" element={<LeadAllotedBydate />} />
              <Route
                path="saLeadByAdmissionDate"
                element={<SALeadByAdmissionDate />}
              />
              <Route
                path="smExamRevenue"
                element={<SuperManagerExamRevenue />}
              />
              <Route path="smTargetStatus" element={<SMTargetStatus />} />
              <Route path="sACouponCode" element={<SACouponCode />} />
              <Route path="manageEmployee" element={<ManageEmployee />} />
              <Route path="leadTransfer" element={<LeadTransfer />} />
              <Route path="saActiveEmployee" element={<SAActiveEmployee />} />
              <Route path="saAttendance" element={<SAAttendance />} />
              <Route path="saMonthlyReport" element={<SAMonthlyReport />} />
              <Route path="dailyMeeting" element={<DailyMeeting />} />
              <Route
                path="sMLeadAssignedStatusDetails"
                element={<SMLeadAssignedStatusDetails />}
              />
              <Route
                path="sMCompleteLeadDetail"
                element={<SMCompleteLeadDetail />}
              />
              <Route path="sMSkipDetails" element={<SMSkipDetails />} />
              <Route path="incentiveTarget" element={<IncentiveTarget />} />
              <Route
                path="leadAllottedAnalysisbyDate"
                element={<LeadAllottedAnalysisbyDate />}
              />
              <Route
                path="timeAnalysisSuperManager"
                element={<TimeAnalysisSuperManager />}
              />
              <Route
                path="revenueAnalysisSuperManager"
                element={<RevenueAnalysisSuperManager />}
              />
              <Route path="sMSummeryOverview" element={<SMSummeryOverview />} />
              <Route path="sMCompleteDetails" element={<SMCompleteDetails />} />
              <Route
                path="counsellorCallDetails"
                element={<CounsellorCallDetails />}
              />
              <Route path="completeLeadDetail" element={<LeadDetail />} />
              {/*********************** Transactions *************************** */}
              <Route
                path="transactionEdutech"
                element={<TransactionEdutech />}
              />
              <Route
                path="transactionEdutechStudyMaterial"
                element={<TransactionEdutechStudyMaterial />}
              />
              <Route path="sourceWiseAnalysis" element={<SourceAnalysis />} />
              <Route path="stateWiseAnalysis" element={<StateAnalysis />} />
              <Route
                path="allExamWiseRevenue"
                element={<AllExamWiseRevenue />}
              />
              <Route path="cxobatchwiseCount" element={<CxoBatchwiseCount />} />
              <Route path="datewiseCount" element={<DatewiseCount />} />
              <Route path="assuredRevenue" element={<AssuredRevenue />} />
              <Route
                path="secondInstallmentDue"
                element={<SecondInstallmentDue />}
              />
              <Route
                path="secondInstallmentPaid"
                element={<SecondInstallmentPaid />}
              />
              <Route path="registeredUser" element={<RegisteredUser />} />
              <Route path="allSourceLead" element={<StudentCount />} />
              <Route path="websiteLeadCount" element={<WebsiteLeadCount />} />
              <Route path="classroomStudents" element={<ClassroomStudents />} />
              <Route path="expertSupport" element={<ExpertSupport />} />
              <Route path="leadAnalysis" element={<LeadAnalysis />} />
              <Route
                path="referenceGenerated"
                element={<ReferenceGenerated />}
              />
              <Route
                path="referenceManagerCount"
                element={<ReferenceManagerCount />}
              />
              <Route
                path="referenceTeamLeadCount"
                element={<ReferenceTeamLeadCount />}
              />
              <Route
                path="referenceLeadsCount"
                element={<ReferenceLeadsCount />}
              />
              <Route path="salesPerformance" element={<SalesPerformance />} />
            </Routes>
          </Suspense>
        }
      </div>
    </div>
  );
}

export default SuperAdminPanelDashboard;
