import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../Component/pageTitle";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import BarGraph from "../../Component/barGraph";
import {
  getCounsellorAllLeadsCount,
  getDashboardCallRecords,
  getDashboardIncomingRecords,
  getDashboardRecords,
  getDashboardRevenueRecords,
  getDashboardStatusRecords,
  getDashboardTotalRevenue,
} from "../../Redux/Actions/counsellorPanelAction";
import { useSelector } from "react-redux";
import moment from "moment";
// import CanvasJSChart from "@canvasjs/react-charts";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export default function HrDashbord() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );

  const [resumeShared, setResumeShared] = useState<number>(0);
  const [firstRoundInterview, setFirstRoundInterview] = useState<number>(0);
  const [secondRoundInterview, setSecondRoundInterview] = useState<number>(0);
  const [hrRoundInterview, setHrRoundInterview] = useState<number>(0);
  const [rejected, setRejected] = useState<number>(0);
  const [joiningToday, setJoiningToday] = useState<number>(0);
  const [joiningInWeek, setJoiningInWeek] = useState<number>(0);
  const [callingDone, setCallingDone] = useState<number>(0);

  const [xLabel1, setXLabel1] = useState<any>([]);
  const [xLabel2, setXLabel2] = useState<any>([]);
  const [xLabel3, setXLabel3] = useState<any>([]);
  const [Graphdata1, setGraphdata1] = useState<any>([]);
  const [Graphdata2, setGraphdata2] = useState<any>([]);
  const [Graphdata3, setGraphdata3] = useState<any>([]);

  const [xLabel4, setXLabel4] = useState<any>([]);
  const [xLabel5, setXLabel5] = useState<any>([]);
  const [xLabel6, setXLabel6] = useState<any>([]);
  const [Graphdata4, setGraphdata4] = useState<any>([]);
  const [Graphdata5, setGraphdata5] = useState<any>([]);
  const [Graphdata6, setGraphdata6] = useState<any>([]);

  const {
    loggedInUserId,
    loggedInUserManagerId,
    loggedInUserRole,
    loggedInUserName,
  } = useSelector((state: any) => ({
    loggedInUserId: state.Auth.loggedInUserId,
    loggedInUserManagerId: state.Auth.loggedInUserManagerId,
    loggedInUserRole: state.Auth.loggedInUserRole,
    loggedInUserName: state.Auth.loggedInUserName,
  }));

  useEffect(() => {
    onChangeGetRevenue();
    dispatch(
      getDashboardStatusRecords(
        loggedInUserId,
        (res: any) => {
          setResumeShared(res?.data?.resumeShared);
          setFirstRoundInterview(res?.data?.firstRoundInterview);
          setSecondRoundInterview(res?.data?.secondRoundInterview);
          setHrRoundInterview(res?.data?.hrRoundInterview);
          setRejected(res?.data?.rejected);
          setJoiningToday(res?.data?.joiningToday);
          setJoiningInWeek(res?.data?.joiningInWeek);
          setCallingDone(res?.data?.callingDone);
        },
        () => {}
      )
    );
  }, []);

  const onChange = (value: string) => {
    if (value === "resumeShared") {
      navigate("../resumeShared");
    } else if (value === "firstRoundInterview") {
      navigate("../firstRoundInterview");
    } else if (value === "secondRoundInterview") {
      navigate("../secondRoundInterview");
    } else if (value === "hrRoundInterview") {
      navigate("../hrRoundInterview");
    } else if (value === "rejected") {
      navigate("../rejected");
    } else if (value === "joiningToday") {
      navigate("../joiningToday");
    } else if (value === "joiningInWeek") {
      navigate("../joiningInWeek");
    } else if (value === "callingDone") {
      navigate("../callingDone");
    }
  };

  const onChangeGetRevenue = () => {
    let data = {
      startDate,
      endDate,
    };
    dispatch(
      getDashboardRevenueRecords(
        loggedInUserId,
        data,
        (res: any) => {
          // Revenue
          for (let i = 0; i <= xLabel1?.length; i++) {
            xLabel1?.pop();
            Graphdata1?.pop();
          }
          if (res) {
            if (res[0]?.target >= 0) {
              xLabel1?.push("Target");
              Graphdata1?.push(res[0]?.target);
            }
            if (res[0]?.achieved >= 0) {
              xLabel1?.push("Achieved");
              Graphdata1?.push(res[0]?.achieved);
            }
          }
        },
        () => {}
      )
    );

    const postData = {
      startTime: startDate,
      endTime: endDate,
    };
    if (loggedInUserRole === 5) {
      dispatch(
        getDashboardIncomingRecords(
          loggedInUserId,
          postData,
          (res: any) => {
            // Calling Time
            for (let i = 0; i <= xLabel2?.length; i++) {
              xLabel2?.pop();
              Graphdata2?.pop();
            }
            if (res?.callTime) {
              if (res?.callTime?.target >= 0) {
                xLabel2?.push("Target");
                Graphdata2?.push(res?.callTime?.target);
              }
              if (res?.callTime?.achieved >= 0) {
                xLabel2?.push("Achieved");
                Graphdata2?.push(res?.callTime?.achieved);
              }
            }
            // Reference
            for (let i = 0; i <= xLabel3?.length; i++) {
              xLabel3?.pop();
              Graphdata3?.pop();
            }
            if (res?.reference) {
              if (res?.reference?.target >= 0) {
                xLabel3?.push("Target");
                Graphdata3?.push(res?.reference?.target);
              }
              if (res?.reference?.achieved >= 0) {
                xLabel3?.push("Achieved");
                Graphdata3?.push(res?.reference?.achieved);
              }
            }
          },
          () => {}
        )
      );
    } else if (loggedInUserRole !== 5) {
      dispatch(
        getDashboardRecords(
          loggedInUserId,
          postData,
          (res: any) => {
            // Calling Time
            for (let i = 0; i <= xLabel2?.length; i++) {
              xLabel2?.pop();
              Graphdata2?.pop();
            }
            if (res?.callTime) {
              if (res?.callTime?.target >= 0) {
                xLabel2?.push("Target");
                Graphdata2?.push(res?.callTime?.target);
              }
              if (res?.callTime?.achieved >= 0) {
                xLabel2?.push("Achieved");
                Graphdata2?.push(res?.callTime?.achieved);
              }
            }
            // Reference
            for (let i = 0; i <= xLabel3?.length; i++) {
              xLabel3?.pop();
              Graphdata3?.pop();
            }
            if (res?.reference) {
              if (res?.reference?.target >= 0) {
                xLabel3?.push("Target");
                Graphdata3?.push(res?.reference?.target);
              }
              if (res?.reference?.achieved >= 0) {
                xLabel3?.push("Achieved");
                Graphdata3?.push(res?.reference?.achieved);
              }
            }
          },
          () => {}
        )
      );
    }

    dispatch(
      getDashboardCallRecords(
        loggedInUserId,
        postData,
        (res: any) => {
          // Total Connected Calls
          for (let i = 0; i <= xLabel4?.length; i++) {
            xLabel4?.pop();
            Graphdata4?.pop();
          }
          if (res?.totalConnectedCall) {
            if (res?.totalConnectedCall?.target >= 0) {
              xLabel4?.push("Target");
              Graphdata4?.push(res?.totalConnectedCall?.target);
            }
            if (res?.totalConnectedCall?.achieved >= 0) {
              xLabel4?.push("Achieved");
              Graphdata4?.push(res?.totalConnectedCall?.achieved);
            }
          }
          // Unique Connected Calls
          for (let i = 0; i <= xLabel5?.length; i++) {
            xLabel5?.pop();
            Graphdata5?.pop();
          }
          if (res?.uniqueConnectedCall) {
            if (res?.uniqueConnectedCall?.target >= 0) {
              xLabel5?.push("Target");
              Graphdata5?.push(res?.uniqueConnectedCall?.target);
            }
            if (res?.uniqueConnectedCall?.achieved >= 0) {
              xLabel5?.push("Achieved");
              Graphdata5?.push(res?.uniqueConnectedCall?.achieved);
            }
          }
          // Old Connected Calls
          for (let i = 0; i <= xLabel6?.length; i++) {
            xLabel6?.pop();
            Graphdata6?.pop();
          }
          if (res?.oldConnectedCall) {
            if (res?.oldConnectedCall?.target >= 0) {
              xLabel6?.push("Target");
              Graphdata6?.push(res?.oldConnectedCall?.target);
            }
            if (res?.oldConnectedCall?.achieved >= 0) {
              xLabel6?.push("Achieved");
              Graphdata6?.push(res?.oldConnectedCall?.achieved);
            }
          }
        },
        () => {}
      )
    );
  };

  const options = {
    animationEnabled: true,
    exportEnabled: false,
    title: {
      text: "Source Wise Analysis",
      fontFamily: "verdana",
    },
    axisY: {
      title: "Revenue",
    },
    axisX: {
      title: "Source",
      interval: 1,
    },
    toolTip: {
      shared: true,
      reversed: true,
    },
    data: [
      {
        // type: "stackedColumn",
        type: "column",
        name: "Actual Revenue",
        indexLabel: "{y}",
        showInLegend: true,
        dataPoints: [{ label: "VIpul", y: 2 }],
      },
      // transactionType?._id === 1
      //   ? {
      //       type: "stackedColumn",
      //       name: "GST Amount",
      //       indexLabel: "{y}",
      //       showInLegend: true,
      //       dataPoints: totalGSTGraph,
      //     }
      //   : {},
    ],
  };

  const renderGraphMode = () => {
    return (
      <div className="p-1 cursor">
        <div className="d-flex mx-2 flex-wrap">
          <div className="col-md-6 p-1">
            <div className="boxshadow  p-2">
              {/* <CanvasJSChart options={options} /> */}
              <BarGraph label={xLabel1} value={Graphdata1} />
              <div className=" d-flex justify-content-center">
                <label className="label me-2">Revenue</label>
              </div>
            </div>
          </div>
          <div className="col-md-6 p-1">
            <div className="boxshadow  p-2">
              <BarGraph label={xLabel2} value={Graphdata2} />
              <div className=" d-flex justify-content-center">
                <label className="label me-2">Calling Time</label>
              </div>
            </div>{" "}
          </div>
          <div className="col-md-6 p-1">
            <div className="boxshadow  p-2">
              <BarGraph label={xLabel3} value={Graphdata3} />
              <div className=" d-flex justify-content-center">
                <label className="label me-2">Reference</label>
              </div>
            </div>
          </div>
          <div className="col-md-6 p-1">
            <div className="boxshadow  p-2">
              <BarGraph label={xLabel4} value={Graphdata4} />
              <div className=" d-flex justify-content-center">
                <label className="label me-2">Total Connected Calls</label>
              </div>
            </div>
          </div>
          <div className="col-md-6 p-1">
            <div className="boxshadow  p-2">
              <BarGraph label={xLabel5} value={Graphdata5} />
              <div className=" d-flex justify-content-center">
                <label className="label me-2">Unique Connected Calls</label>
              </div>
            </div>
          </div>
          <div className="col-md-6 p-1">
            <div className="boxshadow  p-2">
              <BarGraph label={xLabel6} value={Graphdata6} />
              <div className=" d-flex justify-content-center">
                <label className="label me-2">Old Connected Calls</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"HR Dashboard"} loggedInUserRole={loggedInUserRole} />
      <div className="col-md-12 row">
        <div className=" formDiv formWidth">
          <div className="d-flex">
            <div className="col-8">
              <div>
                <h5>
                  HELLO, <br />
                  {loggedInUserName}
                </h5>
                {/* <div className="mx-2 px-2 d-flex Justify-content-between">
                  <h4 className="col-md-3 text-primary fw-bold">Analysis</h4>
                  <div className="col-md-2 d-flex">
                    <div className="teargetbg px-4 my-3 me-3"></div>
                    <p className="mt-3 fw-bold ">Target</p>
                  </div>
                  <div className="col-md-2 d-flex">
                    <div className="unachievedbg px-4 my-3 me-3"></div>
                    <p className="mt-3 fw-bold ">Achieved</p>
                  </div>
                </div> */}
                {/* {renderGraphMode()} */}
              </div>
            </div>
            <div className="col-4">
              <div className="mx-0">
                <div className="card">
                  <div className="d-flex p-2">
                    <div className="col-md-4 me-3">
                      <LabelField lableName="Start Date" />
                      <div className="d-flex justify-content-start rounded fw-bold align-items-center">
                        <DatePicker
                          value={startDate}
                          setInputText={(value: string) => setStartDate(value)}
                          maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <LabelField lableName="End Date" />
                      <div className="d-flex justify-content-start rounded fw-bold align-items-center">
                        <DatePicker
                          value={endDate}
                          setInputText={(value: string) => setEndDate(value)}
                          maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 d-flex justify-content-center align-items-center">
                      <button
                        className="btn btn-sm btn-primary rounded-pill"
                        onClick={onChangeGetRevenue}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                  {/* <div>
                    <div className="d-flex justify-content-between align-items-center p-2">
                      <div className="col-md-4">
                        <h6 className="card-title">Total Revenue</h6>
                      </div>
                      <div className="col-md-6">
                        <div className="d-flex justify-content-center rounded border text-center fw-bold text-success fs-4 align-items-center">
                          {totalRevenue ? totalRevenue : 0}
                        </div>
                      </div>
                    </div>
                    {loggedInUserRole === 3 ? (
                      <div className="d-flex justify-content-between align-items-center p-2">
                        <div className="col-md-4">
                          <h6 className="card-title">Other Revenue</h6>
                        </div>
                        <div className="col-md-6">
                          <div className="d-flex justify-content-center rounded border text-center fw-bold text-success fs-4 align-items-center">
                            {otherRevenue ? otherRevenue : 0}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div className="d-flex justify-content-between card-footer">
                    <div>
                      <span className="fw-bold">Total Lead Count : </span>
                      <span className="fw-bold text-success">
                        {" "}
                        &nbsp;{totalLeadCount}
                      </span>
                    </div>
                    <div>
                      <span className="fw-bold">Total Reference Count : </span>
                      <span className="fw-bold text-success">
                        {" "}
                        &nbsp;{totalReferenceLeadCount}
                      </span>
                    </div>
                  </div> */}
                </div>
                <ol className="list-group list-group-numbered mt-2 border counsellorDashboardStatus">
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                    onClick={() => {
                      onChange("resumeShared");
                    }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Resume Shared</div>
                      {/* <span className="">
                        {((resumeShared / totalLeadCount) * 100).toFixed(2)}%
                      </span> */}
                    </div>
                    <span className="badge text-bg-primary rounded-pill fs-6">
                      {resumeShared}
                    </span>
                  </li>
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                    onClick={() => {
                      onChange("firstRoundInterview");
                    }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">First Round Interview</div>
                      {/* <span className="">
                        {((firstRoundInterview / totalLeadCount) * 100).toFixed(2)}%
                      </span> */}
                    </div>
                    <span className="badge text-bg-primary rounded-pill fs-6">
                      {firstRoundInterview}
                    </span>
                  </li>
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                    onClick={() => {
                      onChange("secondRoundInterview");
                    }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Second Round Interview</div>
                      {/* <span className="">
                        {((secondRoundInterview / totalLeadCount) * 100).toFixed(2)}%
                      </span> */}
                    </div>
                    <span className="badge text-bg-primary rounded-pill fs-6">
                      {secondRoundInterview}
                    </span>
                  </li>
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                    onClick={() => {
                      onChange("hrRoundInterview");
                    }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Hr Round Interview</div>
                      {/* <span className="">
                        {((hrRoundInterview / totalLeadCount) * 100).toFixed(2)}%
                      </span> */}
                    </div>
                    <span className="badge text-bg-primary rounded-pill fs-6">
                      {hrRoundInterview}
                    </span>
                  </li>
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                    onClick={() => {
                      onChange("rejected");
                    }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Rejected</div>
                      {/* <span className="">
                        {((rejected / totalLeadCount) * 100).toFixed(2)}%
                      </span> */}
                    </div>
                    <span className="badge text-bg-primary rounded-pill fs-6">
                      {rejected}
                    </span>
                  </li>
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                    onClick={() => {
                      onChange("joiningToday");
                    }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Joining Today</div>
                      {/* <span className="">
                        {((joiningToday / totalLeadCount) * 100).toFixed(2)}%
                      </span> */}
                    </div>
                    <span className="badge text-bg-primary rounded-pill fs-6">
                      {joiningToday}
                    </span>
                  </li>
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                    onClick={() => {
                      onChange("joiningInWeek");
                    }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Joining In Week</div>
                      {/* <span className="">
                        {((joiningInWeek / totalLeadCount) * 100).toFixed(2)}%
                      </span> */}
                    </div>
                    <span className="badge text-bg-primary rounded-pill fs-6">
                      {joiningInWeek}
                    </span>
                  </li>
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                    onClick={() => {
                      onChange("callingDone");
                    }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Calling Done</div>
                      {/* <span className="">
                        {((callingDone / totalLeadCount) * 100).toFixed(2)}%
                      </span> */}
                    </div>
                    <span className="badge text-bg-primary rounded-pill fs-6">
                      {callingDone}
                    </span>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
