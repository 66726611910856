import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InputField from "../../Component/inputField";
import LabelField from "../../Component/labelField";
import CustomDropdown from "../../Component/customDropdown";
import {
  getCountryList,
  getStatesList,
  referNewLead,
} from "../../Redux/Actions/counsellorPanelAction";
import {
  getHrEmployeeCompanyList,
  getHrEmployeeDepartmentList,
  getHrEmployeeDesignationList,
  getHrStatusList,
  getHrSubDepartment,
  getHrSubStatusList,
  uploadCVPDF,
  uploadPDF,
} from "../../Redux/Actions/hrPanelAction";
import DateTime from "../../Component/dateTime";
import SubmitButton from "../../Component/submitButton";
import {
  containsNumber,
  length_Ten,
  pdf_size_1MB,
  validateIsFilePDFType,
  validateValueWithoutSpace,
} from "../../DATA/validators";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import {
  validateIsFilePDFTypeErrMsg,
  validateIsNumberOnlyErrMsg,
} from "../../DATA/errorMsg";
import { getLeadByMobileNumber } from "../../Redux/Actions/managerPanelAction";
import { useSelector } from "react-redux";
import {
  qualificationList,
  qualificationYearList,
} from "../../DATA/dataConstant";
import moment from "moment";
import ImagePickerAndViewer from "../../Component/imagePickerAndViewer";
import { useAppDispatch } from "../../hooks";

export default function LeadDetail() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState<any>("");
  const [lastName, setLastName] = useState<any>("");
  const [contact1, setContact1] = useState<any>("");
  const [contact2, setContact2] = useState<any>("");
  const [email, setEmail] = useState<any>("");
  const [state, setState] = useState<any>("");
  const [city, setCity] = useState<any>("");
  const [address, setAddress] = useState<any>("");
  const [educationYear, setEducationYear] = useState<any>("");
  const [qualification, setQualification] = useState<any>("");
  const [currentCompany, setCurrentCompany] = useState<any>("");
  const [currentCTC, setCurrentCTC] = useState<any>("");
  const [experience, setExperience] = useState<any>("");
  const [selectedCV, setSelectedCV] = useState<any>("");
  const [pdfCV, setPdfCV] = useState<any>("");
  const [selectedAadharCard, setSelectedAadharCard] = useState<any>("");
  const [pdfAadharCard, setPdfAadharCard] = useState<any>("");
  const [selectedPanCard, setSelectedPanCard] = useState<any>("");
  const [pdfPanCard, setPdfPanCard] = useState<any>("");
  const [company, setCompany] = useState<any>("");
  const [department, setDepartment] = useState<any>("");
  const [subDepartment, setSubDepartment] = useState<any>("");
  const [designation, setDesignation] = useState<any>("");
  const [status, setStatus] = useState<any>("");
  const [subStatus, setSubStatus] = useState<any>("");
  const [remark, setRemark] = useState<any>("");
  const [callBackDate, setCallBackDate] = useState<any>("");
  const [firstInterviewDate, setFirstInterviewDate] = useState<any>("");
  const [secondInterviewDate, setSecondInterviewDate] = useState<any>("");
  const [hrRoundDate, setHrRoundDate] = useState<any>("");
  const [joiningDate, setJoiningDate] = useState<any>("");

  const [stateList, setStateList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [educationYearList, setEducationYearList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [subDepartmentList, setSubDepartmentList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [subStatusList, setSubStatusList] = useState([]);

  const [resetStatus, setResetStatus] = useState(false);
  const [resetStatusDetails, setResetStatusDetails] = useState(false);

  const { loggedInUserRole } = useSelector((state: any) => ({
    loggedInUserRole: state.Auth.loggedInUserRole,
  }));

  useEffect(() => {
    dispatch(
      getHrEmployeeCompanyList(
        (res: any) => {
          setCompanyList(res);
        },
        () => {}
      )
    );
    dispatch(
      getHrEmployeeDepartmentList(
        (res: any) => {
          setDepartmentList(res);
        },
        () => {}
      )
    );
    dispatch(
      getHrEmployeeDesignationList(
        (res: any) => {
          setDesignationList(res);
        },
        () => {}
      )
    );
    dispatch(
      getStatesList((res: any) => {
        if (res?.data?.length > 0) {
          setStateList(res?.data);
        } else {
          setStateList([]);
        }
      })
    );
    dispatch(
      getHrStatusList(
        "",
        (res: any) => {
          setStatusList(res);
        },
        () => {}
      )
    );
  }, []);

  const onChangeContact1 = (val: any) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setContact1(val);
        if (val?.length === 10) {
          const postData = {
            mobileNo: val,
          };
          dispatch(
            getLeadByMobileNumber(
              postData,
              (res: any) => {
                if (res?.length > 0) {
                  dispatch(showErrorSnackbar("Lead is Already Present") as any);
                }
              },
              () => {}
            )
          );
        }
      }
    } else {
      setContact1(val);
    }
  };

  const onChangeContact2 = (val: any) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setContact2(val);
        if (val?.length === 10) {
          const postData = {
            mobileNo: val,
          };
          dispatch(
            getLeadByMobileNumber(
              postData,
              (res: any) => {
                if (res?.length > 0) {
                  dispatch(showErrorSnackbar("Lead is Already Present") as any);
                }
              },
              () => {}
            )
          );
        }
      }
    } else {
      setContact2(val);
    }
  };

  const onChangeState = (val: any) => {
    setState(val);
    dispatch(
      getCountryList(
        val._id,
        (res: any) => {
          setCountryList(res?.data);
        },
        () => {}
      )
    );
  };

  const onChangeQualification = (val: any) => {
    setEducationYear("");
    setQualification(val);
    let tempQualification: any = qualificationYearList?.filter?.(
      (item: any) => item?.qualificationId === val?._id
    );
    setEducationYearList(tempQualification);
  };

  const onChangePDFCV = (e: any) => {
    e.preventDefault();
    if (!validateIsFilePDFType(e)) {
      dispatch(showErrorSnackbar(validateIsFilePDFTypeErrMsg));
      return;
    }
    if (e.target.files[0].size > pdf_size_1MB) {
      dispatch(
        showErrorSnackbar("File size should be less than 10 MB!!!") as any
      );
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setPdfCV(reader.result);
        setSelectedCV(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const onChangePDFAadharCard = (e: any) => {
    e.preventDefault();
    if (!validateIsFilePDFType(e)) {
      dispatch(showErrorSnackbar(validateIsFilePDFTypeErrMsg));
      return;
    }
    if (e.target.files[0].size > pdf_size_1MB) {
      dispatch(
        showErrorSnackbar("File size should be less than 10 MB!!!") as any
      );
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setPdfAadharCard(reader.result);
        setSelectedAadharCard(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const onChangePDFPanCard = (e: any) => {
    e.preventDefault();
    if (!validateIsFilePDFType(e)) {
      dispatch(showErrorSnackbar(validateIsFilePDFTypeErrMsg));
      return;
    }
    if (e.target.files[0].size > pdf_size_1MB) {
      dispatch(
        showErrorSnackbar("File size should be less than 10 MB!!!") as any
      );
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setPdfPanCard(reader.result);
        setSelectedPanCard(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const onChangeDepartment = (val: any) => {
    setSubDepartment(val);
    dispatch(
      getHrSubDepartment(
        val?._id,
        (res: any) => {
          setSubDepartmentList(res);
        },
        () => {}
      )
    );
  };

  const onChangeStatus = (val: any) => {
    setSubStatus("");
    setStatus(val);
    setResetStatus(false);
    setSubStatusList([]);
    dispatch(
      getHrSubStatusList(
        val?._id,
        (res: any) => {
          setSubStatusList(res);
        },
        () => {}
      )
    );
  };

  const onChangeSubStatus = (val: any) => {
    setSubStatus(val);
    setResetStatusDetails(false);
  };

  const changeRemark = (val: any) => {
    setRemark(val);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    let postData: any = {
      firstName: firstName?.toUpperCase(),
      lastName: lastName?.toUpperCase(),
      mobileNo: contact1,
      altMobileNo: contact2,
      email: email,
      stateId: state?._id,
      stateName: state?.name,
      cityId: city?._id,
      cityName: city?.name,
      address: address,
      educationYear: educationYear?._id,
      qualification: qualification?._id,
      currentCTC: currentCTC,
      currentCompany: currentCompany,
      company: company?._id,
      department: department?._id,
      subDepartment: subDepartment?._id,
      designation: designation?._id,
      leadStatusId: status?._id,
      leadSubstatusId: subStatus?._id,
      comment: remark,
      callBackDate: callBackDate,
      firstInterviewDate: firstInterviewDate,
      secondInterviewDate: secondInterviewDate,
      hrRoundCallBackDate: hrRoundDate,
      joiningDate: joiningDate,
    };
    if (selectedCV) {
      let reqBody = {
        firstName: firstName?.toUpperCase(),
        lastName: lastName?.toUpperCase(),
        designation: designation?._id,
        resume: selectedCV,
      };
      dispatch(
        uploadCVPDF(
          reqBody,
          (resPdfCV: any) => {
            if (resPdfCV) {
              postData["cv"] = resPdfCV?.file;
            }
          },
          () => {}
        )
      );
    } else if (selectedAadharCard) {
      dispatch(
        uploadPDF(
          selectedAadharCard,
          2,
          (resPddAadhar: any) => {
            if (resPddAadhar) {
              postData["aadharCard"] = resPddAadhar?.file;
            }
          },
          () => {}
        )
      );
    } else if (selectedPanCard) {
      dispatch(
        uploadPDF(
          selectedPanCard,
          3,
          (resPdfPan: any) => {
            if (resPdfPan) {
              postData["panCard"] = resPdfPan?.file;
            }
          },
          () => {}
        )
      );
    }

    if (selectedCV && !selectedAadharCard && !selectedPanCard) {
      dispatch(
        referNewLead(postData, (res: any) => {
          resetData();
        })
      );
    } else if (selectedCV && selectedAadharCard && !selectedPanCard) {
      dispatch(
        referNewLead(postData, (res: any) => {
          resetData();
        })
      );
    } else if (selectedCV && selectedAadharCard && selectedPanCard) {
      dispatch(
        referNewLead(postData, (res: any) => {
          resetData();
        })
      );
    } else {
      dispatch(
        referNewLead(postData, (res: any) => {
          resetData();
        })
      );
    }
  };

  const resetData = () => {
    setFirstName("");
    setLastName("");
    setContact1("");
    setContact2("");
    setEmail("");
    setState("");
    setCity("");
    setAddress("");
    setEducationYear("");
    setQualification("");
    setCurrentCTC("");
    setCurrentCompany("");
    setExperience("");
    setSelectedCV("");
    setPdfCV("");
    setSelectedAadharCard("");
    setPdfAadharCard("");
    setSelectedPanCard("");
    setPdfPanCard("");
    setDepartment("");
    setDesignation("");
    setStatus("");
    setSubStatus("");
    setRemark("");
    setCallBackDate("");
    setFirstInterviewDate("");
    setSecondInterviewDate("");
    setHrRoundDate("");
    setJoiningDate("");
    navigate("../hrDashbord");
  };

  return (
    <div className="contentBox">
      <div className="col-md-12">
        <div className="row m-0">
          <div className="col-md-12 leaddetails font14">
            <div className="boxshadow mx-4 my-1">
              <div className="p-1 d-flex justify-content-center  ">
                <form
                  onSubmit={handleSubmit}
                  className="detailsdiv col-md-12 row d-flex justify-content-center px-2 "
                >
                  <div className="col-md-6 col-sm-12 p-2">
                    <div className="tabheader p-2">Personal & Contact </div>
                    <div className="row d-flex justify-content-between pt-2 ">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="First Name" />
                      </div>
                      <div className="col-md-7">
                        <InputField
                          placeholder="Enter First Name"
                          value={firstName}
                          onChangeInput={(value: any) =>
                            setFirstName(value?.toUpperCase())
                          }
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between ">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Last Name" />
                      </div>
                      <div className="col-md-7">
                        <InputField
                          placeholder="Enter Last Name"
                          value={lastName}
                          onChangeInput={(value: any) =>
                            setLastName(value?.toUpperCase())
                          }
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between  ">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Contact 1" isRequired={true} />
                      </div>
                      <div className="col-md-7">
                        <InputField
                          placeholder="Enter Contact 1"
                          value={contact1}
                          onChangeInput={(value: any) =>
                            onChangeContact1(value)
                          }
                          minlength={length_Ten}
                          maxlength={length_Ten}
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between  ">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Contact 2" />
                      </div>
                      <div className="col-md-7">
                        <InputField
                          placeholder="Enter Contact 2"
                          value={contact2}
                          onChangeInput={(value: any) =>
                            onChangeContact2(value)
                          }
                          minlength={length_Ten}
                          maxlength={length_Ten}
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between  ">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Email" />
                      </div>
                      <div className="col-md-7">
                        <InputField
                          placeholder="Enter Email"
                          value={email}
                          onChangeInput={(value: any) => setEmail(value)}
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between mt-1">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="State" isRequired={true} />
                      </div>
                      <div className="col-md-7 ps-3">
                        <CustomDropdown
                          lableName="Select State"
                          setInputText={(value: any) => onChangeState(value)}
                          value={state}
                          options={stateList}
                          defaultValue={stateList?.length > 0 ? state : ""}
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between mt-1">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="City" />
                      </div>
                      <div className="col-md-7  ps-3">
                        <CustomDropdown
                          lableName="Select City"
                          setInputText={(value: any) => setCity(value)}
                          value={city}
                          options={countryList}
                          defaultValue={countryList?.length > 0 ? city : ""}
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between mt-1">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Address" />
                      </div>
                      <div className="col-md-7  ps-3">
                        <textarea
                          className="form-control"
                          placeholder={"Address"}
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 p-2">
                    <div className="tabheader p-2">Employment Details</div>
                    <div className="row d-flex justify-content-between  pt-3">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Company" />
                      </div>
                      <div className="col-md-7">
                        <CustomDropdown
                          lableName="Select Company"
                          setInputText={(value: any) => setCompany(value)}
                          value={company}
                          options={companyList}
                          defaultValue={
                            companyList?.length > 0 ? company?._id : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between  pt-3">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Department" />
                      </div>
                      <div className="col-md-7">
                        <CustomDropdown
                          lableName="Select Department"
                          setInputText={(value: any) =>
                            onChangeDepartment(value)
                          }
                          value={department}
                          options={departmentList}
                          defaultValue={
                            departmentList?.length > 0 ? department?._id : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between  pt-3">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Sub Department" />
                      </div>
                      <div className="col-md-7">
                        <CustomDropdown
                          lableName="Select Sub Department"
                          setInputText={(value: any) => setSubDepartment(value)}
                          value={subDepartment}
                          options={subDepartmentList}
                          defaultValue={
                            subDepartmentList?.length > 0
                              ? subDepartment?._id
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between  pt-3">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Designation" />
                      </div>
                      <div className="col-md-7">
                        <CustomDropdown
                          lableName="Select Designation"
                          setInputText={(value: any) => setDesignation(value)}
                          value={designation}
                          options={designationList}
                          defaultValue={
                            designationList?.length > 0 ? designation?._id : ""
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 p-2">
                    <div className="tabheader p-2">Education & Work</div>
                    <div className="row d-flex justify-content-between pt-2 ">
                      <div className="col-md-4 mt-2">
                        <LabelField
                          lableName="Qualification"
                          isRequired={true}
                        />
                      </div>
                      <div className="col-md-7">
                        <CustomDropdown
                          lableName="Select Qualification"
                          setInputText={(value: any) =>
                            onChangeQualification(value)
                          }
                          value={qualification}
                          options={qualificationList}
                          defaultValue={
                            qualificationList?.length > 0 ? qualification : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between  ">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Education Year" />
                      </div>
                      <div className="col-md-7">
                        <CustomDropdown
                          lableName="Select Education Year"
                          setInputText={(value: any) => setEducationYear(value)}
                          value={educationYear}
                          options={educationYearList}
                          defaultValue={
                            educationYearList?.length > 0 ? educationYear : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between ">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Current Company" />
                      </div>
                      <div className="col-md-7">
                        <InputField
                          placeholder="Enter Current Company"
                          value={currentCompany}
                          onChangeInput={(value: any) =>
                            setCurrentCompany(value)
                          }
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between ">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Current CTC" />
                      </div>
                      <div className="col-md-7">
                        <InputField
                          placeholder="Enter Current CTC"
                          value={currentCTC}
                          onChangeInput={(value: any) => setCurrentCTC(value)}
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between ">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Experience" />
                      </div>
                      <div className="col-md-7">
                        <InputField
                          placeholder="Enter Experience"
                          value={experience}
                          onChangeInput={(value: any) => setExperience(value)}
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="row d-flex justify-content-between ">
                        <div className="mt-2">
                          <LabelField lableName="Upload CV" />
                          <ImagePickerAndViewer
                            lableName={"Upload PDF"}
                            value={selectedCV}
                            doctype={"pdf"}
                            removelabel={true}
                            htmlFor={`pdf-upload`}
                            handleChange={(e: any) => onChangePDFCV(e)}
                          />
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between ">
                        <div className="mt-2">
                          <LabelField lableName="Upload Aadhar Card" />
                          <ImagePickerAndViewer
                            lableName={"Upload PDF"}
                            value={selectedAadharCard}
                            doctype={"pdf"}
                            removelabel={true}
                            htmlFor={`pdf-upload-aadhar`}
                            handleChange={(e: any) => onChangePDFAadharCard(e)}
                          />
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between ">
                        <div className="mt-2">
                          <LabelField lableName="Upload Pan Card" />
                          <ImagePickerAndViewer
                            lableName={"Upload PDF"}
                            value={selectedPanCard}
                            doctype={"pdf"}
                            removelabel={true}
                            htmlFor={`pdf-upload-pan`}
                            handleChange={(e: any) => onChangePDFPanCard(e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 p-2">
                    <div className="tabheader p-2">Action & Disposition</div>
                    <div className="row d-flex justify-content-between pt-2 ">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Status" isRequired={true} />
                      </div>
                      <div className="col-md-7">
                        <CustomDropdown
                          lableName="Select Status"
                          setInputText={(value: any) => onChangeStatus(value)}
                          value={status}
                          options={statusList}
                          defaultValue={statusList?.length > 0 ? status : ""}
                          reset={resetStatus}
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between mt-1">
                      <div className="col-md-4 mt-2">
                        <LabelField
                          lableName="Status Details"
                          isRequired={true}
                        />
                      </div>
                      <div className="col-md-7">
                        <CustomDropdown
                          lableName="Select Status Details"
                          setInputText={(value: any) =>
                            onChangeSubStatus(value)
                          }
                          value={subStatus}
                          options={subStatusList}
                          defaultValue={
                            subStatusList?.length > 0 ? subStatus : ""
                          }
                          reset={resetStatusDetails}
                        />
                      </div>
                    </div>

                    <div className="row d-flex justify-content-between p-2">
                      <textarea
                        className="form-control"
                        placeholder={"Remark"}
                        value={remark}
                        onChange={(e) => changeRemark(e.target.value)}
                      ></textarea>
                    </div>
                    {status?._id === 1 ? (
                      <div className="row d-flex justify-content-between  ">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="Call Back Date" />
                        </div>
                        <div className="col-md-7">
                          <DateTime
                            value={callBackDate}
                            setInputText={(value: any) =>
                              setCallBackDate(value)
                            }
                            defaultValue={callBackDate}
                          />
                        </div>
                      </div>
                    ) : null}

                    {status?._id === 2 ? (
                      <div className="row d-flex justify-content-between mt-2">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="1st Interview Date" />
                        </div>
                        <div className="col-md-7">
                          <DateTime
                            value={firstInterviewDate}
                            setInputText={(value: any) =>
                              setFirstInterviewDate(value)
                            }
                            defaultValue={firstInterviewDate}
                          />
                        </div>
                      </div>
                    ) : null}

                    {status?._id === 3 ? (
                      <div className="row d-flex justify-content-between mt-2">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="2nd Interview Date" />
                        </div>
                        <div className="col-md-7">
                          <DateTime
                            value={secondInterviewDate}
                            setInputText={(value: any) =>
                              setSecondInterviewDate(value)
                            }
                            defaultValue={secondInterviewDate}
                          />
                        </div>
                      </div>
                    ) : null}

                    {status?._id === 4 ? (
                      <div className="row d-flex justify-content-between mt-2">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="Hr Interview Date" />
                        </div>
                        <div className="col-md-7">
                          <DateTime
                            value={hrRoundDate}
                            setInputText={(value: any) => setHrRoundDate(value)}
                            defaultValue={hrRoundDate}
                          />
                        </div>
                      </div>
                    ) : null}

                    {status?._id === 9 ? (
                      <div className="row d-flex justify-content-between mt-2">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="Joining Date" />
                        </div>
                        <div className="col-md-7">
                          <DateTime
                            value={joiningDate}
                            setInputText={(value: any) => setJoiningDate(value)}
                            defaultValue={joiningDate}
                          />
                        </div>
                      </div>
                    ) : null}

                    <div className=" d-flex justify-content-end mt-2 ">
                      <SubmitButton name={"Save"} />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
