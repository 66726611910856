import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../Component/pageTitle";
import LabelField from "../../Component/labelField";
import CustomDropdown from "../../Component/customDropdown";
import { Pagination } from "@mui/material";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import usePagination from "../../Component/usePagination";
import { useSelector } from "react-redux";
import {
  getAttendanceReqForManager,
  getCounsellorListByManagerId,
} from "../../Redux/Actions/managerPanelAction";
import { getSMManagerList } from "../../Redux/Actions/superManagerAction";

export default function SAAttendance() {
  const dispatch = useDispatch();
  const [manager, setManager] = useState<any>("");
  const [counsellor, setCounsellor] = useState<any>("");

  const [managerList, setManagerList] = useState<any>([]);
  const [counsellorList, setCounsellorList] = useState<any>([]);

  const [resetCounsellor, setResetCounsellor] = useState<any>(false);
  const [attendanceReqList, setAttendanceReqList] = useState<any>([]);

  const { loggedInUserId } = useSelector((state: any) => ({
    loggedInUserRole: state.Auth.loggedInUserRole,
    loggedInUserId: state.Auth.loggedInUserId,
  }));

  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  useEffect(() => {
    dispatch(
      getSMManagerList(
        loggedInUserId,
        (res: any) => {
          setManagerList(res);
        },
        () => {}
      )
    );
    const data: any = {
      skip: 0,
      limit: rowsPerPage,
    };
    if (manager) {
      data["managerId"] = manager?._id;
    }
    if (counsellor) {
      data["counsellorId"] = counsellor?._id;
    }
    displayAttendance(data);
  }, []);

  const onChangeManager = (val: any) => {
    setManager(val);
    setResetCounsellor(true);
    setCounsellorList([]);
    dispatch(
      getCounsellorListByManagerId(
        val?._id,
        (res: any) => {
          setCounsellorList(res?.data);
        },
        () => {}
      )
    );
  };

  const onChangeCounsellor = (val: any) => {
    setCounsellor(val);
  };

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data: any = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        managerId: manager?._id,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        managerId: manager?._id,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        managerId: manager?._id,
      };
    }
    if (counsellor) {
      data["counsellorId"] = counsellor?._id;
    }
    displayAttendance(data);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    const data: any = {
      skip: 0,
      limit: rowsPerPage,
      managerId: manager?._id,
    };
    if (counsellor) {
      data["counsellorId"] = counsellor?._id;
    }
    displayAttendance(data);
  };

  const displayAttendance = (postData: any) => {
    dispatch(
      getAttendanceReqForManager(
        postData,
        (res: any) => {
          setAttendanceReqList(res?.data?.attendanceWithLoginDetails);
          setCount(res?.data?.count);
        },
        () => {}
      )
    );
  };

  const renderSearchMode = () => {
    return (
      <>
        <form className="row d-flex" onSubmit={handleSearch}>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Manager" />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <CustomDropdown
                lableName="Select Manager"
                setInputText={(value: any) => onChangeManager(value)}
                value={manager}
                options={managerList}
                Isrequired={true}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Counsellor" />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <CustomDropdown
                lableName="Select Counsellor"
                setInputText={(value: any) => onChangeCounsellor(value)}
                value={counsellor}
                options={counsellorList}
                reset={resetCounsellor}
              />
            </div>
            <button className="btn btn-primary rounded-pill mb-3">
              Search
            </button>
          </div>
        </form>
      </>
    );
  };

  const renderListMode = () => {
    return (
      <>
        {attendanceReqList?.length > 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}

        {count > 0 ? (
          <PaginationView
            pagename={"attendanceList"}
            count={count}
            tableData={attendanceReqList ? attendanceReqList : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            // rowsPerPageOptions={getTableDataSlots(count)}
          />
        ) : null}

        <PaginationTable
          tableData={attendanceReqList ? attendanceReqList : []}
          pagename={"attendanceList"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={["S.N", "Name", "Login Time", "Logout Time", "Status"]}
        />
      </>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Attendance"} />
      <div className="col-md-12 row">
        <div className="formDiv formWidth p-4">
          {renderSearchMode()}
          {renderListMode()}
        </div>
      </div>
    </div>
  );
}
