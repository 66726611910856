import services from "../../API/service";
import endpoint from "../../API/endpoint";
import { showSuccessSnackbar } from "./snackbarAction";
import { showErrorSnackbar } from "./snackbarAction";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { SET, UPDATE } from "../Types/leadManagementTypes";
import { updateCommonValues } from "./CommonAction";

export const setParentCourse =
  (key: string, value: any) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch({
      type: SET,
      payload: {
        [key]: value,
      },
    });
  };

export const modifyParentCourse =
  (key: string, value: any) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch({
      type: UPDATE,
      payload: {
        [key]: value,
      },
    });
  };

//   **************************************

export const getDailyMeetingForManager =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.SuperManager.getDailyMeetingForManager,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getSMManagerList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.SuperManager.getSMManagerList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          let tempList = res?.data?.data?.filter((item: any) => {
            return (
              item?._id !== "65a22be897fd8bb1374641f3" &&
              item?._id !== "66e3e7c58c6bded69e3f3f51"
            );
          });
          callback(tempList);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getRevenue =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.SuperManagerDashbord.getRevenue,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getTalkTimeData =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.SuperManagerDashbord.getTalkTimeData,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getSMTeamLeadOverview =
  (id: any, params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.SuperManagerTeamAnalysis.getSMTeamLeadOverview + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getSMCouresAnalysis =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.SuperManagerTeamAnalysis.getSMCouresAnalysis,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getSMReferenceAnalysis =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.SuperManagerTeamAnalysis.getSMReferenceAnalysis,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getSMReferenceAnalysisByID =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.SuperManagerTeamAnalysis.getSMReferenceAnalysisByID,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getSMLeadAnalysis =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.SuperManagerTeamAnalysis.getSMLeadAnalysis,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getSMEnrolledAnalysis =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.SuperManagerTeamAnalysis.getSMEnrolledAnalysis,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getManagerwiseAllocatedRevenue =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.SuperManager.getManagerwiseAllocatedRevenue,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getManagerwiseOtherRevenue =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.SuperManager.getManagerwiseOtherRevenue,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getManagerwisePublicationRevenue =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.SuperManager.getManagerwisePublicationRevenue,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const geCounsellorRevenueTargetByRole =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.SuperManager.geCounsellorRevenueTargetByRole,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getAllocatedRevenueTargetByExams =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.SuperManager.getAllocatedRevenueTargetByExams,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getOtherRevenueTargetByExams =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.SuperManager.getOtherRevenueTargetByExams,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getPublicationRevenueTargetByExams =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.SuperManager.getPublicationRevenueTargetByExams,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getAllocatedRevenueTargetExamsByCounsellor =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.SuperManager.getAllocatedRevenueTargetExamsByCounsellor,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getOtherRevenueTargetExamsByCounsellor =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.SuperManager.getOtherRevenueTargetExamsByCounsellor,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getPublicationRevenueTargetExamsByCounsellor =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.SuperManager.getPublicationRevenueTargetExamsByCounsellor,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getManagerwiseRevenueTarget =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.SuperManager.getManagerwiseRevenueTarget,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getTeamwiseAllocatedRevenueTargetByManagerId =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.SuperManager.getTeamwiseAllocatedRevenueTargetByManagerId,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getTeamwiseOtherRevenueTargetByManagerId =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.SuperManager.getTeamwiseOtherRevenueTargetByManagerId,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getTeamwisePublicationRevenueTargetByManagerId =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.SuperManager.getTeamwisePublicationRevenueTargetByManagerId,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getTeamwLeadwiseRevenueTarget =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.SuperManager.getTeamwLeadwiseRevenueTarget,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const geCounsellorwiseRevenueTargetByTeamId =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.SuperManager.geCounsellorwiseRevenueTargetByTeamId,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const geCounsellorwiseRevenueTarget =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.SuperManager.geCounsellorwiseRevenueTarget,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const geCounsellorwiseRevenueTargetByManagerId =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.SuperManager.geCounsellorwiseRevenueTargetByManagerId,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getEdutechTransaction =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postAdminApiCall(
      endpoint.SuperManager.getEdutechTransaction,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };
export const getStudyMaterialTransaction =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postAdminApiCall(
      endpoint.SuperManager.getStudyMaterialTransaction,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const sourceAnalysis =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postAdminApiCall(
      endpoint.SuperManager.sourceAnalysis,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const stateWiseAnalysis =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postAdminApiCall(
      endpoint.SuperManager.stateWiseAnalysis,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getExamWiseAnalysis =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postAdminApiCall(
      endpoint.SuperManager.getExamWiseAnalysis,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getBatchWiseCount =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postAdminApiCall(
      endpoint.SuperManager.getBatchWiseCount,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getBatchwiseAdmissionDetails =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getAdminApiCall(
      endpoint.SuperManager.getBatchwiseAdmissionDetails,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getDateWiseCount =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postAdminApiCall(
      endpoint.SuperManager.getDateWiseCount,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getAssuredRevenue =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postAdminApiCall(
      endpoint.SuperManager.getAssuredRevenue,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getRegisteredUserList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postAdminApiCall(
      endpoint.SuperManager.getRegisteredUserList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getStateStudentCount =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postAdminApiCall(
      endpoint.SuperManager.getStateStudentCount,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getStudentCount =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postAdminApiCall(
      endpoint.SuperManager.getStudentCount,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getWebAppLeads =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postAdminApiCall(
      endpoint.SuperManager.getWebAppLeads,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getStateLeadCount =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postAdminApiCall(
      endpoint.SuperManager.getStateLeadCount,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getBranchList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getAdminApiCall(
      endpoint.SuperManager.getBranchList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getBatchListFilter =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postAdminApiCall(
      endpoint.SuperManager.getBatchListFilter,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getClassroomRegisteredList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postAdminApiCall(
      endpoint.SuperManager.getClassroomRegisteredList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getExpertSupportList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postAdminApiCall(
      endpoint.SuperManager.getExpertSupportList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getLeadCountSource =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postAdminApiCall(
      endpoint.SuperManager.getLeadCountSource,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getReferenceGenerated =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postAdminApiCall(
      endpoint.SuperManager.getReferenceGenerated,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const salesPerformance =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postAdminApiCall(
      endpoint.SuperManager.salesPerformance,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const geCounsellorwiseRevenue =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.SuperManager.geCounsellorwiseRevenue,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };
