import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import moment from "moment";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import ReplyIcon from "@mui/icons-material/Reply";
import { useLocation, useNavigate } from "react-router-dom";
import {
  geCounsellorwiseRevenueTarget,
  geCounsellorwiseRevenueTargetByManagerId,
} from "../../Redux/Actions/superManagerAction";
import { updateCommonValues } from "../../Redux/Actions/CommonAction";
import { useAppDispatch } from "../../hooks";

export default function SalesDirectorCounsellor() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [startDate, setStartDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [totalData, setTotalData] = useState<any>([]);
  const [counsellorList, setCounsellorList] = useState<any>([]);

  const pageName = location.state?.pageName;

  const { loggedInUserRole, loggedInUserId, loggedInUserName, sDate, eDate } =
    useSelector((state: any) => ({
      loggedInUserRole: state.Auth.loggedInUserRole,
      loggedInUserId: state.Auth.loggedInUserId,
      loggedInUserName: state.Auth.loggedInUserName,
      sDate: state.Auth.startDate,
      eDate: state.Auth.endDate,
    }));

  useEffect(() => {
    if (pageName !== "salesDirectorCounsellorTarget") {
      dispatch(updateCommonValues("startDate", ""));
      dispatch(updateCommonValues("endDate", ""));
      dispatch(updateCommonValues("startDate", startDate));
      dispatch(updateCommonValues("endDate", endDate));
    }
    displayCounsellorwiseRevenue();
  }, []);

  const displayCounsellorwiseRevenue = () => {
    if (loggedInUserRole === 1) {
      dispatch(
        geCounsellorwiseRevenueTargetByManagerId(
          {
            startDate,
            endDate,
            managerId: loggedInUserId,
          },
          (res: any) => {
            let tempRes = res?.sort((a: any, b: any) => {
              return b.percent - a.percent;
            });
            const tempTotalData = calculateTotalData(tempRes);
            setTotalData(tempTotalData);
            setCounsellorList(tempRes);
          },
          () => {}
        )
      );
    } else {
      dispatch(
        geCounsellorwiseRevenueTarget(
          {
            startDate,
            endDate,
          },
          (res: any) => {
            let tempRes = res?.sort((a: any, b: any) => {
              return b.percent - a.percent;
            });
            const tempTotalData = calculateTotalData(tempRes);
            setTotalData(tempTotalData);
            setCounsellorList(tempRes);
          },
          () => {}
        )
      );
    }
  };

  const calculateTotalData = (items: any) => {
    return items.reduce(
      (acc: any, item: any) => {
        acc.totalNewLeadCount += item.newLeads || 0;
        acc.totalRevenue += item.achievedRevenue || 0;
        acc.totalAchievedReference += item.achievedReferenceLeadCount || 0;
        acc.totalTargetReference += item.referenceleadTargetCount || 0;
        acc.totalCounsellor += 1 || 0;
        acc.totalPresent += item?.attendanceStatus === 1 ? 1 : 0;
        acc.totalAbsent += item?.attendanceStatus === 0 ? 1 : 0;
        return acc;
      },
      {
        totalNewLeadCount: 0,
        totalRevenue: 0,
        totalAchievedReference: 0,
        totalTargetReference: 0,
        totalCounsellor: 0,
        totalPresent: 0,
        totalAbsent: 0,
      }
    );
  };

  const counsellorDashboardListStatusStyle = {
    maxHeight: "calc(100vh - 165px)",
    overflow: "auto",
  };

  const counsellorDashboardListTableStyle = {
    lineHeight: "12px",
  };

  const counsellorDashboardStatusStyle = {
    maxHeight: "calc(100vh - 210px)",
    overflow: "auto",
  };

  const handleOnClick = (value: any) => {
    dispatch(updateCommonValues("startDate", startDate));
    dispatch(updateCommonValues("endDate", endDate));
    navigate("../salesDirectorCounsellorTarget", {
      state: {
        counsellorData: value,
        isView: true,
      },
    });
  };

  const renderListMode = () => {
    return (
      <div className="mt-2 ">
        <div className="d-flex">
          <div className="col-9">
            <div
              className="row mx-0 shadow-sm rounded py-2"
              style={counsellorDashboardListStatusStyle}
            >
              {counsellorList?.map((item: any) => {
                return (
                  <div
                    className="col-sm-6 mb-3 cursor"
                    onClick={() => handleOnClick(item)}
                  >
                    <div className="card h-100 shadow-sm">
                      <div className="p-2">
                        <div>
                          <div className="d-flex justify-content-between py-2">
                            <div className="ms-2 me-auto">
                              <span className="fw-bold">New Lead:</span>{" "}
                              <span className="text-success">
                                {item?.newLeads}
                              </span>
                            </div>
                            <div className="ms-2 me-auto">
                              <span className="fw-bold">Calling:</span>{" "}
                              <span className="">0</span> /{" "}
                              <span className="fw-bold">180</span>
                            </div>
                          </div>
                          <table className="w-100">
                            <thead>
                              <tr style={counsellorDashboardListTableStyle}>
                                <th></th>
                                <th>Target</th>
                                <th>Achieved</th>
                                <th>Percentage</th>
                                <th>Deficit</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr style={counsellorDashboardListTableStyle}>
                                <td>Revenue</td>
                                <td>{item?.targetRevenue}</td>
                                <td>{item?.achievedRevenue}</td>
                                <td className="text-success">
                                  {item?.percent}%
                                </td>
                                <td className="text-danger">{item?.deficit}</td>
                              </tr>
                              <tr style={counsellorDashboardListTableStyle}>
                                <td>Reference</td>
                                <td>{item?.referenceleadTargetCount}</td>
                                <td>{item?.achievedReferenceLeadCount}</td>
                                <td className="text-success">
                                  {item?.referenceLeadPercent}%
                                </td>
                                <td className="text-danger">
                                  {item?.referenceLeadDeficit}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          {/* <div className="d-flex justify-content-between py-2">
                            <div className="ms-2 me-auto">
                              <span className="fw-bold">Target:</span>{" "}
                              <span className="fw-bold text-danger">
                                {item?.achievedRevenue}
                              </span>{" "}
                              /{" "}
                              <span className="fw-bold">
                                {item?.targetRevenue}
                              </span>
                            </div>
                            <div className="ms-2 me-auto">
                              <span className="text-danger">
                                {item?.deficit}
                              </span>
                            </div>
                            <div className="ms-2 me-0 text-end">
                              <span className="text-success">
                                {item?.percent}%
                              </span>
                            </div>
                          </div> */}
                          {/* <div className="d-flex justify-content-between py-2">
                            <div className="ms-2 me-auto">
                              <span className="fw-bold">Reference:</span>{" "}
                              <span className="fw-bold text-danger">
                                {item?.achievedReferenceLeadCount}
                              </span>{" "}
                              /{" "}
                              <span className="fw-bold">
                                {" "}
                                {item?.referenceleadTargetCount}
                              </span>
                            </div>
                            <div className="ms-2 me-auto">
                              <span className="text-danger">
                                {item?.referenceLeadDeficit}
                              </span>
                            </div>
                            <div className="ms-2 me-0 text-end">
                              <span className="text-success">
                                {item?.referenceLeadPercent}%
                              </span>
                            </div>
                          </div> */}
                        </div>
                        <hr className="my-2" />
                        <div>
                          <div className="d-flex justify-content-between">
                            <div className="ms-2 me-auto">
                              <span className="fw-bold">
                                {item?.counsellorName}
                              </span>
                              <span className="font10">
                                {" ("}
                                {item?.teamLeadName}{" "}
                                <span className="text-info">(TL)</span>
                              </span>{" "}
                              /{" "}
                              <span className="font10">
                                {item?.managerName}{" "}
                                <span className="text-success">(M)</span>
                                {")"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-3 p-2 shadow-sm rounded">
            <h4 className="fw-bold text-center">Rank</h4>
            <ol
              className="list-group mt-2"
              style={counsellorDashboardStatusStyle}
            >
              {counsellorList?.map((item: any, index: number) => {
                return (
                  <li className="list-group-item d-flex justify-content-between shadow-sm rounded p-2 cursor mb-1">
                    <div className="d-flex me-auto">
                      <div
                        className="d-flex rounded-circle justify-content-center align-items-center me-2 text-white"
                        style={{
                          width: "25px",
                          height: "25px",
                          backgroundColor:
                            item?.attendanceStatus === 1
                              ? "#2eb509"
                              : "#F23E36",
                        }}
                      >
                        {index + 1}
                      </div>
                      <div className="fw-bold">{item?.counsellorName}</div>
                    </div>
                    <span className="rounded-pill fs-6">{item?.percent}%</span>
                  </li>
                );
              })}
            </ol>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="contentBox">
      <div className="row">
        <div>
          <div className="d-flex justify-content-between shadow-sm p-2 rounded">
            <div>
              <div className="d-flex">
                <h3 className="fw-bold text-primary">Counsellor</h3>
              </div>
            </div>
            <div>
              <div className="d-flex">
                <div className="col-2 me-2">
                  <span>New Leads</span>
                  <div className="d-flex justify-content-start rounded fw-bold align-items-center">
                    {totalData?.totalNewLeadCount ?? 0}
                  </div>
                </div>
                <div className="col-2 me-2">
                  <span>Revenue</span>
                  <div className="d-flex justify-content-start rounded fw-bold align-items-center">
                    {totalData?.totalRevenue?.toFixed(2) ?? 0}
                  </div>
                </div>
                <div className="col-2 me-2">
                  <span>Reference</span>
                  <div className="d-flex justify-content-start rounded fw-bold align-items-center">
                    {totalData?.totalAchievedReference ?? 0} /{" "}
                    {totalData?.totalTargetReference ?? 0}
                  </div>
                </div>
                <div className="col-2 me-2">
                  <span>Total Counsellor</span>
                  <div className="d-flex justify-content-start rounded fw-bold align-items-center">
                    {totalData?.totalCounsellor ?? 0}
                  </div>
                </div>
                <div className="col-2 me-2">
                  <span>Total Present</span>
                  <div className="d-flex justify-content-start rounded fw-bold align-items-center">
                    {totalData?.totalPresent ?? 0}
                  </div>
                </div>
                <div className="col-2 me-2">
                  <span>Total Absent</span>
                  <div className="d-flex justify-content-start rounded fw-bold align-items-center">
                    {totalData?.totalAbsent ?? 0}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="card">
                <div className="d-flex justify-content-between p-2">
                  <div className="col-md-4 me-4">
                    <LabelField lableName="Start Date" />
                    <div className="d-flex justify-content-start rounded fw-bold align-items-center">
                      <DatePicker
                        value={startDate}
                        setInputText={(value: string) => setStartDate(value)}
                        maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 ms-1">
                    <LabelField lableName="End Date" />
                    <div className="d-flex justify-content-start rounded fw-bold align-items-center">
                      <DatePicker
                        value={endDate}
                        setInputText={(value: string) => setEndDate(value)}
                        maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 d-flex justify-content-center align-items-center">
                    <button
                      className="btn btn-sm btn-primary rounded-pill"
                      onClick={displayCounsellorwiseRevenue}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {renderListMode()}
        </div>
      </div>
    </div>
  );
}
