import ReplyIcon from "@mui/icons-material/Reply";
import { useNavigate } from "react-router-dom";
interface props {
  name: string;
  isEditMode?: boolean;
  isGoBack?: boolean;
  setisEditMode?: any;
  onClick?: any;
  newIssueCount?: any,
  loggedInUserRole?: any
}

export default function PageTitle({
  name,
  isEditMode,
  setisEditMode,
  isGoBack,
  onClick,
  newIssueCount,
  loggedInUserRole,
}: props) {
  const navigate = useNavigate();
  const onRouteToCourseSupport = () => {
    navigate("../coursesupport");
  }
  return (
    <div className="d-flex Justify-content-between">
      <h5 className="Title">{name}
        {loggedInUserRole === 5 || loggedInUserRole === 8 ?
          <button type="button" className="btn btn-primary submitbtn rounded-pill me-2" onClick={onRouteToCourseSupport}> New Enquiry <span className="rounded-pill text-white px-3 float-end mx-2" style={{ backgroundColor: "rgb(63, 81, 181)" }}>{newIssueCount?.length}</span></button>
          : ''
        }
      </h5>
      {isGoBack ? (
        <div className="w-5">
          {/* <button className="btn btn-outline-primary mb-2" onClick={onClick}>
            Go Back
          </button> */}
          <button
            type="button"
            className="btn btn-light"
            // onClick={() => navigate(-1)}
            onClick={() => onClick()}
          >
            <ReplyIcon /> Back
          </button>
        </div>
      ) : null}
    </div>
  );
}
