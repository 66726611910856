import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../Component/pageTitle";
import InputField from "../../Component/inputField";
import LabelField from "../../Component/labelField";
import CustomDropdown from "../../Component/customDropdown";
import {
  getExamList,
  getExamStreamList,
} from "../../Redux/Actions/counsellorPanelAction";
import {
  monthData,
  ResultOtherDetails,
  ScoreCardList,
  yearData,
} from "../../DATA/dataConstant";
import ImagePickerAndViewer from "../../Component/imagePickerAndViewer";
import {
  containsNumber,
  image_size_5MB,
  length_Ten,
  pdf_size_1MB,
  validateIsFileImageType,
  validateIsFilePDFType,
  validateValueWithoutSpace,
  video_size_25MB,
} from "../../DATA/validators";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../Redux/Actions/snackbarAction";
import {
  validateIsFileImageTypeErrMsg,
  validateIsFilePDFTypeErrMsg,
  validateIsNumberOnlyErrMsg,
} from "../../DATA/errorMsg";
import SubmitButton from "../../Component/submitButton";
import {
  addResulCompilation,
  getCasteCategoryList,
  getVrifyStudentNumber,
  uploadResultImage,
} from "../../Redux/Actions/bdeAdminPanelAction";
import { useSelector } from "react-redux";

export default function ResultCompilation() {
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState<any>("");
  const [lastName, setLastName] = useState<any>("");
  const [mob, setMob] = useState<any>("");
  const [exam, setExam] = useState<any>("");
  const [examStream, setExamStream] = useState<any>("");
  const [examList, setExamList] = useState([]);
  const [examStreamList, setExamStreamList] = useState([]);
  const [details, setDetails] = useState<any>("");
  const [rank, setRank] = useState<any>("");
  const [photo, setPhoto] = useState<any>("");
  const [category, setCategory] = useState<any>("");
  const [mobNumber, setMobNumber] = useState<any>("");
  const [email, setEmail] = useState<any>("");
  const [qualifyYear, setQualifyYear] = useState<any>({
    _id: 1,
    value: 2024,
    name: "2024",
  });
  const [qualifyMonth, setQualifyMonth] = useState<any>({
    _id: 7,
    name: "July",
  });
  const [qualifyFor, setQualifyFor] = useState<any>("");
  const [rollNumber, setRollNumber] = useState<any>("");
  const [rollNoError, setRollNoError] = useState<any>("");
  const [studentName, setStudentName] = useState<any>("");
  const [studentList, setStudentList] = useState([]);
  const [studentdata, setStudentdata] = useState<any>("");
  const [categoryList, setCategoryList] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [IsCheck, setIsCheck] = useState(false);
  const [resetParentCourse, setResetParentCourse] = useState(false);
  const [resetExam, setResetExam] = useState(false);
  const [resetQualifyYear, setResetQualifyYear] = useState(false);
  const [resetQualifyMonth, setResetQualifyMonth] = useState(false);
  const [resetQualifyFor, setResetQualifyFor] = useState(false);
  const [resetCategory, setResetCategory] = useState(false);
  const [doctype, setDoctype] = useState<any>("");
  const [selectedPDF, setSelectedPDF] = useState<any>("");
  const [resultPDF, setResultPDF] = useState<any>("");
  const [type, setType] = useState<any>("");
  const [resetType, setResetType] = useState(false);
  const [scoreCardImg, setScoreCardImg] = useState<any>("");
  const [selectedScoreCardImg, setSelectedScoreCardImg] = useState<any>("");
  const [scoreCardImgResult, setScoreCardImgResult] = useState<any>("");
  const [scoreCardPdfResult, setScoreCardPdfResult] = useState<any>("");

  const { loggedInUserId } = useSelector((state: any) => ({
    loggedInUserId: state.Auth.loggedInUserId,
  }));

  useEffect(() => {
    dispatch(
      getExamList((res: any) => {
        if (res?.data?.length > 0) {
          setExamList(res?.data);
        } else {
          setExamList([]);
        }
      })
    );
    dispatch(
      getCasteCategoryList(
        "",
        (res: any) => {
          setCategoryList(res?.data);
        },
        () => {}
      )
    );
  }, []);

  const onClickverify = (val: any) => {
    setIsCheck(true);
    dispatch(
      getVrifyStudentNumber(
        mobNumber,
        (res: any) => {
          setStudentdata(res?.data);
          dispatch(showSuccessSnackbar(res?.msg) as any);
          setFirstName(res?.data?.firstName);
          setLastName(res?.data?.lastName);
          setEmail(res?.data?.email);
          setExam(res?.data?.examTypes?._id);
          setExamStream(res?.data?.basecourses?._id);
          dispatch(
            getExamStreamList(`${res?.data?.examTypes?._id}`, (res: any) => {
              setExamStreamList(res);
            })
          );
        },
        () => {}
      )
    );
  };

  const onChangeExam = (val: any) => {
    setExam(val);

    dispatch(
      getExamStreamList(`${val._id}`, (res: any) => {
        setExamStreamList(res);
      })
    );
  };
  const onChnangeMobileNo = (val: any) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setMobNumber(val);
      }
    } else {
      setMobNumber(val);
    }
  };
  const onChangeExamStream = (val: string) => {
    setExamStream(val);
  };

  const onChangeQualifyYear = (val: string) => {
    setQualifyYear(val);
    setResetQualifyYear(false);
    setResetQualifyMonth(true);
  };

  const onChangeQualifyMonth = (val: string) => {
    setQualifyMonth(val);
    setResetQualifyMonth(false);
  };

  const onChangeQualifyFor = (val: string) => {
    setQualifyFor(val);
  };

  const onChangeRollNumber = (val: any) => {
    setRollNumber(val);
    const regex = /^[A-Za-z]{2}\d{7}$/;
    if (regex.test(rollNumber)) {
      setRollNoError("");
    } else {
      setRollNoError("Please enter a valid Roll Number");
    }
  };

  const onChangeRank = (val: any) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setRank(val);
      }
    } else {
      setRank(val);
    }
  };

  const handleOptionImageChange = (e: any) => {
    setDoctype("img");
    e.preventDefault();
    if (!validateIsFileImageType(e)) {
      dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg) as any);
      return;
    }
    if (e.target.files[0].size > image_size_5MB) {
      dispatch(
        showErrorSnackbar("File size should be less than 150 KB!!!") as any
      );
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setPhoto(reader.result);
        setSelectedImage(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleOptionScoreCardImageChange = (e: any) => {
    e.preventDefault();
    if (!validateIsFileImageType(e)) {
      dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg) as any);
      return;
    }
    if (e.target.files[0].size > video_size_25MB) {
      dispatch(
        showErrorSnackbar("File size should be less than 25 MB!!!") as any
      );
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setScoreCardImg(reader.result);
        setSelectedScoreCardImg(file);
      };
      reader.readAsDataURL(file);
      dispatch(
        uploadResultImage(
          file,
          rollNumber,
          (res: any) => {
            setScoreCardImgResult(res?.fullPath);
          },
          () => {}
        )
      );
    }
  };

  const handlePDFChange = (e: any) => {
    e.preventDefault();
    if (!validateIsFilePDFType(e)) {
      dispatch(showErrorSnackbar(validateIsFilePDFTypeErrMsg) as any);
      return;
    }
    if (e.target.files[0].size > video_size_25MB) {
      dispatch(
        showErrorSnackbar("File size should be less than 25 MB!!!") as any
      );
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setResultPDF(reader.result);
        setSelectedPDF(file);
      };
      reader.readAsDataURL(file);
      dispatch(
        uploadResultImage(
          file,
          rollNumber,
          (res: any) => {
            setScoreCardPdfResult(res?.fullPath);
          },
          () => {}
        )
      );
    }
  };

  const onChangesetCategory = (val: string) => {
    setCategory(val);
  };

  const onChangeType = (val: any) => {
    setType(val?._id);
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(
      uploadResultImage(
        selectedImage,
        rollNumber,
        (res: any) => {
          if (res) {
            const postData = {
              mobileNo: mobNumber,
              firstName: firstName,
              lastName: lastName,
              email: email,
              examTypeId: exam?._id,
              baseCourseId: examStream?._id,
              qualifyYear: qualifyYear?.name,
              qualifyMonth: qualifyMonth?._id,
              qualifyFor: qualifyFor?.name,
              catagory: category?._id,
              rollNumber: rollNumber,
              rank: rank,
              imageLink: res?.fullPath,
              counsellorId: loggedInUserId,
              scoreCard: type === 1 ? scoreCardImgResult : scoreCardPdfResult,
            };
            if (
              postData?.scoreCard === "" ||
              postData?.scoreCard === undefined ||
              postData?.scoreCard === null
            ) {
              delete postData?.scoreCard;
            }
            dispatch(
              addResulCompilation(
                postData,
                (res: any) => {
                  setMobNumber("");
                  setFirstName("");
                  setLastName("");
                  setEmail("");
                  setResetExam(true);
                  setResetParentCourse(true);
                  setResetQualifyYear(true);
                  setResetQualifyFor(true);
                  setResetCategory(true);
                  setResetType(true);
                  setRollNumber("");
                  setRank("");
                  setPhoto("");
                  setScoreCardImg("");
                  setResultPDF("");
                },
                () => {}
              )
            );
          }
        },
        (error: any) => {}
      )
    );
  };

  const renderSearchMode = () => {
    return (
      <div className=" ms-5 p-2 formDiv">
        <form onSubmit={handleSubmit}>
          <div className="d-flex">
            <div className="col-md-4 d-flex  mt-3">
              <div className="col-md-3 mt-2">
                <LabelField lableName="Mobile Number" />
              </div>
              <div className="col-md-9 ms-2 pe-2">
                <InputField
                  placeholder="Enter Mobile Number"
                  value={mobNumber}
                  maxlength={length_Ten}
                  minlength={length_Ten}
                  onChangeInput={(value: any) => onChnangeMobileNo(value)}
                  isRequired={true}
                />
              </div>
            </div>
            <div className="d-flex col-md-6 mb-3">
              <button
                type="submit"
                className="btn btn-outline-primary searchButton ms-2 mt-3"
                onClick={onClickverify}
              >
                Verify Number
              </button>
            </div>
          </div>

          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="First Name" />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <InputField
                placeholder="Enter First Name"
                value={firstName}
                onChangeInput={(value: any) => setFirstName(value)}
                isRequired={true}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Last Name" />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <InputField
                placeholder="Enter Last Name"
                value={lastName}
                onChangeInput={(value: any) => setLastName(value)}
                isRequired={true}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Email Address " />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <InputField
                placeholder="Enter Email Address"
                value={email}
                onChangeInput={(value: any) => setEmail(value)}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Exam Name" />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <CustomDropdown
                lableName="Select Exam"
                setInputText={(value: any) => onChangeExam(value)}
                value={exam}
                options={examList}
                defaultValue={examList?.length > 0 ? exam : null}
                reset={resetExam}
                Isrequired={true}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Exam Stream" />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <CustomDropdown
                lableName="Select Exam Stream"
                setInputText={(value: any) => onChangeExamStream(value)}
                value={examStream}
                defaultValue={examStreamList?.length > 0 ? examStream : null}
                options={examStreamList}
                reset={resetParentCourse}
                Isrequired={true}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Qualify Year" />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <CustomDropdown
                lableName="Select Qualify Year"
                setInputText={(value: any) => onChangeQualifyYear(value)}
                value={qualifyYear}
                reset={resetQualifyYear}
                options={yearData}
                Isrequired={true}
                defaultValue={yearData?.length > 0 ? qualifyYear?._id : ""}
                disabled
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Qualify Month" />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <CustomDropdown
                lableName="Select Qualify Month"
                setInputText={(value: any) => onChangeQualifyMonth(value)}
                value={qualifyMonth}
                reset={resetQualifyMonth}
                options={monthData}
                Isrequired={true}
                defaultValue={monthData?.length > 0 ? qualifyMonth?._id : ""}
                disabled
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Qualified for" />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <CustomDropdown
                lableName="Select Qualified for"
                setInputText={(value: any) => onChangeQualifyFor(value)}
                value={qualifyFor}
                reset={resetQualifyFor}
                options={ResultOtherDetails}
                Isrequired={true}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Category" />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <CustomDropdown
                lableName="Select Category"
                setInputText={(value: any) => onChangesetCategory(value)}
                value={category}
                options={categoryList}
                reset={resetCategory}
                Isrequired={true}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Roll Number" />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <InputField
                placeholder="Enter Roll Number"
                value={rollNumber}
                onChangeInput={(value: any) => onChangeRollNumber(value)}
                isRequired={true}
                maxlength={10}
              />
              {rollNoError && (
                <p className="text-danger fw-bold">{rollNoError}</p>
              )}
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Rank" />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <InputField
                placeholder="Enter Rank"
                value={rank}
                onChangeInput={(value: any) => onChangeRank(value)}
                isRequired={true}
              />
            </div>
          </div>

          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName={"Photo"} />
            </div>

            <div className="col-md-9 ms-2 pe-2">
              <ImagePickerAndViewer
                lableName={"Upload Photo"}
                value={photo}
                handleChange={(e: any) => handleOptionImageChange(e)}
                isRequired={true}
              />
            </div>
          </div>

          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="ScoreCard Type" />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <CustomDropdown
                lableName="Select ScoreCard Type"
                setInputText={(value: any) => onChangeType(value)}
                value={type}
                options={ScoreCardList}
                reset={resetType}
              />
            </div>
          </div>

          {type === 1 ? (
            <div className="col-md-4 d-flex mb-3">
              <div className="col-md-3 mt-2">
                <LabelField lableName={"ScoreCard Image"} />
              </div>

              <div className="col-md-9 ms-2 pe-2">
                <ImagePickerAndViewer
                  lableName={"Upload Photo"}
                  value={scoreCardImg}
                  handleChange={(e: any) => handleOptionScoreCardImageChange(e)}
                />
              </div>
            </div>
          ) : type === 2 ? (
            <div className="col-md-4 d-flex mb-3">
              <div className="col-md-3 mt-2">
                <LabelField lableName={"ScoreCard PDF"} />
              </div>
              <div className="col-md-9 ms-2 pe-2">
                <ImagePickerAndViewer
                  lableName={"Upload PDF"}
                  value={resultPDF}
                  handleChange={(e: any) => handlePDFChange(e)}
                  htmlFor={"pdf-upload"}
                  doctype={"pdf"}
                  // selectedPDF={selectedPDF}
                />
              </div>
            </div>
          ) : null}

          <div className=" d-flex mb-3 ms-5">
            <SubmitButton name={"Submit"} />
          </div>
        </form>
      </div>
    );
  };

  return (
    <div className="contentBox ">
      <PageTitle name={"RESULT COMPILATION"} />
      <div className="  p-3 formDiv">
        <div className="">
          <div className="row d-flex w-100">
            <div className="px-3 col-12 mt-3">
              <div></div>
            </div>
            {renderSearchMode()}
          </div>
        </div>
      </div>
    </div>
  );
}
