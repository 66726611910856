/* eslint-disable react/prop-types */
import React from "react";
import { Link, useLocation } from "react-router-dom";

interface props {
  data: any;
  title: any;
  keyval: any;
}
function SidebarModule({ data, title, keyval }: props) {
  const currentURLarr = useLocation().pathname.split("/");
  let link = currentURLarr[currentURLarr.length - 1];

  return (
    <div className="accordion subAccordion" id={"accordion" + keyval}>
      {data.map((val: any, key: any) => {
        if (val.subNav === undefined) {
          return (
            <li
              key={key}
              id={key}
              className={
                val.path === link || key === 0
                  ? "sideListRow active text-capitalize"
                  : "sideListRow text-capitalize"
              }
            >
              <Link to={val.path}>{val.title.toLowerCase()}</Link>
              <span></span>
            </li>
          );
        } else {
          return (
            <div className="accordion-item rounded-0 border-0 " key={key}>
              <h2
                className="accordion-header "
                id={"SubMenuheading" + +keyval + key}
              >
                <button
                  className="accordion-button collapsed text-capitalize"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={"#SubMenucollapse" + keyval + key}
                  aria-expanded="false"
                  aria-controls={"SubMenucollapse" + keyval + key}
                >
                  {val.title.toLowerCase()}
                </button>
              </h2>
              <div
                id={"SubMenucollapse" + keyval + key}
                className={`accordion-collapse collapse ${
                  key === 0 ? "show" : ""
                }`}
                aria-labelledby={"SubMenuheading" + keyval + key}
                data-bs-parent={"#accordion" + keyval}
              >
                <div className="accordion-body p-0">
                  {val.subNav.map((data: any, index: any) => {
                    return (
                      <li
                        key={index}
                        id={index}
                        className={
                          data.path === link || index === 0
                            ? "sideListRow active text-capitalize"
                            : "sideListRow text-capitalize"
                        }
                      >
                        <Link to={data.path}>{data.title.toLowerCase()}</Link>
                      </li>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        }
      })}
    </div>
  );
}

export default SidebarModule;
