import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PageTitle from "../../Component/pageTitle";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import usePagination from "../../Component/usePagination";
import { useSelector } from "react-redux";
import {
  getStatesList,
  leadAnalysisToday,
} from "../../Redux/Actions/counsellorPanelAction";
import CustomDropdown from "../../Component/customDropdown";
import LabelField from "../../Component/labelField";
import { getTableDataSlots, sortLeadScoreList } from "../../DATA/dataConstant";
import { updateCommonValues } from "../../Redux/Actions/CommonAction";
import { useAppDispatch } from "../../hooks";
export default function CallingDone() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { loggedInUserId, selectedPage, selectedRowsPerPage } = useSelector(
    (state: any) => ({
      loggedInUserId: state.Auth.loggedInUserId,
      selectedPage: state.Auth.selectedPage,
      selectedRowsPerPage: state.Auth.selectedRowsPerPage,
    })
  );

  const pageName = location.state?.pageName;

  const [sortLeadScore, setSortLeadScore] = useState<any>(sortLeadScoreList[0]);
  const [state, setState] = useState<any>("");

  const [stateList, setStateList] = useState<any>([]);
  const [leadsList, setLeadsList] = useState<any>([]);

  const [resetState, setResetState] = useState<any>("");
  const [resetSortLeadScore, setResetSortLeadScore] = useState<any>(false);

  // pagination
  const [count, setCount] = useState<any>(0);
  const [page, setPage] = useState<any>(0);
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  useEffect(() => {
    if (pageName !== "leadDetail") {
      dispatch(updateCommonValues("selectedLeadId", ""));
      dispatch(updateCommonValues("selectedPage", ""));
      dispatch(updateCommonValues("selectedRowsPerPage", ""));
    }
    const postData: any = {
      skip: 0,
      limit: rowsPerPage,
      status: 7,
      sort: sortLeadScore?.value,
      counsellorId: loggedInUserId,
    };
    if (pageName === "leadDetail") {
      setRowsPerPage(selectedRowsPerPage);
      setPage(selectedPage);
      setCurrentPage(selectedPage + 1);
      postData["skip"] = selectedPage * selectedRowsPerPage;
      postData["limit"] = selectedRowsPerPage;
    }
    setCount(0);
    setLeadsList([]);
    dispatch(
      leadAnalysisToday(
        postData,
        (res: any) => {
          setCount(res?.count);
          setLeadsList(res?.data);
        },
        () => {}
      )
    );
    dispatch(
      getStatesList((res: any) => {
        if (res?.data?.length > 0) {
          setStateList(res?.data);
        } else {
          setStateList([]);
        }
      })
    );
  }, []);

  const onChangeLeadScore = (val: any) => {
    setSortLeadScore(val);
    setResetSortLeadScore(false);
    let postData: any = {
      skip: 0,
      limit: rowsPerPage,
      status: 7,
      counsellorId: loggedInUserId,
      sort: val?.value,
    };
    if (state) {
      postData["stateId"] = state?._id;
    }
    dispatch(
      leadAnalysisToday(
        postData,
        (res: any) => {
          setCount(res?.count);
          setLeadsList(res?.data);
        },
        () => {}
      )
    );
  };

  const onChangeState = (val: any) => {
    setState(val);
    setResetState(false);
    let postData: any = {
      skip: 0,
      limit: rowsPerPage,
      status: 7,
      counsellorId: loggedInUserId,
      sort: sortLeadScore?.value,
      stateId: val?._id,
    };
    dispatch(
      leadAnalysisToday(
        postData,
        (res: any) => {
          setCount(res?.count);
          setLeadsList(res?.data);
        },
        () => {}
      )
    );
  };

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data: any = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        counsellorId: loggedInUserId,
        status: 7,
        sort: sortLeadScore?.value,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        counsellorId: loggedInUserId,
        status: 7,
        sort: sortLeadScore?.value,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        counsellorId: loggedInUserId,
        status: 7,
        sort: sortLeadScore?.value,
      };
    }

    if (state) {
      data["stateId"] = state?._id;
    }

    dispatch(
      leadAnalysisToday(
        data,
        (res: any) => {
          setCount(res?.count);
          setLeadsList(res?.data);
        },
        () => {}
      )
    );
  };
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const onClickLead = (data: any) => {
    dispatch(updateCommonValues("selectedLeadId", data?._id));
    dispatch(updateCommonValues("selectedPage", page));
    dispatch(updateCommonValues("selectedRowsPerPage", rowsPerPage));
    navigate("/hrLeadDetail", {
      state: {
        pageName: "callingDone",
      },
    });
  };
  const renderListMode = () => {
    return (
      <>
        <div>
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
          <PaginationView
            count={count}
            tableData={leadsList ? leadsList : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          />
          <div>
            <PaginationTable
              tableData={leadsList ? leadsList : []}
              pagename={"callingDone"}
              rowsPerPage={rowsPerPage}
              page={page}
              tableHead={[
                "S.N",
                "First Name",
                "Last Name",
                "State",
                "Resume",
                "Department",
                "Designation",
                "Candiate Registered Date",
              ]}
              edit={false}
              handleClick={(value: any) => {
                onClickLead(value);
              }}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Calling Done"} />
      <div className="row m-2">
        <div className="col-md-12 formDiv formWidth">
          <div className="d-flex">
            {/* <div className="col-md-3 d-flex mb-3 me-5">
              <div className="col-md-4 mt-2">
                <LabelField lableName="Lead Score" />
              </div>
              <div className="col-md-8 ms-2 pe-2">
                <CustomDropdown
                  lableName="Select Lead Score"
                  setInputText={(value: any) => onChangeLeadScore(value)}
                  value={sortLeadScore}
                  options={sortLeadScoreList}
                  reset={resetSortLeadScore}
                  defaultValue={
                    sortLeadScoreList?.length > 0 ? sortLeadScore?._id : ""
                  }
                />
              </div>
            </div> */}
            <div className="col-md-3 d-flex mb-3">
              <div className="col-md-4 mt-2">
                <LabelField lableName="State" />
              </div>
              <div className="col-md-8 ms-2 pe-2">
                <CustomDropdown
                  lableName="Select State"
                  setInputText={(value: any) => onChangeState(value)}
                  value={state}
                  options={stateList}
                  reset={resetState}
                />
              </div>
            </div>
          </div>
          {renderListMode()}
        </div>
      </div>
    </div>
  );
}
