import { useNavigate } from "react-router-dom";
import localImages from "../Assets/localImages";
import { useAppDispatch } from "../hooks";
import { CrmLogout } from "../Redux/Actions/AuthAction";
import { showSuccessSnackbar } from "../Redux/Actions/snackbarAction";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ReplyIcon from "@mui/icons-material/Reply";
import CallIcon from "@mui/icons-material/Call";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { updateCommonValues } from "../Redux/Actions/CommonAction";

function LeadDetailHeader(props: any) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleBack = () => {
    dispatch(updateCommonValues("ozonetelIframe", false));
    if (props?.pageName === "newOpportunity") {
      navigate("../CrmCounsellorPanel/newOpportunity", {
        state: {
          pageName: "leadDetail",
        },
      });
    } else if (props?.pageName === "reEnquired") {
      navigate("../CrmCounsellorPanel/reEnquired", {
        state: {
          pageName: "leadDetail",
        },
      });
    } else if (props?.pageName === "enrolledLead") {
      navigate("../CrmCounsellorPanel/enrolledLead", {
        state: {
          pageName: "leadDetail",
        },
      });
    } else if (props?.pageName === "notIntrestedLead") {
      navigate("../CrmCounsellorPanel/notIntrestedLead", {
        state: {
          pageName: "leadDetail",
        },
      });
    } else if (props?.pageName === "expired") {
      navigate("../CrmCounsellorPanel/expired", {
        state: {
          pageName: "leadDetail",
        },
      });
    } else if (props?.pageName === "cnrLead") {
      navigate("../CrmCounsellorPanel/cnrLead", {
        state: {
          pageName: "leadDetail",
        },
      });
    } else if (props?.pageName === "prospectLead") {
      navigate("../CrmCounsellorPanel/prospectLead", {
        state: {
          pageName: "leadDetail",
        },
      });
    } else if (props?.pageName === "interested") {
      navigate("../CrmCounsellorPanel/interested", {
        state: {
          pageName: "leadDetail",
        },
      });
    } else if (props?.pageName === "callBackLead") {
      navigate("../CrmCounsellorPanel/callBackLead", {
        state: {
          pageName: "leadDetail",
        },
      });
    } else if (props?.pageName === "overdueProspect") {
      navigate("../CrmCounsellorPanel/overdueProspect", {
        state: {
          pageName: "leadDetail",
        },
      });
    } else if (props?.pageName === "overdueInterested") {
      navigate("../CrmCounsellorPanel/overdueInterested", {
        state: {
          pageName: "leadDetail",
        },
      });
    } else if (props?.pageName === "overdueCallback") {
      navigate("../CrmCounsellorPanel/overdueCallback", {
        state: {
          pageName: "leadDetail",
        },
      });
    } else if (props?.pageName === "overdueCNR") {
      navigate("../CrmCounsellorPanel/overdueCNR", {
        state: {
          pageName: "leadDetail",
        },
      });
    } else if (props?.pageName === "callList") {
      navigate("../CrmCounsellorPanel/callList", {
        state: {
          pageName: "leadDetail",
        },
      });
    } else if (props?.pageName === "IncomingCallList") {
      navigate("../CrmCounsellorPanel/incomingCall", {
        state: {
          pageName: "leadDetail",
        },
      });
    } else if (props?.pageName === "counsellorDashbord") {
      navigate("../CrmCounsellorPanel/counsellorDashbord", {
        state: {
          pageName: "leadDetail",
        },
      });
    } else if (props?.pageName === "leadInvalid") {
      navigate("../CrmCounsellorPanel/leadInvalid", {
        state: {
          pageName: "leadDetail",
        },
      });
    } else if (props?.pageName === "alreadyAdmissionDone") {
      navigate("../CrmCounsellorPanel/alreadyAdmissionDone", {
        state: {
          pageName: "leadDetail",
        },
      });
    } else if (props?.pageName === "finalCNR") {
      navigate("../CrmCounsellorPanel/finalCNR", {
        state: {
          pageName: "leadDetail",
        },
      });
    } else if (props?.pageName === "welcomeCallDone") {
      navigate("../CrmCounsellorPanel/welcomeCallDone", {
        state: {
          pageName: "leadDetail",
        },
      });
    } else if (props?.pageName === "welcomeCallReference") {
      navigate("../CrmCounsellorPanel/welcomeCallReference", {
        state: {
          pageName: "leadDetail",
        },
      });
    } else if (props?.pageName === "followUp") {
      navigate("../CrmCounsellorPanel/followUp", {
        state: {
          pageName: "leadDetail",
        },
      });
    } else if (props?.pageName === "interestedForUpselling") {
      navigate("../CrmCounsellorPanel/interestedForUpselling", {
        state: {
          pageName: "leadDetail",
        },
      });
    } else if (props?.pageName === "prospectForUpselling") {
      navigate("../CrmCounsellorPanel/prospectForUpselling", {
        state: {
          pageName: "leadDetail",
        },
      });
    } else if (props?.pageName === "technicalIssue") {
      navigate("../CrmCounsellorPanel/technicalIssue", {
        state: {
          pageName: "leadDetail",
        },
      });
    } else if (props?.pageName === "leadSearch") {
      navigate("../CrmCounsellorPanel/leadSearchByMobileNumber", {
        state: {
          pageName: "leadDetail",
        },
      });
    } else if (props?.pageName === "summary") {
      navigate("../CrmCounsellorPanel/summary", {
        state: {
          pageName: "leadDetail",
          status: props?.leadStatus,
          state: props?.leadState,
          startDate: props?.leadStartDate,
          endDate: props?.leadEndDate,
        },
      });
    } else if (props?.pageName === "leadAssignedStatusOverview") {
      navigate("../CrmCounsellorPanel/leadAssignedStatusOverview", {
        state: {
          pageName: "leadDetail",
          status: props?.leadStatus?._id,
          type: props?.leadType?._id,
          startDate: props?.leadStartDate,
          endDate: props?.leadEndDate,
        },
      });
    } else if (props?.pageName === "userActivityDetailsList") {
      navigate("../CrmCounsellorPanel/userActivityDetails", {
        state: {
          pageName: "leadDetail",
          leadMobileNo: props?.leadMobileNo,
        },
      });
    } else if (props?.pageName === "referenceGeneratedList") {
      navigate("../CrmCounsellorPanel/referenceLeadsList", {
        state: {
          pageName: "leadDetail",
          type: props?.leadType,
        },
      });
    } else if (props?.pageName === "callRejectToday") {
      navigate("../CrmCounsellorPanel/callRejectToday", {
        state: {
          pageName: "leadDetail",
        },
      });
    } else if (props?.pageName === "overdueCallReject") {
      navigate("../CrmCounsellorPanel/overdueCallReject", {
        state: {
          pageName: "leadDetail",
        },
      });
    } else if (props?.pageName === "resumeShared") {
      navigate("../Panel/HrPanel/resumeShared", {
        state: {
          pageName: "hrLeadDetail",
        },
      });
    } else if (props?.pageName === "firstRoundInterview") {
      navigate("../Panel/HrPanel/firstRoundInterview", {
        state: {
          pageName: "hrLeadDetail",
        },
      });
    } else if (props?.pageName === "secondRoundInterview") {
      navigate("../Panel/HrPanel/secondRoundInterview", {
        state: {
          pageName: "hrLeadDetail",
        },
      });
    } else if (props?.pageName === "hrRoundInterview") {
      navigate("../Panel/HrPanel/hrRoundInterview", {
        state: {
          pageName: "hrLeadDetail",
        },
      });
    } else if (props?.pageName === "rejected") {
      navigate("../Panel/HrPanel/rejected", {
        state: {
          pageName: "hrLeadDetail",
        },
      });
    } else if (props?.pageName === "joiningToday") {
      navigate("../Panel/HrPanel/joiningToday", {
        state: {
          pageName: "hrLeadDetail",
        },
      });
    } else if (props?.pageName === "joiningInWeek") {
      navigate("../Panel/HrPanel/joiningInWeek", {
        state: {
          pageName: "hrLeadDetail",
        },
      });
    } else if (props?.pageName === "callingDone") {
      navigate("../Panel/HrPanel/callingDone", {
        state: {
          pageName: "hrLeadDetail",
        },
      });
    }
  };

  const SignOut = () => {
    dispatch(
      CrmLogout(
        (res: any) => {
          dispatch(showSuccessSnackbar("Logout Succesfully"));
          navigate("/");
        },
        () => {}
      )
    );
  };
  return (
    <header className={window.location.pathname === "/login " ? "hide" : ""}>
      <div className="d-flex justify-content-between w-100">
        <div className=" ">
          <button
            type="button"
            className="btn btn-light"
            // onClick={() => navigate(-1)}
            onClick={() => handleBack()}
          >
            <ReplyIcon /> Back
          </button>
          {/* <img
            src={localImages.logo}
            className="logo d-none d-xl-inline-block"
            alt="Logo"
          /> */}
        </div>
        {props?.page === "LeadDetails" ? (
          <div className="px-3  pt-2 d-flex align-items-center">
            {props?.enrolledData[0]?.courses?.length > 0 ? (
              <>
                <h5 className="fw-bold me-2 font14"> Enrolled Course :</h5>
                <div className="pt-1 me-3">
                  <p className="">
                    {props?.enrolledData[0]?.courses[0]?.examTypeName}
                  </p>
                </div>
                <div className="pt-1 me-3">
                  <p className="">
                    {props?.enrolledData[0]?.courses[0]?.baseCourseName}
                  </p>
                </div>
                <div className="pt-1 me-3">
                  <p className="">
                    {props?.enrolledData[0]?.courses[0]?.courseName}
                  </p>
                </div>
                {/* <div className="pt-1 me-3">
              <p className="">End Date : {moment(props?.enrolledData[0]?.endDate).utc().format("DD-MM-YYYY")}</p>
            </div> */}
              </>
            ) : null}

            {props.qualifiedExamArr[0]?.examTypeId !== undefined ? (
              <>
                <h5 className="fw-bold me-2 font14">
                  {" "}
                  Already Qualified Exam :
                </h5>
                {props?.qualifiedExamArr?.map((item: any) => {
                  return (
                    <div className="pt-1 me-3">
                      <p className="">{item?.examTypeId?.name}</p>
                    </div>
                  );
                })}
              </>
            ) : null}
          </div>
        ) : null}
        {props?.page === "LeadDetails" ? (
          <div className="d-flex justify-content-end  align-items-center">
            <div className="d-flex justify-content-end">
              {props?.leadRank ? (
                <div className="border border-secondary rounded p-1 me-2 d-flex align-items-center">
                  <p className="text-primary fw-bold font14 mb-0 px-2">
                    Class Rank:{" "}
                    <span className="text-black ">{props?.leadRank} </span>{" "}
                  </p>
                </div>
              ) : null}

              <button
                type="button"
                className="btn btn-success me-2 font12"
                onClick={props?.handleCall}
              >
                {" "}
                <span>
                  <CallIcon className="font12" />
                </span>
                <span className="d-none d-xl-inline-block ms-2">Call Now</span>
              </button>
              <button
                type="button"
                className="btn btn-success me-2  font12"
                onClick={props?.handleWhatsapp}
              >
                {" "}
                <span>
                  <WhatsAppIcon className="font14" />
                </span>
                <span className="d-none d-xl-inline-block ms-2">WhatsApp</span>
              </button>

              <div className="border border-secondary rounded p-1 me-2 d-flex align-items-center">
                <p className="text-primary fw-bold font14 mb-0 px-2">
                  Lead Score:{" "}
                  <span className="text-black ">{props?.leadScore} </span>{" "}
                </p>
              </div>
            </div>
          </div>
        ) : null}
        {props?.page === "hrLeadDetail" ? (
          <div className="d-flex align-items-center mx-5">
            <div className="d-flex mx-5">
              <button
                type="button"
                className="btn btn-success font12 mx-5"
                onClick={props?.handleCall}
              >
                {" "}
                <span>
                  <CallIcon className="font12" />
                </span>
                <span className="d-none d-xl-inline-block ms-2">Call Now</span>
              </button>
            </div>
          </div>
        ) : null}
        {props?.page === "DepartmentDetails" ? (
          <div className="px-3  pt-2 d-flex align-items-center">
            <h5 className="fw-bold me-2 font14"> College :</h5>
            <div className="pt-1 me-3">
              <p className="">
                {" "}
                Dr. D. Y. Patil Vidyapeeth (DPU), Pune ,Maharashtra
              </p>
            </div>
          </div>
        ) : null}
        {props?.page === "DepartmentDetails" ? (
          <div className="d-flex justify-content-end  align-items-center">
            <div className="d-flex justify-content-end">
              <button type="button" className="btn btn-success me-2 font12">
                {" "}
                <span>
                  <CallIcon className="font12" />
                </span>
                <span className="d-none d-xl-inline-block ms-2">Call Now</span>
              </button>
              <button type="button" className="btn btn-success me-2  font12">
                {" "}
                <span>
                  <WhatsAppIcon className="font14" />
                </span>
                <span className="d-none d-xl-inline-block ms-2">WhatsApp</span>
              </button>

              <div className="border border-secondary rounded p-1 me-2 d-flex align-items-center">
                <p className="text-primary fw-bold font14 mb-0 px-2">
                  Lead Score: <span className="text-black "> </span>{" "}
                </p>
              </div>
            </div>
          </div>
        ) : null}
        {/* <div className="">
          <button
            type="button"
            className="btn btn-light border font14"
            onClick={SignOut}
          >
            Sign Out
          </button>
        </div> */}
      </div>
    </header>
  );
}

export default LeadDetailHeader;
