import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import moment from "moment";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import ReplyIcon from "@mui/icons-material/Reply";
import { useLocation, useNavigate } from "react-router-dom";
import { geCounsellorwiseRevenue } from "../../Redux/Actions/superManagerAction";

export default function SalesDirectorCounsellor() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { loggedInUserRole, loggedInUserId, loggedInUserName, sDate, eDate } =
    useSelector((state: any) => ({
      loggedInUserRole: state.Auth.loggedInUserRole,
      loggedInUserId: state.Auth.loggedInUserId,
      loggedInUserName: state.Auth.loggedInUserName,
      sDate: state.Auth.startDate,
      eDate: state.Auth.endDate,
    }));

  const [startDate, setStartDate] = useState<any>(sDate);
  const [endDate, setEndDate] = useState<any>(eDate);

  const [ravenueData, setRevenueData] = useState<any>([]);
  const [outboundList, setOutboundList] = useState<any>([]);

  const counsellor = location.state?.counsellorData;
  const isViewMode = location.state?.isView;

  useEffect(() => {
    dispatch(
      geCounsellorwiseRevenue(
        {
          startDate,
          endDate,
          counsellorId: counsellor?._id,
        },
        (res: any) => {
          setRevenueData(res);
        },
        () => {}
      )
    );
  }, []);

  const calculateTotals = (items: any) => {
    return items.reduce(
      (acc: any, item: any) => {
        acc.totalTarget += item.targetRevenue || 0;
        acc.totalAchievedRevenue += item.achievedRevenue || 0;
        acc.totalAchievedReference += item.achievedLeadCount || 0;
        acc.totalTargetReference += item.referenceleadTargetCount || 0;
        return acc;
      },
      {
        totalTarget: 0,
        totalAchievedRevenue: 0,
        totalAchievedReference: 0,
        totalTargetReference: 0,
      }
    );
  };

  const counsellorDashboardStatusStyle = {
    maxHeight: "calc(100vh - 208px)",
    overflow: "auto",
  };

  const handleOnClick = () => {
    // navigate("../leadAssignedDateAnalysis");
  };

  const renderListMode = () => {
    return (
      <div className="mt-2">
        <div className="row">
          <div className="boxshadow rounded pb-5">
            <div className="mt-2">
              <h4 className="fw-bold px-2">Lead Overview</h4>
              <div className="row d-flex flex-wrap">
                <div style={{ maxWidth: "25%" }}>
                  <div className="d-flex justify-content-center p-2 rounded m-2 border boxshadow">
                    <div className="text-center px-5" onClick={handleOnClick}>
                      <p className="fw-bold font24">Total Lead</p>
                      <p className="fw-bold text-success font24">
                        {ravenueData[0]?.totalLeads}
                      </p>
                    </div>
                  </div>
                </div>
                <div style={{ maxWidth: "25%" }}>
                  <div className="d-flex justify-content-center p-2 rounded m-2 border boxshadow">
                    <div className="text-center px-5">
                      <p className="fw-bold font24">ADMISSIONS</p>
                      <p className="fw-bold text-success font24">
                        {ravenueData[0]?.admissionCount}
                      </p>
                    </div>
                  </div>
                </div>
                <div style={{ maxWidth: "25%" }}>
                  <div className="d-flex justify-content-center p-2 rounded m-2 border boxshadow">
                    <div className="text-center px-5">
                      <p className="fw-bold font24">CONVERSION</p>
                      <p className="fw-bold text-success font24">
                        {ravenueData[0]?.conversion}%
                      </p>
                    </div>
                  </div>
                </div>
                <div style={{ maxWidth: "25%" }}>
                  <div className="d-flex justify-content-center p-2 rounded m-2 border boxshadow">
                    <div className="text-center px-5">
                      <p className="fw-bold font24">REVENUE</p>
                      <p className="fw-bold text-danger font24">
                        {ravenueData[0]?.achievedRevenue}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-2">
              <h4 className="fw-bold px-2">Reference Overview</h4>
              <div className="row d-flex flex-wrap">
                <div style={{ maxWidth: "20%" }}>
                  <div className="d-flex justify-content-center p-2 rounded m-2 border boxshadow">
                    <div className="text-center px-5">
                      <p className="fw-bold font24">Reference</p>
                      <p className="fw-bold text-success font24">
                        {counsellor?.referenceleadTargetCount}
                      </p>
                    </div>
                  </div>
                </div>
                <div style={{ maxWidth: "20%" }}>
                  <div className="d-flex justify-content-center p-2 rounded m-2 border boxshadow">
                    <div className="text-center px-5">
                      <p className="fw-bold font24">ACHIEVED</p>
                      <p className="fw-bold text-success font24">
                        {counsellor?.achievedReferenceLeadCount}
                      </p>
                    </div>
                  </div>
                </div>
                <div style={{ maxWidth: "20%" }}>
                  <div className="d-flex justify-content-center p-2 rounded m-2 border boxshadow">
                    <div className="text-center px-5">
                      <p className="fw-bold font24">PERCENTAGE</p>
                      <p className="fw-bold text-success font24">
                        {counsellor?.referenceLeadPercent}%
                      </p>
                    </div>
                  </div>
                </div>
                <div style={{ maxWidth: "20%" }}>
                  <div className="d-flex justify-content-center p-2 rounded m-2 border boxshadow">
                    <div className="text-center px-5">
                      <p className="fw-bold font24">DEFICIT</p>
                      <p className="fw-bold text-danger font24">
                        {counsellor?.referenceLeadDeficit}
                      </p>
                    </div>
                  </div>
                </div>
                {/* 
                <div style={{ maxWidth: "20%" }}>
                  <div className="d-flex justify-content-center p-2 rounded m-2 border boxshadow">
                    <div className="text-center px-5">
                      <p className="fw-bold font24">REVENUE</p>
                      <p className="fw-bold text-danger font24">0</p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="mt-2">
              <h4 className="fw-bold px-2">Target Overview</h4>
              <div className="row d-flex flex-wrap">
                <div style={{ maxWidth: "20%" }}>
                  <div className="d-flex justify-content-center p-2 rounded m-2 border boxshadow">
                    <div className="text-center px-5">
                      <p className="fw-bold font24">TARGET</p>
                      <p className="fw-bold text-success font24">
                        {counsellor?.targetRevenue?.toFixed(2)}
                      </p>
                    </div>
                  </div>
                </div>
                <div style={{ maxWidth: "20%" }}>
                  <div className="d-flex justify-content-center p-2 rounded m-2 border boxshadow">
                    <div className="text-center px-5">
                      <p className="fw-bold font24">ACHIEVED</p>
                      <p className="fw-bold text-success font24">
                        {" "}
                        {counsellor?.achievedRevenue?.toFixed(2)}
                      </p>
                    </div>
                  </div>
                </div>
                <div style={{ maxWidth: "20%" }}>
                  <div className="d-flex justify-content-center p-2 rounded m-2 border boxshadow">
                    <div className="text-center px-5">
                      <p className="fw-bold font24">PERCENTAGE</p>
                      <p className="fw-bold text-success font24">
                        {" "}
                        {counsellor?.percent?.toFixed(2)} %
                      </p>
                    </div>
                  </div>
                </div>
                <div style={{ maxWidth: "20%" }}>
                  <div className="d-flex justify-content-center p-2 rounded m-2 border boxshadow">
                    <div className="text-center px-5">
                      <p className="fw-bold font24">DEFICIT</p>
                      <p className="fw-bold text-danger font24">
                        {" "}
                        {counsellor?.deficit?.toFixed(2)}
                      </p>
                    </div>
                  </div>
                </div>
                <div style={{ maxWidth: "20%" }}>
                  <div className="d-flex justify-content-center p-2 rounded m-2 border boxshadow">
                    <div className="text-center px-5">
                      <p className="fw-bold font24">STATUS</p>
                      <p className="fw-bold text-success font24">PIP</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onClickLead = (data: any) => {
    if (data?.teamLeadData?.length > 0) {
      let outbound = data?.teamLeadData?.filter((item: any) => item?._id !== 5);

      const outboundTotals = calculateTotals(outbound);

      let outboundData: any = {
        totalOutbound: outboundTotals,
        Outbound: outbound,
      };

      setOutboundList(outboundData);
    } else {
      setOutboundList([]);
    }
  };

  const handleBack = () => {
    navigate("../salesDirectorCounsellor", {
      state: { pageName: "salesDirectorCounsellorTarget" },
    });
  };

  return (
    <div className="contentBox">
      <div className="row">
        <div className="formWidth">
          <div className="p-3">
            <div className="d-flex justify-content-between">
              <div>
                <div className="d-flex">
                  {isViewMode ? (
                    <button
                      type="button"
                      className="btn btn-light me-2"
                      onClick={() => handleBack()}
                    >
                      <ReplyIcon /> Back
                    </button>
                  ) : null}

                  <h3 className="fw-bold text-primary">
                    {counsellor?.counsellorName}
                  </h3>
                </div>
              </div>
              <div>
                <div className="d-flex p-2">
                  <div className="col-6 me-3">
                    <span>{counsellor?.teamLeadName}</span>
                    <div className="d-flex justify-content-start rounded fw-bold align-items-center">
                      (Team Lead)
                    </div>
                  </div>
                  <div className="col-6 me-3">
                    <span>{counsellor?.managerName}</span>
                    <div className="d-flex justify-content-start rounded fw-bold align-items-center">
                      (Manager)
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="card">
                  <div className="d-flex justify-content-between p-2">
                    <div className="col-md-4 me-4">
                      <LabelField lableName="Start Date" />
                      <div className="d-flex justify-content-start rounded fw-bold align-items-center">
                        <DatePicker
                          value={startDate}
                          setInputText={(value: string) => setStartDate(value)}
                          maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                          disabled={isViewMode}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 ms-1">
                      <LabelField lableName="End Date" />
                      <div className="d-flex justify-content-start rounded fw-bold align-items-center">
                        <DatePicker
                          value={endDate}
                          setInputText={(value: string) => setEndDate(value)}
                          maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                          disabled={isViewMode}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 d-flex justify-content-center align-items-center">
                      <button
                        className="btn btn-sm btn-primary rounded-pill"
                        disabled={isViewMode}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {renderListMode()}
          </div>
        </div>
      </div>
    </div>
  );
}
