import "../../App.css";
import React, { Suspense } from "react";
import Sidebar from "../../Module/sidebar";
import { Route, Routes } from "react-router-dom";

import Loading from "../../Component/loading";
import { CrmDataOperatorPanelSidebarData } from "../../DATA/crmDataOperatorPanelSidebarData";
import CreateImportLeads from "./createImportLeads";
import ManageEmployee from "./manageEmployee";
import LeadAssignments from "./leadAssignment";
import LeadTransfer from "./leadTransfer";
import IncentiveAllocation from "./incentiveAllocation";
import IncentiveTarget from "./incentiveTarget";
import TeamLeadAllocation from "./teamLeadAllocation";
// import OldImportLeads from "./oldImportLeads";
import LeadGenerationOverview from "../CRMAdminPanel/leadGenerationOverview";
import LeadOverview from "../CRMAdminPanel/leadOverview";
import ResultCompilation from "./resultCompilation";
import StudentResult from "./studentResult";
import LeadSearch from "./leadSearchByMobileNumber";
import AssignStateZoneWise from "./assignStateZoneWise";
import EditDeleteZone from "./editDeleteZone";

function Dashboard() {
  return (
    <div className="AppBox">
      {/* <Header />  */}
      <Sidebar data={CrmDataOperatorPanelSidebarData} />
      <div className="dashboard">
        {
          <Suspense
            fallback={
              <div className="border p-1">
                <Loading />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<CreateImportLeads />} />
              <Route path="createImportLeads" element={<CreateImportLeads />} />
              <Route path="manageEmployee" element={<ManageEmployee />} />
              <Route path="leadAssignments" element={<LeadAssignments />} />
              <Route path="leadTransfer" element={<LeadTransfer />} />
              <Route
                path="incentiveAllocation"
                element={<IncentiveAllocation />}
              />
              <Route path="incentiveTarget" element={<IncentiveTarget />} />
              <Route
                path="teamLeadAllocation"
                element={<TeamLeadAllocation />}
              />
              {/* <Route path="oldImportLeads" element={<OldImportLeads/>} />  */}
              <Route
                path="leadGenerationOverview"
                element={<LeadGenerationOverview />}
              />
              <Route path="leadOverview" element={<LeadOverview />} />
              <Route path="resultCompilation" element={<ResultCompilation />} />
              <Route path="studentResult" element={<StudentResult />} />
              <Route path="leadSearchByMobileNumber" element={<LeadSearch />} />
              <Route
                path="assignStateZoneWise"
                element={<AssignStateZoneWise />}
              />
              <Route path="editDeleteZone" element={<EditDeleteZone />} />
            </Routes>
          </Suspense>
        }
      </div>
    </div>
  );
}

export default Dashboard;
