import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import moment from "moment";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import {
  getManagerwiseRevenueTarget,
  getTeamwiseAllocatedRevenueTargetByManagerId,
} from "../../Redux/Actions/superManagerAction";
import PaginationTable from "../../Component/paginationTable";
import ReplyIcon from "@mui/icons-material/Reply";

export default function SalesDirectorManager() {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );

  const [managerName, setManagerName] = useState<any>("");
  const [managerList, setManagerList] = useState<any>([]);
  const [outboundList, setOutboundList] = useState<any>([]);
  const [isViewMode, setIsViewMode] = useState<any>(false);

  const { loggedInUserRole, loggedInUserId, loggedInUserName } = useSelector(
    (state: any) => ({
      loggedInUserRole: state.Auth.loggedInUserRole,
      loggedInUserId: state.Auth.loggedInUserId,
      loggedInUserName: state.Auth.loggedInUserName,
    })
  );

  useEffect(() => {
    displayManagerwiseRevenue();
  }, []);

  const displayManagerwiseRevenue = () => {
    dispatch(
      getManagerwiseRevenueTarget(
        {
          startDate,
          endDate,
        },
        (res: any) => {
          setManagerList(res);
        },
        () => {}
      )
    );
  };

  const calculateTotals = (items: any) => {
    return items.reduce(
      (acc: any, item: any) => {
        acc.totalTarget += item.targetRevenue || 0;
        acc.totalAchievedRevenue += item.achievedRevenue || 0;
        acc.totalAchievedReference += item.achievedLeadCount || 0;
        acc.totalTargetReference += item.referenceleadTargetCount || 0;
        return acc;
      },
      {
        totalTarget: 0,
        totalAchievedRevenue: 0,
        totalAchievedReference: 0,
        totalTargetReference: 0,
      }
    );
  };

  const renderListMode = () => {
    return (
      <div className="mt-2">
        <PaginationTable
          tableData={managerList ? managerList : []}
          pagename={"salesDirectorManager"}
          tableHead={[
            "S.N",
            "NAME",
            "TARGET",
            "ACHIEVED",
            "DEFICIT",
            "PERCENTAGE ACHIEVED",
          ]}
          edit={false}
          handleClick={(value: any) => {
            onClickLead(value);
          }}
        />
      </div>
    );
  };

  const onClickLead = (data: any) => {
    setIsViewMode(true);
    setManagerName(data?.managerName);
    setOutboundList([]);
    dispatch(
      getTeamwiseAllocatedRevenueTargetByManagerId(
        {
          startDate,
          endDate,
          managerId: data?._id,
        },
        (res: any) => {
          if (res?.length > 0) {
            let outbound = res?.filter((item: any) => item?._id !== 5);

            const outboundTotals = calculateTotals(outbound);

            let outboundData: any = {
              totalOutbound: outboundTotals,
              Outbound: outbound,
            };

            setOutboundList(outboundData);
          } else {
            setOutboundList([]);
          }
        },
        () => {}
      )
    );
  };

  const renderRolewise = () => {
    return (
      <div className="mt-2">
        <table className="w-100">
          <thead>
            <tr className="border-dark border text-dark">
              <th
                className="border-dark border"
                rowSpan={2}
                style={{ width: "3rem" }}
              >
                S.R.
              </th>
              <th
                className="border-dark border"
                rowSpan={2}
                style={{ width: "10rem" }}
              >
                SECTION
              </th>
              <th className="border-dark border" style={{ width: "5rem" }}>
                TARGET
              </th>
              <th className="border-dark border" style={{ width: "5rem" }}>
                ACHIEVED
              </th>
              <th className="border-dark border" style={{ width: "10rem" }}>
                PERCENTAGE ACHIEVED
              </th>
              <th className="border-dark border" style={{ width: "5rem" }}>
                DEFICIT
              </th>
              <th className="border-dark border" style={{ width: "10rem" }}>
                REFERENCE GENERATED
              </th>
            </tr>
            <tr className="text-dark">
              <th className="border-dark border bg-white text-dark">
                {outboundList?.totalOutbound?.totalTarget}
              </th>
              <th className="border-dark border bg-white text-dark">
                {outboundList?.totalOutbound?.totalAchievedRevenue?.toFixed(2)}
              </th>
              <th className="border-dark border bg-white text-dark">
                {outboundList?.totalOutbound?.totalAchievedRevenue
                  ? (
                      (outboundList?.totalOutbound?.totalAchievedRevenue /
                        outboundList?.totalOutbound?.totalTarget) *
                      100
                    )?.toFixed(2)
                  : 0}
                %
              </th>
              <th className="border-dark border bg-white text-danger">
                {outboundList?.totalOutbound?.totalTarget
                  ? (
                      outboundList?.totalOutbound?.totalTarget -
                      outboundList?.totalOutbound?.totalAchievedRevenue
                    )?.toFixed(2)
                  : 0}
              </th>
              <th className="border-dark border bg-white text-dark">
                {`${
                  outboundList?.totalOutbound?.totalAchievedReference
                    ? outboundList?.totalOutbound?.totalAchievedReference
                    : 0
                } / ${
                  outboundList?.totalOutbound?.totalTargetReference
                    ? outboundList?.totalOutbound?.totalTargetReference
                    : 0
                }`}
              </th>
            </tr>
          </thead>
          <tbody className="border-dark border text-dark">
            {outboundList?.Outbound?.map((item: any, index: number) => {
              return (
                <>
                  <tr>
                    <td className="border-dark border bg-white text-dark">
                      {index + 1}
                    </td>
                    <td className="border-dark border bg-white text-dark">
                      {item?.counsellorName}
                    </td>
                    <td className="border-dark border bg-white text-dark">
                      {item?.targetRevenue?.toFixed(2)}
                    </td>
                    <td className="border-dark border bg-white text-dark">
                      {item?.achievedRevenue?.toFixed(2)}
                    </td>
                    <td className="border-dark border bg-white text-dark">
                      {item?.percent ? item?.percent : 0}%
                    </td>
                    <td className="border-dark border bg-white text-danger">
                      {item?.deficit?.toFixed(2)}
                    </td>
                    <td className="border-dark border bg-white text-dark">
                      {`${item?.achievedLeadCount} / ${item?.referenceleadTargetCount}`}
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const handleBack = () => {
    setIsViewMode(false);
  };

  return (
    <div className="contentBox">
      <div className="row">
        <div className="formDiv formWidth">
          <div className="p-3">
            <div className="d-flex justify-content-between">
              <div>
                <div className="d-flex">
                  {isViewMode ? (
                    <button
                      type="button"
                      className="btn btn-light me-2"
                      onClick={() => handleBack()}
                    >
                      <ReplyIcon /> Back
                    </button>
                  ) : null}

                  <h3 className="fw-bold text-primary">
                    {isViewMode ? managerName : "Manager"}
                  </h3>
                </div>
              </div>
              <div>
                <div className="card">
                  <div className="d-flex justify-content-between p-2">
                    <div className="col-md-4 me-4">
                      <LabelField lableName="Start Date" />
                      <div className="d-flex justify-content-start rounded fw-bold align-items-center">
                        <DatePicker
                          value={startDate}
                          setInputText={(value: string) => setStartDate(value)}
                          maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                          disabled={isViewMode}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 ms-1">
                      <LabelField lableName="End Date" />
                      <div className="d-flex justify-content-start rounded fw-bold align-items-center">
                        <DatePicker
                          value={endDate}
                          setInputText={(value: string) => setEndDate(value)}
                          maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                          disabled={isViewMode}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 d-flex justify-content-center align-items-center">
                      <button
                        className="btn btn-sm btn-primary rounded-pill"
                        onClick={displayManagerwiseRevenue}
                        disabled={isViewMode}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!isViewMode ? renderListMode() : renderRolewise()}
          </div>
        </div>
      </div>
    </div>
  );
}
