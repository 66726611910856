import "../../App.css";
import React, { Suspense } from "react";
import Sidebar from "../../Module/sidebar";
import { Route, Routes } from "react-router-dom";

import Loading from "../../Component/loading";
import { CrmDataEntryPanelSidebarData } from "../../DATA/crmDataOperatorPanelSidebarData";
import Header from "../../Component/header";
import CreateImportLeads from "./createImportLeads";
import ResultCompilation from "./resultCompilation";

function CrmDataEntryDashboard(Props: any) {
  return (
    <div className="AppBox">
      {/* <Header /> */}  
       <Sidebar data={CrmDataEntryPanelSidebarData} />
      <div className="dashboard">
 
     
        {
          <Suspense
            fallback={
              <div className="border p-1">
                <Loading />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<CreateImportLeads />} />
              <Route path="createImportLeads" element={<CreateImportLeads/>} />
            <Route path="resultCompilation" element={<ResultCompilation />} /> 
            </Routes>
          </Suspense>
        }
      </div>
    </div>
  );
}

export default CrmDataEntryDashboard;
