import "../../App.css";
import React, { Suspense } from "react";
import Sidebar from "../../Module/sidebar";
import { Route, Routes } from "react-router-dom";

import Loading from "../../Component/loading";
import { HrDataOperatorPanelSidebarData } from "../../DATA/hrDataOperatorPanelSidebarData";
import ManageHrEmployee from "./manageHrEmployee";

function Dashboard() {
  return (
    <div className="AppBox">
      {/* <Header />  */}
      <Sidebar data={HrDataOperatorPanelSidebarData} />
      <div className="dashboard">
        {
          <Suspense
            fallback={
              <div className="border p-1">
                <Loading />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<ManageHrEmployee />} />
              <Route path="manageHrEmployee" element={<ManageHrEmployee />} />
            </Routes>
          </Suspense>
        }
      </div>
    </div>
  );
}

export default Dashboard;
