import { useEffect, useState } from "react";
import PageTitle from "../../Component/pageTitle";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import moment from "moment";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getTargetStatus } from "../../Redux/Actions/counsellorPanelAction";
import "../../App.css";
import CustomDropdown from "../../Component/customDropdown";
import {
  getCounsellorListByManagerId,
  getTargetStatusByManager,
} from "../../Redux/Actions/managerPanelAction";
import { getSMManagerList } from "../../Redux/Actions/superManagerAction";

export default function ManagerTargetStatus() {
  const dispatch = useDispatch();
  const [startDate, SetStartDate] = useState<any>(
    moment().weekday(1).format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment().weekday(7).format("YYYY-MM-DD")
  );
  const [resetTargetList, setResetTargetList] = useState<any>([]);
  const [counsellor, setCounsellor] = useState<any>("");
  const [manager, setManager] = useState<any>("");
  const [counsellorList, setCounsellorList] = useState([]);
  const [managerList, setManagerList] = useState([]);

  const { loggedInUserRole, loggedInUserId } = useSelector((state: any) => ({
    loggedInUserRole: state.Auth.loggedInUserRole,
    loggedInUserId: state.Auth.loggedInUserId,
  }));

  useEffect(() => {
    dispatch(
      getSMManagerList(
        loggedInUserId,
        (res: any) => {
          setManagerList(res);
        },
        () => {}
      )
    );
    dispatch(
      getTargetStatusByManager(
        {
          startDate,
          endDate,
        },
        (res: any) => {
          let tempArr = res?.data?.map((data: any) => {
            let tempData = {
              tempTotalTarget: 0,
              tempRevenueAchieved: 0,
              tempIncentiveAchieved: 0,
            };
            data?.target?.map((item: any) => {
              if (item?.level === "platinum_level") {
                tempData.tempTotalTarget = item?.targetAmount;
              }
              if (item?.Incentive_Revenue) {
                tempData.tempIncentiveAchieved =
                  tempData.tempIncentiveAchieved + item?.Incentive_Revenue;
              }
              tempData.tempRevenueAchieved =
                tempData.tempRevenueAchieved + item?.Revenue_Achieve;
              return item;
            });
            let aaaa = {
              _id: data?._id,
              startDate: data?.startDate,
              endDate: data?.endDate,
              target: data?.target,
              referenceLeadCount: data?.referenceLeadCount,
              achievedReferenceLeadCount: data?.achievedReferenceLeadCount,
              tempData,
            };
            return aaaa;
          });
          setResetTargetList(tempArr);
        },
        () => {}
      )
    );
  }, []);

  const onEndDateChange = (val: any) => {
    SetEndDate(val);
  };

  const onChangeManager = (val: any) => {
    setManager(val);
    dispatch(
      getCounsellorListByManagerId(
        val?._id,
        (res: any) => {
          setCounsellorList(res?.data);
        },
        () => {}
      )
    );
  };

  const onChangeCounsellor = (val: any) => {
    setCounsellor(val);
  };

  const renderListMode = () => {
    return (
      <>
        {/* *************************************By Counsellor****************************** */}
        <div>
          {resetTargetList?.map((value: any, index: any) => {
            let filterTarget = value?.target?.filter(
              (item: any) => item?.level === "bronze_level"
            );
            return (
              <>
                <hr className="hr"></hr>
                <div className="d-flex mt-5 justify-content-center mx-3 ">
                  <p className="fw-bold fs-5">
                    Target Start Date :{" "}
                    <span>
                      {moment(value.startDate).utc().format("DD-MM-YYYY")}
                    </span>
                  </p>
                  <p className="fw-bold fs-5 ms-5">
                    Target End Date :{" "}
                    <span>
                      {moment(value?.endDate).utc().format("DD-MM-YYYY")}
                    </span>
                  </p>
                </div>
                <div className="d-flex  justify-content-center m-5">
                  <div className="col-md-3 text-center boxshadow rounded p-4 me-5 ">
                    <h4 className="font-monospace">Highest Target</h4>

                    <h2 className="fw-bold">
                      {/* {value?.tempData?.tempTotalTarget}
                       */}
                      {filterTarget?.length > 0
                        ? filterTarget[0]?.targetAmount
                        : 0}
                    </h2>
                  </div>
                  <div className="col-md-3 text-center boxshadow  rounded p-4 me-5">
                    <h4 className="font-monospace">Revenue Achieved</h4>
                    <h2 className="fw-bold">
                      {value?.tempData?.tempRevenueAchieved}
                    </h2>
                  </div>
                  <div className="col-md-3 text-center boxshadow  rounded p-4 me-5">
                    <h4 className="font-monospace">Incentive Achieved</h4>
                    <h2 className="fw-bold">
                      {value?.tempData?.tempIncentiveAchieved}
                    </h2>
                  </div>
                  <div className="col-md-3 text-center boxshadow  rounded p-4 me-5">
                    <h4 className="font-monospace">Reference Count</h4>
                    <h2 className="fw-bold">
                      {value?.achievedReferenceLeadCount} {"/"}{" "}
                      {value?.referenceLeadCount}
                    </h2>
                  </div>
                </div>
                <div className="text-center">
                  <p className="font17 fw-bolder text-success">
                    Weekly Revenue achieved equal to or above the target
                    mentioned in crm will be eligible for incentive as per
                    aligned criteria of the total amount achieved.
                  </p>
                </div>
                <div className="d-flex justify-content-between mx-3 ">
                  <p className="fw-bold">
                    Target Achieved :{" "}
                    <span>
                      {value?.tempData?.tempRevenueAchieved >=
                      value?.target[0]?.targetAmount
                        ? "YES"
                        : "NO"}
                    </span>
                  </p>

                  <p className="fw-bold">
                    Incentive Achieved :{" "}
                    <span>
                      {value?.tempData?.tempIncentiveAchieved ? "YES" : "NO"}
                    </span>
                  </p>
                </div>
                <table className="w-100">
                  <tr>
                    <th>Target Level </th>
                    <th>Target Amount</th>
                    <th>Start Amount</th>
                    <th>End Amount</th>
                    <th>Total Revenue</th>
                    <th>Incentive</th>
                    <th>Max Incentive</th>
                  </tr>
                  <tbody>
                    {value?.target?.map((data: any, index: any) => {
                      if (data?.level === "bronze_level") {
                        return (
                          <tr>
                            <td className="fw-bold">
                              {data?.level === "bronze_level"
                                ? "Bronze Level"
                                : data?.level === "silver_level"
                                ? "Silver Level"
                                : data?.level === "gold_level"
                                ? "Gold Level"
                                : "Platinum Level"}{" "}
                            </td>
                            <td>{data?.targetAmount}</td>
                            <td>{data?.startAmount}</td>
                            <td>{data?.endAmount}</td>
                            <td>{data?.Revenue_Achieve}</td>
                            <td>
                              {data?.Incentive_Revenue
                                ? data?.Incentive_Revenue
                                : 0}
                            </td>
                            <td>
                              {data?.Max_Incentive ? data?.Max_Incentive : 0}
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </table>
              </>
            );
          })}
        </div>
      </>
    );
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (manager) {
      dispatch(
        getTargetStatusByManager(
          {
            startDate,
            endDate,
          },
          (res: any) => {
            let tempArr = res?.data?.map((data: any) => {
              let tempData = {
                tempTotalTarget: 0,
                tempRevenueAchieved: 0,
                tempIncentiveAchieved: 0,
              };
              data?.target?.map((item: any) => {
                if (item?.level === "platinum_level") {
                  tempData.tempTotalTarget = item?.targetAmount;
                }
                if (item?.Incentive_Revenue) {
                  tempData.tempIncentiveAchieved =
                    tempData.tempIncentiveAchieved + item?.Incentive_Revenue;
                }
                tempData.tempRevenueAchieved =
                  tempData.tempRevenueAchieved + item?.Revenue_Achieve;
                return item;
              });
              let aaaa = {
                _id: data?._id,
                startDate: data?.startDate,
                endDate: data?.endDate,
                target: data?.target,
                referenceLeadCount: data?.referenceLeadCount,
                achievedReferenceLeadCount: data?.achievedReferenceLeadCount,
                tempData,
              };
              return aaaa;
            });
            setResetTargetList(tempArr);
          },
          () => {}
        )
      );
    } else if (counsellor) {
      const postData = {
        counsellorId: counsellor?._id,
        startDate: startDate,
        endDate: endDate,
      };
      dispatch(
        getTargetStatus(
          postData,
          (res: any) => {
            let tempArr = res?.map((data: any) => {
              let tempData = {
                tempTotalTarget: 0,
                tempRevenueAchieved: 0,
                tempIncentiveAchieved: 0,
              };
              data?.target?.map((item: any) => {
                if (item?.level === "platinum_level") {
                  tempData.tempTotalTarget = item?.targetAmount;
                }
                if (item?.Incentive_Revenue) {
                  tempData.tempIncentiveAchieved =
                    tempData.tempIncentiveAchieved + item?.Incentive_Revenue;
                }
                tempData.tempRevenueAchieved =
                  tempData.tempRevenueAchieved + item?.Revenue_Achieve;
                return item;
              });
              let aaaa = {
                _id: data?._id,
                startDate: data?.startDate,
                endDate: data?.endDate,
                baseCourseId: data?.baseCourseId,
                examTypeId: data?.examTypeId,
                counsellorType: data?.counsellorType,
                experience: data?.experience,
                type: data?.type,
                target: data?.target,
                referenceLeadCount: data?.referenceLeadCount,
                achievedReferenceLeadCount: data?.achievedReferenceLeadCount,
                tempData,
              };
              return aaaa;
            });
            setResetTargetList(tempArr);
          },
          () => {}
        )
      );
    }
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Target Status"} />
      <div className="col-md-12 row">
        <div className="formDiv formWidth p-4">
          <form onSubmit={handleSubmit}>
            <div className="d-flex flex-wrap">
              <div className="col-md-4 d-flex mb-3 me-2">
                <div className="col-md-3 mt-2">
                  <LabelField lableName="Start Date" />
                </div>
                <div className="col-md-9">
                  <DatePicker
                    value={startDate}
                    setInputText={(value: string) => SetStartDate(value)}
                  />
                </div>
              </div>
              <div className="col-md-4 d-flex mb-3 me-2">
                <div className="col-md-3 mt-2">
                  <LabelField lableName="End Date" />
                </div>
                <div className="col-md-9">
                  <DatePicker
                    value={endDate}
                    setInputText={(value: string) => onEndDateChange(value)}
                  />
                </div>
              </div>
              <div className="col-md-4 d-flex mb-3 me-2">
                <div className="col-md-4 mt-2">
                  <LabelField lableName="Manager" />
                </div>
                <div className="col-md-8 pe-2">
                  <CustomDropdown
                    lableName="Select Manager"
                    setInputText={(value: any) => onChangeManager(value)}
                    value={manager}
                    options={managerList}
                  />
                </div>
              </div>
              <div className="col-md-4 d-flex mb-3 me-2">
                <div className="col-md-4 mt-2">
                  <LabelField lableName="Counsellor" />
                </div>
                <div className="col-md-8 ps-2">
                  <CustomDropdown
                    lableName="Select Counsellor"
                    setInputText={(value: any) => onChangeCounsellor(value)}
                    value={counsellor}
                    options={counsellorList}
                  />
                </div>
              </div>
              <div>
                <button className="btn btn-primary rounded-pill mb-3">
                  Search
                </button>
              </div>
            </div>
          </form>

          {renderListMode()}
        </div>
      </div>
    </div>
  );
}
