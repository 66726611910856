import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PageTitle from "../../Component/pageTitle";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import usePagination from "../../Component/usePagination";
import { useSelector } from "react-redux";
import { makeCalltoLead } from "../../Redux/Actions/counsellorPanelAction";
import moment from "moment";
import { getTableDataSlots, referSourceType } from "../../DATA/dataConstant";
import { updateCommonValues } from "../../Redux/Actions/CommonAction";
import { useAppDispatch } from "../../hooks";
import LabelField from "../../Component/labelField";
import DatePicker from "../../Component/datePicker";
import { getReferenceListByCounsellorId } from "../../Redux/Actions/managerPanelAction";
import CustomDropdown from "../../Component/customDropdown";
import { getStatusList } from "../../Redux/Actions/leadManagementAction";

export default function IncomingCall() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    loggedInUserId,
    loggedInUserRole,
    selectedStartDate,
    selectedEndDate,
    selectedStatus,
  } = useSelector((state: any) => ({
    loggedInUserId: state.Auth.loggedInUserId,
    loggedInUserRole: state.Auth.loggedInUserRole,
    selectedStartDate: state.Auth.startDate,
    selectedEndDate: state.Auth.endDate,
    selectedStatus: state.Auth.selectedStatus,
  }));

  const [startDate, SetStartDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [source, setSource] = useState<any>("");
  const [status, setStatus] = useState<any>("");
  const [sourceTypeList, setSourceTypeList] = useState<any>([]);
  const [statusList, setStatusList] = useState<any>([]);

  const pageName = location.state?.pageName;
  const leadType = location.state?.type;

  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  const [referenceLeadsList, setReferenceLeadsList] = useState([]);

  useEffect(() => {
    if (pageName !== "leadDetail") {
      dispatch(updateCommonValues("selectedLeadId", ""));
      dispatch(updateCommonValues("selectedPage", ""));
      dispatch(updateCommonValues("selectedRowsPerPage", ""));
      dispatch(updateCommonValues("startDate", ""));
      dispatch(updateCommonValues("endDate", ""));
      dispatch(updateCommonValues("selectedStatus", ""));
    }
    if (loggedInUserRole === 5) {
      setSourceTypeList(referSourceType);
      fetchData();
    }
    if (loggedInUserRole !== 5) fetchData();
    dispatch(
      getStatusList(
        "",
        (res: any) => {
          if (loggedInUserRole !== 8 && loggedInUserRole !== 4) {
            // let arr = res?.filter?.(
            //   (item: any) =>
            //     item?._id === 4 ||
            //     item?._id === 6 ||
            //     item?._id === 7 ||
            //     item?._id === 11 ||
            //     item?._id === 12
            // );
            let arr = res?.filter?.(
              (item: any) =>
                item?._id !== 15 && item?._id !== 16 && item?._id !== 17
            );
            setStatusList(arr);
            let tempStatus = arr?.filter?.(
              (item: any) => item?._id === selectedStatus
            );
            setStatus(tempStatus[0]);
          } else if (loggedInUserRole === 8) {
            // let arr = res?.filter?.(
            //   (item: any) =>
            //     item?._id === 4 ||
            //     item?._id === 6 ||
            //     item?._id === 7 ||
            //     item?._id === 11 ||
            //     item?._id === 12 ||
            //     item?._id === 15 ||
            //     item?._id === 16 ||
            //     item?._id === 17
            // );
            setStatusList(res);
            let tempStatus = res?.filter?.(
              (item: any) => item?._id === selectedStatus
            );
            setStatus(tempStatus[0]);
          } else if (loggedInUserRole === 4) {
            let tempStatus = res?.filter(
              (item: any) =>
                item?._id === 4 ||
                item?._id === 5 ||
                item?._id === 6 ||
                item?._id === 7 ||
                item?._id === 8 ||
                item?._id === 9 ||
                item?._id === 10 ||
                item?._id === 11 ||
                item?._id === 12 ||
                item?._id === 15 ||
                item?._id === 16 ||
                item?._id === 17 ||
                item?._id === 18 ||
                item?._id === 19 ||
                item?._id === 20
            );
            setStatusList(tempStatus);
          }
        },
        () => {}
      )
    );
  }, []);

  const fetchData = () => {
    setCount(0);
    setReferenceLeadsList([]);
    const postData: any = {
      startDate: startDate,
      endDate: endDate,
    };
    if (pageName === "leadDetail") {
      SetStartDate(selectedStartDate);
      SetEndDate(selectedEndDate);
      setSource(leadType);
      postData["startDate"] = selectedStartDate;
      postData["endDate"] = selectedEndDate;
      postData["type"] = leadType?._id;
      postData["status"] = selectedStatus;
    }
    if (source) {
      postData["type"] = source?._id;
    } else {
      postData["type"] = 2;
    }
    dispatch(
      getReferenceListByCounsellorId(loggedInUserId, postData, (res: any) => {
        if (res?.data[0]?.data?.length > 0) {
          setCount(res?.data[0]?.data?.length);
          setReferenceLeadsList(res?.data[0]?.data);
        } else {
          setCount(0);
          setReferenceLeadsList([]);
        }
      })
    );
  };

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        counsellorId: loggedInUserId,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        counsellorId: loggedInUserId,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        counsellorId: loggedInUserId,
      };
    }
  };
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const onClickLead = (value: any) => {
    dispatch(updateCommonValues("selectedLeadId", value?._id));
    dispatch(updateCommonValues("selectedPage", page));
    dispatch(updateCommonValues("selectedRowsPerPage", rowsPerPage));
    navigate("/leadDetail", {
      state: {
        pageName: "referenceGeneratedList",
        type: source,
      },
    });
  };

  const renderListMode = () => {
    return (
      <>
        <div>
          {/* <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
          <PaginationView
            count={count}
            tableData={referenceLeadsList ? referenceLeadsList : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          /> */}
          <div>
            <PaginationTable
              tableData={referenceLeadsList ? referenceLeadsList : []}
              pagename={"referenceLeadsList"}
              rowsPerPage={rowsPerPage}
              page={page}
              tableHead={[
                "S.N",
                "Name",
                "Exam - Exam Stream",
                "Enrolled",
                "Revenue",
                "Status",
              ]}
              edit={false}
              handleClick={(value: any) => onClickLead(value)}
            />
          </div>
        </div>
      </>
    );
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    fetchData();
  };

  const onChangeStartDate = (value: any) => {
    SetStartDate(value);
    dispatch(updateCommonValues("startDate", value));
  };

  const onChangeEndDate = (value: any) => {
    SetEndDate(value);
    dispatch(updateCommonValues("endDate", value));
  };

  const onChangeStatus = (val: any) => {
    setStatus(val);
    dispatch(updateCommonValues("selectedStatus", val?._id));
  };

  return (
    <div className="contentBox">
      <PageTitle name={"REFERENCES GENERATED"} />
      <div className="row m-2">
        <div className="col-md-12 formDiv formWidth">
          <form onSubmit={handleSubmit}>
            <div className="d-flex">
              <div className="col-md-3 d-flex pb-3 pe-2 ">
                <div className="col-md-3 mt-2">
                  <LabelField lableName="Start Date" />
                </div>
                <div className="col-md-9">
                  <DatePicker
                    value={startDate}
                    setInputText={(value: string) => onChangeStartDate(value)}
                  />
                </div>
              </div>
              <div className="col-md-3 d-flex pb-3 pe-2">
                <div className="col-md-3 mt-2">
                  <LabelField lableName="End Date" />
                </div>
                <div className="col-md-9">
                  <DatePicker
                    value={endDate}
                    setInputText={(value: string) => onChangeEndDate(value)}
                  />
                </div>
              </div>
              {loggedInUserRole === 5 ? (
                <div className="col-md-3 d-flex pb-3 pe-2">
                  <div className="col-md-4 mt-2">
                    <LabelField lableName="Source" />
                  </div>
                  <div className="col-md-7">
                    <CustomDropdown
                      lableName="Select Source"
                      setInputText={(value: any) => setSource(value)}
                      value={source}
                      options={sourceTypeList}
                      defaultValue={
                        sourceTypeList?.length > 0 ? source?._id : ""
                      }
                      Isrequired={true}
                    />
                  </div>
                </div>
              ) : null}
              <div className="col-md-3 d-flex mb-3">
                <div className="col-md-3 mt-2">
                  <LabelField lableName="Status" />
                </div>
                <div className="col-md-8 ms-2 pe-2">
                  <CustomDropdown
                    lableName="Select Status"
                    setInputText={(value: any) => onChangeStatus(value)}
                    value={status}
                    options={statusList}
                    defaultValue={statusList?.length > 0 ? status?._id : ""}
                  />
                </div>
              </div>
            </div>
            <div className="justify-content-end mb-2">
              <button className="btn btn-primary " type="submit">
                {" "}
                Search
              </button>
            </div>
          </form>
          {renderListMode()}
        </div>
      </div>
    </div>
  );
}
