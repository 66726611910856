export const SuperAdminSideBar = [
  {
    title: "DASHBOARD",
    path: "salesDirectorDashboard",
  },
  // {
  //   title: "MANAGER",
  //   path: "salesDirectorManager",
  // },
  // {
  //   title: "TEAM LEAD",
  //   path: "salesDirectorTeamLead",
  // },
  {
    title: "COUNSELLOR",
    path: "salesDirectorCounsellor",
  },
  // {
  //   title: "DASHBOARD",
  //   path: "superAdminDashbord",
  // },
  {
    title: "TEAM ANALYSIS",
    subNav: [
      {
        title: "Lead Analysis",
        path: "sALeadAnalysis",
        cName: "sub-nav",
      },
      {
        title: "Enrolled Analysis",
        path: "sAEnrollAnalysis",
        cName: "sub-nav",
      },
      {
        title: "Course Analysis",
        path: "saCourseAnalysis",
        cName: "sub-nav",
      },
      {
        title: "References Generated Analysis",
        path: "saReferenceanalysisManager",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "REVENUE ANALYSIS",
    subNav: [
      // {
      //   title: "Leads Allotted Date",
      //   path: "leadAllotedBydate",
      //   cName: "sub-nav",
      // },
      {
        title: "Leads Allotted Date Analysis",
        path: "leadAllottedAnalysisbyDate",
        cName: "sub-nav",
      },

      {
        title: "Admission Date",
        path: "saLeadByAdmissionDate",
        cName: "sub-nav",
      },
      {
        title: "Exam Revenue Based On Admission Date",
        path: "smExamRevenue",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "TARGET DASHBOARD",
    subNav: [
      {
        title: "Target Status",
        path: "smTargetStatus",
        cName: "sub-nav",
      },
      {
        title: "DIRECT INCENTIVE TARGET",
        path: "incentiveTarget",
        cName: "sub-nav",
      },
    ],
  },
  // {
  //   title: "COUPON CODE",
  //   path: "sACouponCode",
  // },
  {
    title: "MANAGE TEAM",
    subNav: [
      {
        title: "Active Employee",
        path: "manageEmployee",
        cName: "sub-nav",
      },
      {
        title: "LEAD TRANSFER",
        path: "leadTransfer",
        cName: "sub-nav",
      },
    ],
  },
  // {
  //   title: "SKIP DETAILS",
  //   path: "sMSkipDetails",
  // },

  {
    title: "ATTENDANCE",
    subNav: [
      {
        title: "Attendance",
        path: "saAttendance",
        cName: "sub-nav",
      },

      {
        title: "Monthly Report",
        path: "saMonthlyReport",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "DATA ANALYSIS",
    subNav: [
      {
        title: "Time Analysis",
        path: "timeAnalysisSuperManager",
        cName: "sub-nav",
      },
      {
        title: "Revenue Analysis",
        path: "revenueAnalysisSuperManager",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "DAILY MEETING",
    subNav: [
      {
        title: "STATUS MEETING",
        path: "dailyMeeting",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "TRANSACTION DETAILS",
    subNav: [
      {
        title: "TRANSACTION EDUTECH",
        path: "transactionedutech",
        cName: "sub-nav",
      },
      {
        title: "TRANSACTION EDUTECH STUDY MATERIAL",
        path: "transactionEdutechStudyMaterial",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "REVENUE ANALYSIS",
    subNav: [
      // {
      //   title: "SOURCE WISE ANALYSIS",
      //   path: "sourceWiseAnalysis",
      //   cName: "sub-nav",
      // },
      // {
      //   title: "STATE WISE ANALYSIS",
      //   path: "stateWiseAnalysis",
      //   cName: "sub-nav",
      // },
      {
        title: "ALL EXAM WISE REVENUE NUMBER",
        path: "allExamWiseRevenue",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "ADMISSION ANALYSIS",
    subNav: [
      {
        title: "BATCHWISE COUNT",
        path: "cxoBatchwiseCount",
        cName: "sub-nav",
      },
      {
        title: "DATEWISE COUNT",
        path: "datewisecount",
        cName: "sub-nav",
      },
      {
        title: "SECOND INSTALLMENT AMOUNT",
        subNav: [
          {
            title: "ASSURED REVENUE",
            path: "assuredRevenue",
            cName: "sub-nav",
          },
          {
            title: "SECOND INSTALLMENT DUE",
            path: "secondInstallmentDue",
            cName: "sub-nav",
          },
          {
            title: "SECOND INSTALLMENT PAID",
            path: "secondInstallmentPaid",
            cName: "sub-nav",
          },
        ],
      },
    ],
  },
  // {
  //   title: "LEADS DETAILS",
  //   subNav: [
  //     {
  //       title: "ALL SOURCE DETAILS LEADS",
  //       path: "registereduser",
  //       cName: "sub-nav",
  //     },
  //     {
  //       title: "ALL SOURCE LEAD COUNTS",
  //       path: "allSourceLead",
  //       cName: "sub-nav",
  //     },
  //     {
  //       title: "WEBSITE LEADS",
  //       path: "websiteLeadCount",
  //       cName: "sub-nav",
  //     },

  //     {
  //       title: "CLASSROOM LEADS",
  //       path: "classroomstudents",
  //       cName: "sub-nav",
  //     },
  //     {
  //       title: "HOME PAGE LEADS",
  //       path: "expertsupport",
  //       cName: "sub-nav",
  //     },
  //     {
  //       title: "SOURCE ANALYSIS",
  //       path: "leadAnalysis",
  //       cName: "sub-nav",
  //     },
  //     {
  //       title: "REFERENCE GENERATED",
  //       path: "referenceGenerated",
  //       cName: "sub-nav",
  //     },
  //   ],
  // },
  {
    title: "CRM Data",
    subNav: [
      {
        title: "REFERENCE LEADS",
        path: "referenceManagerCount",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "CRM Leads Analysis",
    subNav: [
      {
        title: "LEAD GENERATION DASHBOARD",
        path: "leadGenerationOverview",
        cName: "sub-nav",
      },
      {
        title: "LEADS ALLOCATIONS",
        path: "teamLeadOverview",
      },
    ],
  },
  {
    title: "PERFORMANCE",
    subNav: [
      {
        title: "Sales Performance",
        path: "salesPerformance",
        cName: "sub-nav",
      },
    ],
  },
];
