import "../App.css";
import { useDispatch } from "react-redux";
import {
  ReactElement,
  JSXElementConstructor,
  ReactNode,
  ReactPortal,
  Key,
  useState,
} from "react";
import ToggleSwitchbtn from "./toggleSwitchBtn";
import moment from "moment";
import { dateFormat } from "../DATA/dataConstant";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CallIcon from "@mui/icons-material/Call";
interface props {
  tableData: any;
  tableHead: any;
  handleEdit?: any;
  handleEditBatch?: any;
  handleDelete?: any;
  pagename?: any;
  edit?: any;
  type?: any;
  className?: any;
  addOnButton?: any;
  handleAddOnButton?: any;
  addOnButtonLabel?: any;
  handleCheck?: any;
  handleAllCheck?: any;
  isSelectedCheckbox?: any;
  adddeleteButton?: any;
  page?: any;
  rowsPerPage?: any;
  handleCancelButton?: any;
  handleEndLiveButton?: any;
  handleRescheduleButton?: any;
  addOnButtonClass?: any;
  handleToggle?: any;
  handleNavigate?: any;
  selectedIndex?: any;
  handleClick?: any;
  handleViewMore?: any;
}

function GenaralTable(props: props) {
  const [toggle, setToggle] = useState(false);
  const {
    tableData,
    tableHead,
    page,
    rowsPerPage,
    pagename,
    handleCancelButton,
    handleEndLiveButton,
    handleRescheduleButton,
    edit,
    handleEdit,
    handleDelete,
    handleCheck,
    handleAllCheck,
    isSelectedCheckbox,
    addOnButton,
    handleAddOnButton,
    addOnButtonLabel,
    addOnButtonClass,
    handleToggle,
    handleNavigate,
    handleClick,
    handleViewMore,
  } = props;
  const dispatch = useDispatch();

  let temp = 0;
  temp = page * rowsPerPage + 1;

  const OnClickHandle = (data: any) => {
    if (
      pagename === "ManagerLeadAnalysis" ||
      pagename === "Counsellor_call_Details" ||
      pagename === "TimeAnalysisOverview" ||
      pagename === "BDETimeAnalysisOverview" ||
      pagename === "ReferenceSummeryOverview"
    ) {
      handleClick(data);
    }
  };

  const getBatchDataName = (coursesList: any) => {
    var coursesArray = coursesList.map((item: any) => {
      return item?.name ? (
        <li>
          {item?.name} <br />
        </li>
      ) : null;
    });
    return <td className="col-2">{coursesArray}</td>;
  };

  const getBatchName = (coursesList: any) => {
    var coursesArray = coursesList.map((item: any) => {
      return item?.courseData?.name ? (
        <li>
          {item?.courseData?.name} <br />
        </li>
      ) : null;
    });
    return <td className="col-2">{coursesArray}</td>;
  };

  const getSubscriptionMonths = (coursesList: any) => {
    var coursesArray = coursesList.map((item: any) => {
      return item?.subscriptionPlanMonths ? (
        <>
          {item?.subscriptionPlanMonths} <br />
        </>
      ) : null;
    });
    return <td className="col-1">{coursesArray}</td>;
  };

  const getActualPrice = (coursesList: any) => {
    var coursesArray = coursesList.map((item: any) => {
      return item?.actual_Amount ? (
        <>
          {"\u20B9"}
          {item?.actual_Amount}
          <br />
        </>
      ) : null;
    });
    return <td className="col-3">{coursesArray}</td>;
  };

  const getGSTPrice = (coursesList: any) => {
    var coursesArray = coursesList.map((item: any) => {
      return item?.gst_Amount ? (
        <>
          {"\u20B9"}
          {item?.gst_Amount}
          <br />
        </>
      ) : null;
    });
    return <td className="col-3">{coursesArray}</td>;
  };

  const getPublicationTotalPrice = (coursesList: any) => {
    var coursesArray = coursesList.map((item: any) => {
      return item?.taxFreeAmount ? (
        <>
          {"\u20B9"}
          {item?.taxFreeAmount} <br />
        </>
      ) : null;
    });
    return <td className="col-2">{coursesArray}</td>;
  };

  const getEdutechTotalPrice = (coursesList: any) => {
    var coursesArray = coursesList.map((item: any) => {
      return item?.taxableAmount ? (
        <>
          {"\u20B9"}
          {item?.taxableAmount} <br />
        </>
      ) : null;
    });
    return <td className="col-2">{coursesArray}</td>;
  };

  const getEdutechSumPrice = (coursesList: any) => {
    var coursesArray = coursesList.map((item: any) => {
      return item?.taxableAmount ? (
        <>
          {"\u20B9"}
          {item?.taxFreeAmount + item?.actual_Amount + item?.gst_Amount} <br />
        </>
      ) : null;
    });
    return <td className="col-2">{coursesArray}</td>;
  };

  const getCoupon = (coursesList: any) => {
    var coursesArray = coursesList.map((item: any) => {
      return item?.couponName ? (
        <>
          {item?.couponName}
          <br />
        </>
      ) : null;
    });
    return <td className="col-2">{coursesArray}</td>;
  };

  const getDiscountedValue = (coursesList: any) => {
    var coursesArray = coursesList.map((item: any) => {
      return item?.couponDiscount ? (
        <>
          {"\u20B9"}
          {item?.couponDiscount} <br />
        </>
      ) : null;
    });
    return <td className="col-2">{coursesArray}</td>;
  };

  const getTotalPriceSum = (priceArray: any) => {
    let price = 0;
    priceArray.forEach((item: any) => {
      price = price + item?.taxableAmount;
    });
    return <td className="col-2">{price}</td>;
  };

  const getPublicationDiscountPrice = (priceArray: any) => {
    var coursesArray = priceArray.map((item: any) => {
      return item?.taxFreeAmount ? (
        <>
          {"\u20B9"}
          {item?.booksPrice - item?.taxFreeAmount} <br />
        </>
      ) : null;
    });
    return <td className="col-2">{coursesArray}</td>;
  };

  return (
    <div className=" pe-0 ps-0">
      <div className="salesTable table-responsive">
        <table className="w-100 mb-0">
          <thead className=" font-12">
            <tr>
              {tableHead?.map(
                (
                  data:
                    | string
                    | number
                    | boolean
                    | ReactElement<any, string | JSXElementConstructor<any>>
                    | Iterable<ReactNode>
                    | ReactPortal
                    | null
                    | undefined,
                  index: Key | null | undefined
                ) => {
                  return <th key={index}>{data}</th>;
                }
              )}
            </tr>
          </thead>
          <tbody>
            {Array.isArray(tableData) && tableData.length ? (
              tableData?.map((data, index) => {
                return (
                  <>
                    <tr
                      key={data._id}
                      className={
                        pagename === "ManagerLeadAnalysis" ||
                        pagename === "Counsellor_call_Details" ||
                        pagename === "TimeAnalysisOverview" ||
                        pagename === "BDETimeAnalysisOverview" ||
                        pagename === "ReferenceSummeryOverview"
                          ? "cursor"
                          : ""
                      }
                      onClick={() => OnClickHandle(data)}
                    >
                      {pagename === "TimeAnalysisOverview" ||
                      pagename === "BDETimeAnalysisOverview" ||
                      pagename === "SummeryOverview" ||
                      pagename === "UserActivityDetails" ||
                      pagename === "studentAnalysis" ||
                      pagename === "transactionEdutech" ||
                      pagename === "transactionEdutechStudyMaterial" ||
                      pagename === "revenueDataList" ||
                      pagename === "cxoBatchWiseCount" ||
                      pagename === "cxoBatchWiseCountDetail" ||
                      pagename === "cxoDateWiseCount" ||
                      pagename === "assuredRevenue" ||
                      pagename === "registeredUserList" ||
                      pagename === "studentCount" ||
                      pagename === "stateStudentCount" ||
                      pagename === "classroomStudents" ||
                      pagename === "expertSupport" ||
                      pagename === "referenceGenerated" ||
                      pagename === "referenceLeadCount" ||
                      pagename === "referenceTeamLeadCount" ? (
                        <td>{index + 1}</td>
                      ) : null}
                      {pagename === "ManagerLeadAnalysis" ? (
                        <td>
                          {" "}
                          {data?.firstName ? data?.firstName : ""}{" "}
                          {data?.lastName ? data?.lastName : ""}
                        </td>
                      ) : null}
                      {pagename === "ManagerLeadAnalysis" ? (
                        <td>
                          {data["NEWLEAD(ASSIGNED)"]?.count
                            ? data["NEWLEAD(ASSIGNED)"]?.count
                            : "-"}
                        </td>
                      ) : null}
                      {pagename === "ManagerLeadAnalysis" ? (
                        <td>
                          {data["TRANSFERREDLEAD"]?.count
                            ? data["TRANSFERREDLEAD"]?.count
                            : "-"}
                        </td>
                      ) : null}
                      {pagename === "ManagerLeadAnalysis" ? (
                        <td>
                          {data["CALLBACK"]?.count
                            ? data["CALLBACK"]?.count
                            : "-"}
                        </td>
                      ) : null}
                      {pagename === "ManagerLeadAnalysis" ? (
                        <td>
                          {data["INTERESTED"]?.count
                            ? data["INTERESTED"]?.count
                            : "-"}
                        </td>
                      ) : null}
                      {pagename === "ManagerLeadAnalysis" ? (
                        <td>
                          {data["NOTINTERESTED"]?.count
                            ? data["NOTINTERESTED"]?.count
                            : "-"}
                        </td>
                      ) : null}
                      {pagename === "ManagerLeadAnalysis" ? (
                        <td>
                          {data["PROSPECT"]?.count
                            ? data["PROSPECT"]?.count
                            : "-"}
                        </td>
                      ) : null}
                      {pagename === "ManagerLeadAnalysis" ? (
                        <td>
                          {data["ADMISSIONDONE"]?.count
                            ? data["ADMISSIONDONE"]?.count
                            : "-"}
                        </td>
                      ) : null}

                      {/* ****************************ccounsellor call **************************** */}

                      {pagename === "Counsellor_call_Details" ? (
                        <td> {data.firstName ? data.firstName : "-"}</td>
                      ) : null}
                      {pagename === "Counsellor_call_Details" ? (
                        <td> {data.lastName ? data.lastName : "-"}</td>
                      ) : null}
                      {/* {pagename ===
                                      "Counsellor_call_Details" ? (
                                        <td>
                                          {" "}
                                          {data.phone ? data.phone : "-"}
                                        </td>
                                      ) : null} */}
                      {pagename === "Counsellor_call_Details" ? (
                        <td> {data.state ? data.state : "-"}</td>
                      ) : null}
                      {pagename === "Counsellor_call_Details" ? (
                        <td> {data.course ? data.course : "-"}</td>
                      ) : null}
                      {pagename === "Counsellor_call_Details" ? (
                        <td> {data.status ? data.status : "-"}</td>
                      ) : null}
                      {pagename === "Counsellor_call_Details" ? (
                        <td> {data.source ? data.source : "-"}</td>
                      ) : null}
                      {pagename === "Counsellor_call_Details" ? (
                        <td> {data.comment ? data.comment : "-"}</td>
                      ) : null}
                      {pagename === "Counsellor_call_Details" ? (
                        <td>
                          {data.callBackDate
                            ? moment(data.callBackDate).format(
                                "DD-MM-YYYY hh:mm:ss a"
                              )
                            : ""}
                        </td>
                      ) : null}
                      {pagename === "Counsellor_call_Details" ? (
                        <td>
                          {data.registerDate
                            ? moment(data.registerDate).format(
                                "DD-MM-YYYY hh:mm:ss a"
                              )
                            : ""}
                        </td>
                      ) : null}
                      {/* **********************************SummeryOverview****************************************** */}
                      {pagename === "SummeryOverview" ? (
                        <td className="cursor" onClick={() => handleEdit(data)}>
                          {" "}
                          {data.firstName ? data.firstName : "-"}
                        </td>
                      ) : null}

                      {pagename === "SummeryOverview" ? (
                        <td className="cursor" onClick={() => handleEdit(data)}>
                          {" "}
                          {data.lastName ? data.lastName : "-"}
                        </td>
                      ) : null}

                      {pagename === "SummeryOverview" ? (
                        <td className="cursor" onClick={() => handleEdit(data)}>
                          {" "}
                          {data.examName ? data.examName : "-"}
                        </td>
                      ) : null}

                      {pagename === "SummeryOverview" ? (
                        <td className="cursor" onClick={() => handleEdit(data)}>
                          {" "}
                          {data.baseCourseName ? data.baseCourseName : "-"}
                        </td>
                      ) : null}

                      {pagename === "SummeryOverview" ? (
                        <td className="cursor" onClick={() => handleEdit(data)}>
                          {" "}
                          {data.sourceName ? data.sourceName : "-"}
                        </td>
                      ) : null}
                      {pagename === "SummeryOverview" ? (
                        <td className="cursor" onClick={() => handleEdit(data)}>
                          {" "}
                          {data.revenue ? data.revenue : "-"}
                        </td>
                      ) : null}

                      {pagename === "ReferenceSummeryOverview" ? (
                        <td> {data?.firstName ? data?.firstName : "-"}</td>
                      ) : null}

                      {pagename === "ReferenceSummeryOverview" ? (
                        <td> {data?.lastName ? data?.lastName : "-"}</td>
                      ) : null}
                      {pagename === "ReferenceSummeryOverview" ? (
                        // <td>
                        //   {" "}
                        //   {data?.exams?.map((c: any, index: any) => {
                        //     return (
                        //       <li>
                        //         {c?.exam} - {c.examStream}
                        //       </li>
                        //     );
                        //   })}{" "}
                        // </td>
                        <td>
                          {data?.exam ? data?.exam : "-"} -
                          {data?.examStream ? data?.examStream : "-"}
                        </td>
                      ) : null}

                      {/* {pagename === "ReferenceSummeryOverview" ? (
                                        <td>
                                          {" "}
                                          {data?._id?.examStream
                                            ? data?._id?.examStream
                                            : "-"}
                                        </td>
                                      ) : null} */}

                      {/* {pagename === "ReferenceSummeryOverview" ? (
                        <td> {data?.source ? data?.source : "-"}</td>
                      ) : null} */}

                      {/* {pagename === "ReferenceSummeryOverview" ? (
                        <td>
                          {" "}
                          {data.studentleadCount ? data.studentleadCount : "-"}
                        </td>
                      ) : null} */}
                      {/* {pagename === "ReferenceSummeryOverview" ? (
                        <td>
                          {" "}
                          {data.enrolledleadCount
                            ? data.enrolledleadCount
                            : "-"}
                        </td>
                      ) : null} */}
                      {pagename === "ReferenceSummeryOverview" ? (
                        <td> {data.enrolled ? "Yes" : "No"}</td>
                      ) : null}
                      {pagename === "ReferenceSummeryOverview" ? (
                        <td> {data.revenue ? data.revenue : "-"}</td>
                      ) : null}

                      {/* **********************************TeamShrinkageAnalysis****************************************** */}
                      {pagename === "TeamShrinkageAnalysis" ? (
                        <td> {data.firstName ? data.firstName : "-"}</td>
                      ) : null}
                      {pagename === "TeamShrinkageAnalysis" ? (
                        <td> {data.lastName ? data.lastName : "-"}</td>
                      ) : null}
                      {pagename === "TeamShrinkageAnalysis" ? (
                        <td> {data.examName ? data.examName : "-"}</td>
                      ) : null}
                      {pagename === "TeamShrinkageAnalysis" ? (
                        <td>
                          {" "}
                          {data.baseCourseName ? data.baseCourseName : "-"}
                        </td>
                      ) : null}
                      {pagename === "TeamShrinkageAnalysis" ? (
                        <td> {data.sourceName ? data.sourceName : "-"}</td>
                      ) : null}
                      {pagename === "TeamShrinkageAnalysis" ? (
                        <td> {data.status === 1 ? "Present" : "Absent"}</td>
                      ) : null}
                      {/* **********************************EnrolledAnalysis****************************************** */}
                      {pagename === "EnrolledAnalysis" ? (
                        <td> {data.Name ? data.Name : "-"}</td>
                      ) : null}
                      {pagename === "EnrolledAnalysis" ? (
                        <td> {data.Contact_No ? data.Contact_No : "-"}</td>
                      ) : null}
                      {pagename === "EnrolledAnalysis" ? (
                        <td> {data.state ? data.state : "-"}</td>
                      ) : null}
                      {pagename === "EnrolledAnalysis" ? (
                        <td> {data.exam ? data.exam : "-"}</td>
                      ) : null}
                      {pagename === "EnrolledAnalysis" ? (
                        <td> {data.examstream ? data.examstream : "-"}</td>
                      ) : null}
                      {pagename === "EnrolledAnalysis" ? (
                        <td> {data.Course ? data.Course : "-"}</td>
                      ) : null}
                      {pagename === "EnrolledAnalysis" ? (
                        <td>
                          {" "}
                          {data.studymaterial ? data.studymaterial : "-"}
                        </td>
                      ) : null}
                      {pagename === "EnrolledAnalysis" ? (
                        <td> {data.Source ? data.Source : "-"}</td>
                      ) : null}
                      {pagename === "EnrolledAnalysis" ? (
                        <td> {data.Campigan ? data.Campigan : "-"}</td>
                      ) : null}
                      {pagename === "EnrolledAnalysis" ? (
                        <td>
                          {" "}
                          {data.counsellor_name ? data.counsellor_name : "-"}
                        </td>
                      ) : null}
                      {pagename === "EnrolledAnalysis" ? (
                        <td> {data.amount ? data.amount : "-"}</td>
                      ) : null}
                      {pagename === "EnrolledAnalysis" ? (
                        <td> {data.a_Date ? data.a_Date : "-"}</td>
                      ) : null}
                      {pagename === "EnrolledAnalysis" ? (
                        <td> {data.regi ? data.regi : "-"}</td>
                      ) : null}
                      {/* **********************************CourseAnalysis****************************************** */}
                      {pagename === "CourseAnalysis" ? (
                        <td> {data.First_Name ? data.First_Name : "-"}</td>
                      ) : null}
                      {pagename === "CourseAnalysis" ? (
                        <td> {data.Last_Name ? data.Last_Name : "-"}</td>
                      ) : null}
                      {pagename === "CourseAnalysis" ? (
                        <td> {data.Phone_No ? data.Phone_No : "-"}</td>
                      ) : null}
                      {pagename === "CourseAnalysis" ? (
                        <td> {data.exam ? data.exam : "-"}</td>
                      ) : null}
                      {pagename === "CourseAnalysis" ? (
                        <td> {data.examstream ? data.examstream : "-"}</td>
                      ) : null}
                      {pagename === "CourseAnalysis" ? (
                        <td> {data.Course ? data.Course : "-"}</td>
                      ) : null}
                      {pagename === "CourseAnalysis" ? (
                        <td> {data.Source ? data.Source : "-"}</td>
                      ) : null}
                      {pagename === "CourseAnalysis" ? (
                        <td>
                          {" "}
                          {data.studymaterial ? data.studymaterial : "-"}
                        </td>
                      ) : null}
                      {pagename === "CourseAnalysis" ? (
                        <td> {data.Status ? data.Status : "-"}</td>
                      ) : null}
                      {pagename === "CourseAnalysis" ? (
                        <td> {data.Call_Back ? data.Call_Back : "-"}</td>
                      ) : null}
                      {/* ***********************managerDashboard***************** */}
                      {pagename === "managerDashboard" ||
                      pagename === "timeAnalysis" ||
                      pagename === "BDEtimeAnalysis" ? (
                        <td> {data.name ? data.name : "-"}</td>
                      ) : null}

                      {pagename === "managerDashboard" ? (
                        <td> {data?.totalTime}</td>
                      ) : null}

                      {/* {pagename === "managerDashboard" ? (
                        <td> {data.talkTime_min[0]?.time_12AM_1AM}</td>
                      ) : null}

                      {pagename === "managerDashboard" ? (
                        <td> {data.talkTime_min[1]?.time_1AM_2AM}</td>
                      ) : null}
                      {pagename === "managerDashboard" ? (
                        <td> {data.talkTime_min[2]?.time_2AM_3AM}</td>
                      ) : null}
                      {pagename === "managerDashboard" ? (
                        <td> {data.talkTime_min[3]?.time_3AM_4AM}</td>
                      ) : null}
                      {pagename === "managerDashboard" ? (
                        <td> {data.talkTime_min[4]?.time_4AM_5AM}</td>
                      ) : null}
                      {pagename === "managerDashboard" ? (
                        <td> {data.talkTime_min[5]?.time_5AM_6AM}</td>
                      ) : null}
                      {pagename === "managerDashboard" ? (
                        <td> {data.talkTime_min[6]?.time_6AM_7AM}</td>
                      ) : null}
                      {pagename === "managerDashboard" ? (
                        <td> {data.talkTime_min[7]?.time_7AM_8AM}</td>
                      ) : null}
                      {pagename === "managerDashboard" ? (
                        <td> {data.talkTime_min[8]?.time_8AM_9AM}</td>
                      ) : null} 
                      {pagename === "managerDashboard" ? (
                        <td> {data.talkTime_min[9]?.time_9AM_10AM}</td>
                      ) : null} */}

                      {pagename === "managerDashboard" ? (
                        <td>
                          {" "}
                          {data.talkTime_min[0]?.time_9AM_10AM
                            ? data.talkTime_min[0]?.time_9AM_10AM
                            : 0}
                        </td>
                      ) : null}

                      {pagename === "managerDashboard" ? (
                        <td>
                          {" "}
                          {data.talkTime_min[1]?.time_10AM_11AM
                            ? data.talkTime_min[1]?.time_10AM_11AM
                            : 0}
                        </td>
                      ) : null}
                      {pagename === "managerDashboard" ? (
                        <td>
                          {" "}
                          {data.talkTime_min[2]?.time_11AM_12PM
                            ? data.talkTime_min[2]?.time_11AM_12PM
                            : 0}
                        </td>
                      ) : null}
                      {pagename === "managerDashboard" ? (
                        <td>
                          {" "}
                          {data.talkTime_min[3]?.time_12PM_1PM
                            ? data.talkTime_min[3]?.time_12PM_1PM
                            : 0}
                        </td>
                      ) : null}
                      {pagename === "managerDashboard" ? (
                        <td>
                          {" "}
                          {data.talkTime_min[4]?.time_1PM_2PM
                            ? data.talkTime_min[4]?.time_1PM_2PM
                            : 0}
                        </td>
                      ) : null}
                      {pagename === "managerDashboard" ? (
                        <td>
                          {" "}
                          {data.talkTime_min[5]?.time_2PM_3PM
                            ? data.talkTime_min[5]?.time_2PM_3PM
                            : 0}
                        </td>
                      ) : null}
                      {pagename === "managerDashboard" ? (
                        <td>
                          {" "}
                          {data.talkTime_min[6]?.time_3PM_4PM
                            ? data.talkTime_min[6]?.time_3PM_4PM
                            : 0}
                        </td>
                      ) : null}
                      {pagename === "managerDashboard" ? (
                        <td>
                          {" "}
                          {data.talkTime_min[7]?.time_4PM_5PM
                            ? data.talkTime_min[7]?.time_4PM_5PM
                            : 0}
                        </td>
                      ) : null}
                      {pagename === "managerDashboard" ? (
                        <td>
                          {" "}
                          {data.talkTime_min[8]?.time_5PM_6PM
                            ? data.talkTime_min[8]?.time_5PM_6PM
                            : 0}
                        </td>
                      ) : null}
                      {pagename === "managerDashboard" ? (
                        <td>
                          {" "}
                          {data.talkTime_min[9]?.time_6PM_7PM
                            ? data.talkTime_min[9]?.time_6PM_7PM
                            : 0}
                        </td>
                      ) : null}
                      {pagename === "managerDashboard" ? (
                        <td>
                          {" "}
                          {data.talkTime_min[10]?.time_7PM_8PM
                            ? data.talkTime_min[10]?.time_7PM_8PM
                            : 0}
                        </td>
                      ) : null}
                      {pagename === "managerDashboard" ? (
                        <td>
                          {" "}
                          {data.talkTime_min[11]?.time_8PM_9PM
                            ? data.talkTime_min[11]?.time_8PM_9PM
                            : 0}
                        </td>
                      ) : null}
                      {pagename === "managerDashboard" ? (
                        <td>
                          {" "}
                          {data.talkTime_min[12]?.time_9PM_10PM
                            ? data.talkTime_min[12]?.time_9PM_10PM
                            : 0}
                        </td>
                      ) : null}
                      {pagename === "managerDashboard" ? (
                        <td>
                          {" "}
                          {data.talkTime_min[13]?.time_10PM_11PM
                            ? data.talkTime_min[13]?.time_10PM_11PM
                            : 0}
                        </td>
                      ) : null}
                      {pagename === "managerDashboard" ? (
                        <td>
                          {" "}
                          {data.talkTime_min[14]?.time_11PM_12AM
                            ? data.talkTime_min[14]?.time_11PM_12AM
                            : 0}
                        </td>
                      ) : null}

                      {pagename === "timeAnalysis"
                        ? data?.callDate?.map((item: any) => {
                            return (
                              <td> {item?.totalTime ? item?.totalTime : 0}</td>
                            );
                          })
                        : null}
                      {pagename === "BDEtimeAnalysis"
                        ? data?.dateWiseData?.map((item: any) => {
                            return (
                              <td> {item?.totalTime ? item?.totalTime : 0}</td>
                            );
                          })
                        : null}

                      {/* **********************************ActiveCounsellor****************************************** */}

                      {pagename === "TimeAnalysisOverview" ||
                      pagename === "BDETimeAnalysisOverview" ? (
                        data?.firstName ? (
                          <td className="text-break ">{data?.firstName}</td>
                        ) : (
                          <td>-</td>
                        )
                      ) : null}

                      {pagename === "TimeAnalysisOverview" ||
                      pagename === "BDETimeAnalysisOverview" ? (
                        data?.lastName ? (
                          <td className="text-break ">{data?.lastName}</td>
                        ) : (
                          <td>-</td>
                        )
                      ) : null}

                      {pagename === "TimeAnalysisOverview" ? (
                        data?.counsellorName ? (
                          <td className="text-break">{data?.counsellorName}</td>
                        ) : (
                          <td>-</td>
                        )
                      ) : null}

                      {pagename === "BDETimeAnalysisOverview" ? (
                        data?.executiveName ? (
                          <td className="text-break">{data?.executiveName}</td>
                        ) : (
                          <td>-</td>
                        )
                      ) : null}

                      {pagename === "TimeAnalysisOverview" ||
                      pagename === "BDETimeAnalysisOverview" ? (
                        data?.call_duration ? (
                          <td className="text-break">{data?.call_duration}</td>
                        ) : (
                          <td>-</td>
                        )
                      ) : null}

                      {pagename === "TimeAnalysisOverview" ||
                      pagename === "BDETimeAnalysisOverview" ? (
                        data?.call_date ? (
                          <td className="text-break">
                            {data.call_date
                              ? moment(data.call_date)
                                  .utc()
                                  .format("DD-MM-YYYY  ")
                              : "-"}
                          </td>
                        ) : (
                          <td>-</td>
                        )
                      ) : null}

                      {pagename === "TimeAnalysisOverview" ||
                      pagename === "BDETimeAnalysisOverview" ? (
                        data?.call_time ? (
                          <td className="text-break">{data?.call_time}</td>
                        ) : (
                          <td>-</td>
                        )
                      ) : null}
                      {/* *****************************LeadOverViewDetails****************************** */}
                      {pagename === "LeadOverViewDetails" ||
                      pagename === "WebinarOverViewDetails" ||
                      pagename === "DepartmentOverViewDetails" ? (
                        <td> {data.name ? data.name : "-"}</td>
                      ) : null}

                      {pagename === "LeadOverViewDetails" ||
                      pagename === "WebinarOverViewDetails" ||
                      pagename === "DepartmentOverViewDetails" ? (
                        <td> {data.collegeName ? data.collegeName : "-"}</td>
                      ) : null}

                      {pagename === "LeadOverViewDetails" ||
                      pagename === "WebinarOverViewDetails" ||
                      pagename === "DepartmentOverViewDetails" ? (
                        <td>
                          {" "}
                          {data.departmentName ? data.departmentName : "-"}
                        </td>
                      ) : null}

                      {/* {pagename === "LeadOverViewDetails" ? (
                        <td> {data.webinarCount ? data.webinarCount : "-"}</td>
                      ) : null} */}
                      {pagename === "LeadOverViewDetails" ? (
                        <td>
                          {" "}
                          {data.webinarAt
                            ? moment(data.webinarAt)
                                .utc()
                                .format("DD-MM-YYYY  ")
                            : "-"}
                        </td>
                      ) : null}

                      {pagename === "DepartmentOverViewDetails" ? (
                        <td> {data.totalWebinar ? data.totalWebinar : "-"}</td>
                      ) : null}

                      {pagename === "LeadOverViewDetails" ||
                      pagename === "WebinarOverViewDetails" ||
                      pagename === "DepartmentOverViewDetails" ? (
                        <td> {data.webinarURL ? data.webinarURL : "-"}</td>
                      ) : null}

                      {pagename === "LeadOverViewDetails" ? (
                        <td> {data.leadCount ? data.leadCount : "-"}</td>
                      ) : null}

                      {pagename === "WebinarOverViewDetails" ? (
                        <td>
                          {" "}
                          {data.webinarLeadCount ? data.webinarLeadCount : "-"}
                        </td>
                      ) : null}

                      {/*  Complete webinar list */}
                      {/* {pagename === "Complete_webinarList" ? (
                        <td> {data.reference ? data.reference : "-"}</td>
                      ) : null} */}
                      {pagename === "Complete_webinarList" ? (
                        <td> {data.firstName ? data.firstName : "-"}</td>
                      ) : null}
                      {pagename === "Complete_webinarList" ? (
                        <td> {data.lastName ? data.lastName : "-"}</td>
                      ) : null}
                      {pagename === "Complete_webinarList" ? (
                        <td> {data.examTypeName ? data.examTypeName : "-"}</td>
                      ) : null}
                      {pagename === "Complete_webinarList" ? (
                        <td>
                          {" "}
                          {data.basecourseName ? data.basecourseName : "-"}
                        </td>
                      ) : null}
                      {pagename === "Complete_webinarList" ? (
                        <td>
                          {" "}
                          {data.webinarDate
                            ? moment(data.webinarDate)
                                .utc()
                                .format("DD-MM-YYYY  ")
                            : "-"}
                        </td>
                      ) : null}

                      {/* ********************************************* SAReferenceSummeryOverview" ********************************************** */}
                      {pagename === "SAReferenceSummeryOverview" ? (
                        <td>
                          {" "}
                          {data?._id?.firstName ? data?._id?.firstName : "-"}
                        </td>
                      ) : null}
                      {pagename === "SAReferenceSummeryOverview" ? (
                        <td>
                          {" "}
                          {data?._id?.lastName ? data?._id?.lastName : "-"}
                        </td>
                      ) : null}
                      {pagename === "SAReferenceSummeryOverview" ? (
                        <td>
                          {" "}
                          {data?._id?.lastName ? data?._id?.lastName : "-"}
                        </td>
                      ) : null}
                      {/****************************************** User Activity Details******************************** */}
                      {pagename === "UserActivityDetails" ? (
                        data?.url ? (
                          <td className="text-break  ">
                            {data?.url.split("/").join(" -> ")}
                          </td>
                        ) : (
                          <td>-</td>
                        )
                      ) : null}
                      {pagename === "UserActivityDetails" ? (
                        data?.date ? (
                          <td className="text-break  ">
                            {" "}
                            {moment(data?.date).utc().format("LTS")}
                          </td>
                        ) : (
                          <td>-</td>
                        )
                      ) : null}

                      {/* **********************************ActiveCounsellor****************************************** */}
                      {pagename === "ActiveCounsellor" ? (
                        <td> {data.Name ? data.Name : "-"}</td>
                      ) : null}
                      {pagename === "ActiveCounsellor" ? (
                        <td> {data.Username ? data.Username : "-"}</td>
                      ) : null}
                      {pagename === "ActiveCounsellor" ? (
                        <td>
                          {" "}
                          {data.Counsellor_Type ? data.Counsellor_Type : "-"}
                        </td>
                      ) : null}
                      {pagename === "ActiveCounsellor" ? (
                        <td> {data.Course_Type ? data.Course_Type : "-"}</td>
                      ) : null}
                      {pagename === "ActiveCounsellor" ? (
                        <td> {data.Sub_Course ? data.Sub_Course : "-"}</td>
                      ) : null}
                      {pagename === "ActiveCounsellor" ? (
                        <td className="removelabel">
                          {" "}
                          <ToggleSwitchbtn />
                        </td>
                      ) : null}
                      {pagename === "ActiveCounsellor" ? (
                        <td className="cursor">
                          {handleEdit ? (
                            <span
                              className="text-Dark fw-bold cursor"
                              onClick={() => handleEdit(data)}
                            >
                              {" "}
                              <BorderColorIcon />{" "}
                            </span>
                          ) : null}
                          {handleDelete ? (
                            <span
                              className="text-Dark fw-bold cursor"
                              onClick={() => handleDelete(data)}
                            >
                              {" "}
                              <DeleteIcon />{" "}
                            </span>
                          ) : null}{" "}
                        </td>
                      ) : null}

                      {/************************* Student Analysis************************** */}
                      {pagename === "studentAnalysis" ? (
                        data?.firstName ? (
                          <td className="text-break  ">{data?.firstName}</td>
                        ) : (
                          <td>-</td>
                        )
                      ) : null}
                      {pagename === "studentAnalysis" ? (
                        data?.lastName ? (
                          <td className="text-break  ">{data?.lastName}</td>
                        ) : (
                          <td>-</td>
                        )
                      ) : null}
                      {pagename === "studentAnalysis" ? (
                        data?.examCount >= 0 ? (
                          <td className="text-break  ">{data?.examCount}</td>
                        ) : (
                          <td>-</td>
                        )
                      ) : null}
                      {pagename === "studentAnalysis" ? (
                        data?.liveVideoCount >= 0 ? (
                          <td className="text-break  ">
                            {data?.liveVideoCount}
                          </td>
                        ) : (
                          <td>-</td>
                        )
                      ) : null}
                      {pagename === "studentAnalysis" ? (
                        <td
                          className="text-success fw-bold cursor"
                          onClick={() => handleEdit(data)}
                        >
                          View Details
                        </td>
                      ) : null}
                      {pagename === "studentTestData" ? (
                        <>
                          <td>{data?.examName ? data?.examName : "-"}</td>
                          <td>{data?.totalMarks ? data?.totalMarks : "-"}</td>
                          <td>
                            {data?.correct === 0
                              ? 0
                              : data?.correct
                              ? data?.correct
                              : "-"}
                          </td>
                          <td>
                            {data?.incorrect === 0
                              ? 0
                              : data?.incorrect
                              ? data?.incorrect
                              : "-"}
                          </td>
                          <td>
                            {data?.skipped
                              ? data?.skipped
                              : data?.skipped === 0
                              ? 0
                              : "-"}
                          </td>
                          <td>{data?.rank ? data?.rank : "000"}</td>
                        </>
                      ) : null}

                      {/************************************************ */}
                      {pagename === "transactionEdutech" &&
                        (data ? (
                          <>
                            <td>{data?.orderNo}</td>
                            <td>
                              {moment.utc(data.date).format("DD-MM-YYYY ; LT")}
                            </td>
                            {/* <td>{data?.userDetails?.mobileNo}</td> */}
                            <td>{getBatchName(data?.courses)}</td>
                            <td>{getSubscriptionMonths(data?.courses)}</td>
                            <td>{getActualPrice(data?.courses)}</td>
                            <td>{getGSTPrice(data?.courses)}</td>
                            <td>{getEdutechTotalPrice(data?.courses)}</td>
                            <td>{getCoupon(data?.courses)}</td>
                            <td>{getDiscountedValue(data?.courses)}</td>
                            <td>
                              {data?.status !== 2 ? (
                                <button
                                  type="button"
                                  className="btn btn-default text-primary fw-bold me-2 cursor"
                                  onClick={() => handleViewMore(data)}
                                >
                                  View
                                </button>
                              ) : null}
                            </td>
                          </>
                        ) : (
                          <td>-</td>
                        ))}

                      {pagename === "transactionEdutechStudyMaterial" &&
                        (data ? (
                          <>
                            <td>{data?.orderNo}</td>
                            <td>
                              {moment.utc(data.date).format("DD-MM-YYYY ; LT")}
                            </td>
                            {/* <td>{data?.userDetails?.mobileNo}</td> */}
                            <td>{getBatchDataName(data?.coursedata)}</td>
                            <td>{getSubscriptionMonths(data?.courses)}</td>
                            <td>{getPublicationTotalPrice(data?.courses)}</td>
                            <td>{getActualPrice(data?.courses)}</td>
                            <td>{getGSTPrice(data?.courses)}</td>
                            <td>{getEdutechTotalPrice(data?.courses)}</td>
                            <td>{getEdutechSumPrice(data?.courses)}</td>
                            <td>{getCoupon(data?.courses)}</td>
                            <td>{getDiscountedValue(data?.courses)}</td>
                            <td>
                              {data?.status !== 2 ? (
                                <button
                                  type="button"
                                  className="btn btn-default text-primary fw-bold me-2 cursor"
                                  onClick={() => handleViewMore(data)}
                                >
                                  View
                                </button>
                              ) : null}
                            </td>
                          </>
                        ) : (
                          <td>-</td>
                        ))}

                      {pagename === "revenueDataList" &&
                        (data ? (
                          <>
                            <td>{data?._id}</td>
                            <td>
                              {"\u20B9"}
                              {data?.publication?.toFixed(2)}
                            </td>
                            <td>
                              {"\u20B9"}
                              {data?.actualTotalAmount?.toFixed(2)}
                            </td>
                            <td>
                              {"\u20B9"}
                              {data?.totalGstAmount?.toFixed(2)}
                            </td>
                            <td>
                              {"\u20B9"}
                              {data?.edutech + data?.publication}
                            </td>
                          </>
                        ) : (
                          <td>-</td>
                        ))}

                      {pagename === "cxoBatchWiseCount" &&
                        (data ? (
                          <>
                            <td>{data?.courseName}</td>
                            <td>{data?.addmissionCount}</td>
                            <td>{data?.withoutGstTotalAmount?.toFixed(2)}</td>
                            <td>{data?.totalGstAmount?.toFixed(2)}</td>
                            <td>{data?.amount?.toFixed(2)}</td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-default text-primary fw-bold me-2 cursor"
                                onClick={() => handleEdit(data)}
                              >
                                View Details
                              </button>
                            </td>
                          </>
                        ) : (
                          <td>-</td>
                        ))}
                      {pagename === "cxoBatchWiseCountDetail" &&
                        (data ? (
                          <>
                            <td>{data?.firstName}</td>
                            <td>{data?.lastName}</td>
                            <td>{data?.state}</td>
                            <td>
                              {moment.utc(data?.date).format("DD-MM-YYYY")}
                            </td>{" "}
                            <td>
                              {moment.utc(data?.endDate).format("DD-MM-YYYY")}
                            </td>
                            <td>{data?.total}</td>
                          </>
                        ) : (
                          <td>-</td>
                        ))}

                      {pagename === "cxoDateWiseCount" ? (
                        <>
                          <td>{data?.name ? data?.name : "-"}</td>
                          <td>
                            {data?.studentCount ? data?.studentCount : "-"}
                          </td>
                          <td>
                            {data?.withoutGstAmount
                              ? (data?.withoutGstAmount).toFixed(2)
                              : data?.withoutGstAmount === 0
                              ? 0
                              : "-"}
                          </td>
                          <td>
                            {data?.totalGstAmount
                              ? (data?.totalGstAmount).toFixed(2)
                              : data?.totalGstAmount === 0
                              ? 0
                              : "-"}
                          </td>
                          <td>
                            {data?.amount
                              ? (data?.amount).toFixed(2)
                              : data?.amount === 0
                              ? 0
                              : "-"}
                          </td>
                        </>
                      ) : null}

                      {pagename === "assuredRevenue" &&
                        (data ? (
                          <>
                            <td>
                              {data?.userId?.firstName} {data?.userId?.lastName}
                            </td>
                            <td>{data?.baseCourseId?.name}</td>
                            <td>{data?.dueAmount}</td>
                            <td>{data?.dueDate}</td>
                          </>
                        ) : (
                          <td>-</td>
                        ))}
                      {pagename === "registeredUserList" &&
                        (data ? (
                          <>
                            <td>{data?.firstName}</td>
                            <td>{data?.lastName}</td>
                            <td>
                              {moment
                                .utc(data.createdAt)
                                .format("DD - MM - YYYY")}
                            </td>
                            <td>
                              {moment.utc(data.createdAt).format("hh:mm:ss a")}
                            </td>
                            <td>{data?.state}</td>
                            <td>{data?.examType}</td>
                            <td>{data?.baseCourse}</td>
                            <td>{data?.source}</td>
                            <td>{data?.actualOrigin}</td>
                          </>
                        ) : (
                          <td>-</td>
                        ))}

                      {pagename === "studentCount" &&
                        (data ? (
                          <>
                            <td>{data?.examTypeId?.name}</td>
                            <td>{data?.baseCourseId?.name}</td>
                            <td>{data?.studentCount}</td>
                            <td>{data?.countPercentage}</td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-default text-primary fw-bold me-2 cursor"
                                onClick={() => handleEdit(data)}
                              >
                                View Details
                              </button>
                            </td>
                          </>
                        ) : (
                          <td>-</td>
                        ))}
                      {pagename === "stateStudentCount" &&
                        (data ? (
                          <>
                            <td>{data?.name}</td>
                            <td>{data?.studentCount}</td>
                            <td>{data?.countPercentage}</td>
                          </>
                        ) : (
                          <td>-</td>
                        ))}

                      {pagename === "classroomStudents" &&
                        (data ? (
                          <>
                            <td>{data?.userId?.firstName}</td>
                            <td>{data?.userId?.lastName}</td>
                            <td>
                              {moment.utc(data.createdAt).format("DD-MM-YYYY")}
                            </td>
                            <td>{data?.userId?.stateId?.name}</td>
                            <td>{data?.isClassRoomPayment ? "Yes" : "No"}</td>
                            <td>{data?.amount ? data?.amount : "-"}</td>
                          </>
                        ) : (
                          <td>-</td>
                        ))}
                      {pagename === "expertSupport" &&
                        (data ? (
                          <>
                            <td>{data?.fullName}</td>
                            <td>
                              {moment
                                .utc(data.updatedAt)
                                .format("DD - MM - YYYY")}
                            </td>
                            <td>
                              {moment.utc(data.updatedAt).format("hh:mm:ss a")}
                            </td>
                          </>
                        ) : (
                          <td>-</td>
                        ))}
                      {pagename === "referenceGenerated" &&
                        (data ? (
                          <>
                            <td>{data?.exam ? data?.exam : "-"}</td>
                            <td>{data?.examStream ? data?.examStream : "-"}</td>
                            <td>{data?.leads ? data?.leads : "-"}</td>
                          </>
                        ) : (
                          <td>-</td>
                        ))}
                      {pagename === "referenceLeadCount" &&
                        (data ? (
                          <>
                            <td>{data?.name}</td>
                            <td>{data?.studentleadCount}</td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-default text-primary fw-bold me-2 cursor"
                                onClick={() => handleEdit(data)}
                              >
                                View Details
                              </button>
                            </td>
                          </>
                        ) : (
                          <td>-</td>
                        ))}
                      {pagename === "referenceTeamLeadCount" &&
                        (data ? (
                          <>
                            <td>{data?.name}</td>
                            <td>{data?.studentLeadsIds}</td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-default text-primary fw-bold me-2 cursor"
                                onClick={() => handleEdit(data)}
                              >
                                View Details
                              </button>
                            </td>
                          </>
                        ) : (
                          <td>-</td>
                        ))}
                    </tr>
                  </>
                );
              })
            ) : (
              <tr>
                <td colSpan={tableHead?.length}>No record found.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default GenaralTable;
